//Dependencies
import clsx from 'clsx';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState, useRef, useMemo, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//Core
import { Box, Stack } from '@mui/material';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import green from '@material-ui/core/colors/green';

//Icons
import Close from '@material-ui/icons/Close';
import ReportProblem from '@material-ui/icons/ReportProblemOutlined';

//Commons
import { createDomObjectsFromHtml } from '../Menu/MaterialTripList/MailVisualEditor/utils/dom';
import { fixNodeEmptyContent } from '../Menu/MaterialTripList/MailVisualEditor/utils/fixNodeEmptyContent';
import GetCookie from '../Common/Functions/GetCookie';
import CheckBeforeRequest from '../Common/CheckBeforeRequest';
import GetPricesId from '../Cart/Functions/Margin/GetPricesId';
//Components
import CartManualProductsNote from './CartManualProductsNote';
import CartManualProductsManualBooking from './CartManualProductsManualBooking';
import CartManualProductsManualCancellationRules from './CartManualProductsManualCancellationRules';
import CartDeleteModal from './CartDeleteModal';
import {
    CartConstructionProductsTableItemProviderQuotationStatus
} from './CartConstructionProductsTableItemProviderQuotationStatus';
import {
    CartPackageItemChangeProviderQuotationStatusButtons
} from './CartPackageItemChangeProviderQuotationStatusButtons';
import { CartConstructionProviderQuotationStatusLayout } from './CartConstructionProviderQuotationStatusLayout';
import { CartCancellationRules } from './CartCancellationRules';
import { isHTMLEmpty } from './utils/isHTMLEmpty';
import { ManualProduct } from '../../Reducers/objects/manualProduct';
import { Currency } from '../../Reducers/objects/currency';
import { ProductPrice } from '../Itinerary/objects/productPrice';
import { AppState } from '../../Reducers/Reducers';
import { ProviderContext } from './utils/providerContext';

type Props = {
    manual_product: ManualProduct
}

export function CartManualProductCardDetails({ manual_product }: Props): JSX.Element {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const quotation_code = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;

    const user = useSelector((state: AppState) => state.user?.user);
    const currency = useSelector((state: AppState) => state.trip.currency);
    const language = useSelector((state: AppState) => state.header.tmp_language);
    const currency_list = useSelector((state: AppState) => state.base.currency_list);
    const step = useSelector((state: AppState) => state.cart.step);
    const to_book = useSelector((state: AppState) => state.cart.to_book);
    const description = useMemo(() => {
        const { document } = createDomObjectsFromHtml(manual_product.description ?? '');
        fixNodeEmptyContent(document.body);
        return document.body.innerHTML;
    }, [manual_product.description]);
    const [showNote, setShowNote] = useState(false);
    const [showManualCancellationRules, setShowManualCancellationRules] = useState(false);
    const [showManualBooking, setShowManualBooking] = useState(false);
    const [margin, setMargin] = useState<{
        id?: number,
        ids?: {
            room_id: number,
            price_id: number
        }[],
        rate_type: 'PER' | 'FIX',
        purchase: number,
        purchase_converted: number,
        purchase_currency?: Currency,
        factor_used: string,
        value: number,
        static_value?: number,
        markup: boolean,
        percent: number,
        static_percent?: number,
        selling: number,
        selling_currency?: Currency,
        custom_rate?: boolean,
        custom_rate_type?: string
    } | null>(null);
    const [surcom, setSurcom] = useState<{
        value?: number,
        static?: number,
        purchase: number,
        selling: number,
        percent: number,
        currency: Currency | null
    } | null>(null);
    const [deleteModal, setDeleteModal] = useState(false);
    const [checkBooking, setCheckBooking] = useState(false);
    const [marginValue, setMarginValue] = useState<number | null>(null);
    const [marginPercent, setMarginPercent] = useState<number | null>(null);

    const manualBookingRef = useRef<HTMLDivElement>(null);
    const providerContext = useContext(ProviderContext);

    const scrollIntoManuaBookingView = () => {
        manualBookingRef.current?.scrollIntoView({ block: 'center', inline: 'center', behavior: 'smooth' });
    };

    const onManualBooking = () => {
        setShowManualBooking(!showManualBooking);
    };

    const onDeleteManualCancellationRules = () => {
        const { headers } = CheckBeforeRequest();
        axios({
            method: 'PATCH',
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/manual-products/${manual_product.id}/`,
            data: {
                no_refund: true,
                cancel_condition_text: null
            }
        }).then(response => {
            dispatch({ type: 'CART_EDIT_MANUAL_ITEM', payload: response.data });
        }).catch(error => {
            console.log(error);
            setShowManualCancellationRules(!showManualCancellationRules);
        });
    };

    const onAddNote = () => {
        setShowNote(!showNote);
    };

    const onDeleteNote = () => {
        const { headers } = CheckBeforeRequest();
        axios({
            method: 'PATCH',
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/manual-products/${manual_product.id}/`,
            data: {
                custom_information: null
            }
        }).then(response => {
            dispatch({ type: 'CART_EDIT_MANUAL_ITEM', payload: response.data });
            enqueueSnackbar(t('cart-material.note-deleted'), { variant: 'success' });
        }).catch(error => {
            console.log(error);
            enqueueSnackbar(t('cart-material.note-not-deleted'), { variant: 'error' });
        });
    };

    const onDelete = () => {
        const { headers } = CheckBeforeRequest();
        axios({
            method: 'DELETE',
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/manual-products/${manual_product.id}/`
        }).then(() => {
            dispatch({ type: 'CART_MANUAL_PRODUCT_REMOVE_FROM_CART_BY_ID', payload: manual_product.id });
            enqueueSnackbar(t('cart-material.manual-product-deleted'), { variant: 'success' });
        }).catch(error => {
            console.log(error);
            enqueueSnackbar(t('cart-material.manual-product-not-deleted'), { variant: 'error' });
        });
    };

    const onValidateManualCancellationRules = (noRefund: boolean, content: string) => {
        const { headers } = CheckBeforeRequest();
        axios({
            method: 'PATCH',
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/manual-products/${manual_product.id}/`,
            data: {
                no_refund: noRefund,
                cancel_condition_text: isHTMLEmpty(content) ? null : content
            }
        }).then(response => {
            dispatch({ type: 'CART_EDIT_MANUAL_ITEM', payload: response.data });
            setShowManualCancellationRules(false);
        }).catch(error => {
            console.log(error);
            setShowManualCancellationRules(false);
        });
    };

    const priceToDisplay = (price: ProductPrice) => {
        return {
            cost: parseFloat(price.selling_price),
            currency: currency_list.find(currency => currency.id === price.selling_currency)
        };
    };

    const getPrice = (prices: ProductPrice[]) => {
        if (user?.client_full?.type === 1 || user?.client_full?.type === 3 || quotation_code === 'verdie') {
            if (manual_product.creator === null || (manual_product.creator && (manual_product.creator.client as any | null)?.type === 2)) {
                for (let i = 0; i < prices.length; i++) {
                    if (prices[i]!.master_price) {
                        return priceToDisplay(prices[i]!);
                    }
                }
            }
            for (let i = 0; i < prices.length; i++) {
                if (prices[i]!.is_tva) {
                    return priceToDisplay(prices[i]!);
                }
            }
            for (let i = 0; i < prices.length; i++) {
                if (prices[i]!.is_surcom) {
                    return priceToDisplay(prices[i]!);
                }
            }
            for (let i = 0; i < prices.length; i++) {
                if (prices[i]!.master_price) {
                    return priceToDisplay(prices[i]!);
                }
            }
            for (let i = 0; i < prices.length; i++) {
                if (prices[i]!.owner === user?.client) {
                    return priceToDisplay(prices[i]!);
                }
            }
        } else {
            for (let i = 0; i < prices.length; i++) {
                if (prices[i]!.is_tva) {
                    return priceToDisplay(prices[i]!);
                }
            }
            for (let i = 0; i < prices.length; i++) {
                if (prices[i]!.owner === user?.client) {
                    return priceToDisplay(prices[i]!);
                }
            }
        }
        return {
            cost: 0,
            currency: currency
        };
    };

    const getMargin = (
        tmp_margin: NonNullable<typeof margin>,
        tmp_surcom: NonNullable<typeof surcom>,
        prices: ProductPrice[]
    ) => {
        if (user?.client_full?.type === 1 || user?.client_full?.type === 3 || quotation_code === 'verdie') {
            for (let i = 0; i < prices.length; i++) {
                if (prices[i]!.is_surcom) {
                    tmp_surcom.purchase += parseFloat(prices[i]!.purchase_price) * parseFloat(prices[i]!.factor_used);
                    tmp_surcom.selling += parseFloat(prices[i]!.selling_price);
                    tmp_surcom.currency = currency_list.find(currency => currency.id === prices[i]!.selling_currency) ?? null;
                    tmp_surcom.percent += parseFloat(prices[i]!.percentage_between);
                    break;
                }
            }
            for (let i = 0; i < prices.length; i++) {
                if (prices[i]!.master_price) {
                    tmp_margin.purchase += parseFloat(prices[i]!.purchase_price);
                    tmp_margin.purchase_converted += parseFloat(prices[i]!.purchase_price) * parseFloat(prices[i]!.factor_used);
                    tmp_margin.purchase_currency = currency_list.find(currency => currency.id === prices[i]!.purchase_currency);
                    tmp_margin.selling += parseFloat(prices[i]!.selling_price);
                    tmp_margin.selling_currency = currency_list.find(currency => currency.id === prices[i]!.selling_currency);
                    tmp_margin.percent += parseFloat(prices[i]!.percentage_between);
                    tmp_margin.custom_rate = prices[i]!.custom_rate;
                    tmp_margin.custom_rate_type = prices[i]!.custom_rate_type ?? undefined;
                    tmp_margin.id = prices[i]!.id;
                    break;
                }
            }
            if (tmp_margin.id === null) {
                for (let i = 0; i < prices.length; i++) {
                    if (prices[i]!.owner === user?.client) {
                        tmp_margin.purchase += parseFloat(prices[i]!.purchase_price);
                        tmp_margin.purchase_converted += parseFloat(prices[i]!.purchase_price) * parseFloat(prices[i]!.factor_used);
                        tmp_margin.purchase_currency = currency_list.find(currency => currency.id === prices[i]!.purchase_currency);
                        tmp_margin.selling += parseFloat(prices[i]!.selling_price);
                        tmp_margin.selling_currency = currency_list.find(currency => currency.id === prices[i]!.selling_currency);
                        tmp_margin.percent += parseFloat(prices[i]!.percentage_between);
                        tmp_margin.custom_rate = prices[i]!.custom_rate;
                        tmp_margin.custom_rate_type = prices[i]!.custom_rate_type ?? undefined;
                        tmp_margin.id = prices[i]!.id;
                        break;
                    }
                }
            }
        } else {
            for (let i = 0; i < prices.length; i++) {
                if (prices[i]!.owner === user?.client) {
                    tmp_margin.purchase += parseFloat(prices[i]!.purchase_price);
                    tmp_margin.purchase_converted += parseFloat(prices[i]!.purchase_price) * parseFloat(prices[i]!.factor_used);
                    tmp_margin.purchase_currency = currency_list.find(currency => currency.id === prices[i]!.purchase_currency);
                    tmp_margin.selling += parseFloat(prices[i]!.selling_price);
                    tmp_margin.selling_currency = currency_list.find(currency => currency.id === prices[i]!.selling_currency);
                    tmp_margin.percent += parseFloat(prices[i]!.percentage_between);
                    tmp_margin.custom_rate = prices[i]!.custom_rate;
                    tmp_margin.custom_rate_type = prices[i]!.custom_rate_type ?? undefined;
                    tmp_margin.id = prices[i]!.id;
                    break;
                }
            }
        }
    };

    const onToggleForBooking = (item: typeof manual_product) => () => {
        dispatch({
            type: 'CART_TOGGLE_FOR_BOOKING',
            payload: {
                ...item,
                is_manual: true
            }
        });
    };

    const confirmRecalc = () => {
        const { headers } = CheckBeforeRequest();
        axios({
            method: 'PATCH',
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/manual-products/${manual_product.id}/`,
            data: {
                moved_with_recalc: false
            }
        }).then(response => {
            dispatch({ type: 'CART_EDIT_MANUAL_ITEM', payload: response.data });
        }).catch(error => {
            console.log(error);
        });
    };

    const confirmCustomInformationRecalc = () => {
        const { headers } = CheckBeforeRequest();
        axios({
            method: 'PATCH',
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/manual-products/${manual_product.id}/`,
            data: {
                moved_with_recalc_custom_information: false
            }
        }).then(response => {
            dispatch({ type: 'CART_EDIT_MANUAL_ITEM', payload: response.data });
        }).catch(error => {
            console.log(error);
        });
    };

    const confirmCancelConditionRecalc = () => {
        const { headers } = CheckBeforeRequest();
        axios({
            method: 'PATCH',
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/manual-products/${manual_product.id}/`,
            data: {
                moved_with_recalc_cancel_condition_text: false
            }
        }).then(response => {
            dispatch({ type: 'CART_EDIT_MANUAL_ITEM', payload: response.data });
        }).catch(error => {
            console.log(error);
        });
    };

    useEffect(() => {
        if (showManualBooking) {
            scrollIntoManuaBookingView();
        }
    });

    useEffect(() => {
        if (marginValue && marginValue !== margin?.value) {
            let tmp_margin = margin ?
                { ...margin } :
                null;
            if (tmp_margin) {
                tmp_margin.value = marginValue;
                tmp_margin.rate_type = 'FIX';
                if (tmp_margin.markup) {
                    tmp_margin.percent = parseFloat(((tmp_margin.value / tmp_margin.purchase_converted) * 100).toFixed(2));
                } else {
                    tmp_margin.percent = parseFloat(((tmp_margin.value / tmp_margin.selling) * 100).toFixed(2));
                }
                setMarginPercent(tmp_margin.percent);
                setMargin(tmp_margin);
            }
        }
    }, [marginValue]);

    useEffect(() => {
        if (marginPercent && marginPercent !== margin?.percent) {
            let tmp_margin = margin ?
                { ...margin } :
                null;
            if (tmp_margin) {
                tmp_margin.percent = marginPercent;
                tmp_margin.rate_type = 'PER';
                if (tmp_margin.markup) {
                    tmp_margin.value = parseFloat((tmp_margin.purchase_converted * (tmp_margin.percent / 100)).toFixed(2));
                } else {
                    tmp_margin.value = parseFloat((tmp_margin.selling * (tmp_margin.percent / 100)).toFixed(2));
                }
                setMarginValue(tmp_margin.value);
                setMargin(tmp_margin);
            }
        }
    }, [marginPercent]);

    useEffect(() => {
        if (showNote) {
            const element = document.getElementById(`flight-${manual_product.id}-note`);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
            }
        }
    });

    useEffect(() => {
        if (showManualCancellationRules) {
            const element = document.getElementById(`flight-${manual_product.id}-manual-cancellation-rules`);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
            }
        }
    });

    useEffect(() => {
        let total_cost: {
            cost: number,
            currency?: Currency
        }[] = [];
        let tmp_margin: NonNullable<typeof margin> = {
            purchase: 0,
            purchase_converted: 0,
            selling: 0,
            value: 0,
            percent: 0,
            rate_type: 'FIX',
            custom_rate_type: 'PER',
            factor_used: '0',
            markup: false
        };
        let tmp_surcom: NonNullable<typeof surcom> = {
            purchase: 0,
            selling: 0,
            value: 0,
            percent: 0,
            currency: null
        };
        let price = getPrice(manual_product.prices);
        getMargin(tmp_margin, tmp_surcom, manual_product.prices);
        total_cost.push({
            cost: price.cost,
            currency: price.currency
        });
        tmp_margin.value = parseFloat((tmp_margin.selling - tmp_margin.purchase_converted).toFixed(2));
        tmp_margin.static_value = tmp_margin.value;
        tmp_margin.markup = Math.abs(tmp_margin.percent - (100 - (tmp_margin.purchase_converted / tmp_margin.selling) * 100)) > Math.abs(tmp_margin.percent - ((tmp_margin.value / tmp_margin.purchase_converted) * 100));
        tmp_margin.static_percent = tmp_margin.percent;
        tmp_surcom.static = tmp_surcom.value;
        tmp_surcom.value = tmp_surcom.selling - tmp_surcom.purchase;
        let checked = to_book?.find(book_item => book_item.id === manual_product.id);
        if (checked) {
            setCheckBooking(true);
        } else {
            setCheckBooking(false);
        }
        setMargin(tmp_margin);
        setSurcom(tmp_surcom);
    }, [manual_product]);

    useEffect(() => {
        let checked = to_book?.find(book_item => book_item.id === manual_product.id);
        if (checked) {
            setCheckBooking(true);
        } else {
            setCheckBooking(false);
        }
    }, [to_book]);

    return (
        <Grid
            id={`product-${manual_product.id}`}
            className={classes.scrollMargin}
            container
            item
            xs
            direction={"column"}
            style={{ padding: 15 }}
            spacing={2}
        >
            <Grid item xs={12}>
                <Grid container direction="row" justify={'space-between'} alignItems={'center'}>
                    {
                        step === 4 &&
                        user?.client_full?.type !== 2 &&
                        !manual_product.poi_type &&
                        manual_product.is_bookable &&
                        manual_product.is_available &&
                        (manual_product.booking_status === null || manual_product.booking_status.status_booking === "ERROR") &&
                        ![20].includes(manual_product.product_type) &&
                        manual_product.booking_process_state === "NOTHING" &&
                        <Grid item>
                            <div className={classes.checkboxContainer}>
                                <Checkbox edge={'start'} checked={checkBooking} tabIndex={-1} disableRipple onChange={onToggleForBooking(manual_product)} />
                            </div>
                        </Grid>
                    }
                    {
                        user?.client_full?.type !== 2 &&
                        <Grid item xs={12}>
                            <CartConstructionProviderQuotationStatusLayout
                                status={
                                    <CartConstructionProductsTableItemProviderQuotationStatus
                                        item={manual_product}
                                    />
                                }
                                buttons={
                                    <CartPackageItemChangeProviderQuotationStatusButtons
                                        item={{
                                            ...manual_product,
                                            type: 'manual',
                                            isCustom: manual_product.is_custom
                                        }}
                                    />
                                }
                            />
                        </Grid>
                    }
                </Grid>
            </Grid>
            <Grid container item xs spacing={2}>
                {
                    /*(
                        user?.groups?.some(group => group.is_admin === true) ||
                        (manual_product.auto_product === null || !manual_product.has_deletion_restriction)
                    ) && (
                        <Grid item>
                            <div className={ classes.deleteContainer }>
                                <Tooltip title={ t('cart-material.delete-product') }><Close className={ classes.delete } onClick={ toggleDeleteModal }/></Tooltip>
                            </div>
                        </Grid>
                    ) */
                }
                <Grid item xs={12}>
                    {
                        manual_product.booking_status &&
                        manual_product.booking_status.status_booking === 'CANCELLED' &&
                        manual_product.cancellation_charged_amount &&
                        <Typography className={clsx(classes.booking, classes.error)}>
                            {t('flight_search.cancel_fee')} :{' '}
                            {
                                new Intl.NumberFormat(
                                    language,
                                    {
                                        style: 'currency',
                                        currency: currency_list.find((currency) => currency.id === manual_product.prices[GetPricesId(manual_product.prices, user?.client, user, true)]?.purchase_currency)?.iso_code,
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    }
                                ).format(manual_product.cancellation_charged_amount)
                            }
                        </Typography>
                    }
                </Grid>
                {
                    showManualBooking &&
                    <Grid item xs={12} style={{ marginTop: 15 }} ref={manualBookingRef}>
                        <hr />
                        <CartManualProductsManualBooking manual_product={manual_product} onManualBooking={onManualBooking} />
                    </Grid>
                }
                {
                    !providerContext.module &&
                    manual_product.moved_with_recalc &&
                    (
                        quotation_code !== 'visiteurs' ||
                        user?.client_full?.type !== 2
                    ) &&
                    <Grid item xs={12} onClick={confirmRecalc} style={{ cursor: 'pointer' }}>
                        <Alert variant={'filled'} severity={'warning'} action={<Close />}>
                            {t('cart-material.recalc-move')}
                        </Alert>
                    </Grid>
                }
                {
                    (manual_product.product_type === 2 || manual_product.product_type === 4) && manual_product.one_way_fees && parseFloat(manual_product.one_way_fees) !== 0 &&
                    <Grid item xs={12}>
                        <Alert variant={'filled'} severity={'warning'}>
                            {`${t('cars.one_way')} : ${t('cart-material.resort-fees-1')} ${parseFloat(manual_product.one_way_fees)} ${currency.symbol} ${t('cart-material.one-way-fees-3')}`}
                        </Alert>
                    </Grid>
                }
                {
                    manual_product.description &&
                    <Grid item xs={12}>
                        <Box
                            sx={{
                                '& p': {
                                    marginTop: '0 !important',
                                    marginBottom: '0 !important'
                                }
                            }}
                        >
                            <Typography
                                className={classes.description}
                                dangerouslySetInnerHTML={{ __html: description }}
                            />
                        </Box>
                    </Grid>
                }
                {
                    showNote ? (
                        <Grid id={`flight-${manual_product.id}-note`} item xs={12}>
                            <hr />
                            <CartManualProductsNote manual_product={manual_product} onAddNote={onAddNote} />
                        </Grid>
                    ) : manual_product.custom_information && (
                        <Grid item xs={12}>
                            <hr />
                            <Alert className={classes.cancelConditionAlert} variant={'filled'} severity={'info'} action={
                                <Grid container direction={'column'} spacing={2} alignItems={'flex-end'}>
                                    <Grid item>
                                        <Grid container spacing={2} alignItems={'center'}>
                                            <Grid item>
                                                <Button color={'inherit'} size={'small'} onClick={onAddNote}>
                                                    {t('global.edit')}
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Close onClick={onDeleteNote} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item style={{ width: '100%' }}>
                                        {
                                            manual_product.moved_with_recalc_custom_information &&
                                            <Tooltip title={t('cart-material.recalc-product-note')}>
                                                <div
                                                    className={clsx(classes.traverlerModification, classes.delete)}
                                                    onClick={confirmCustomInformationRecalc}
                                                    style={{ fontSize: 13, color: 'white', background: '#ff9800', border: '2px solid #ff9800' }}
                                                >
                                                    <Grid container alignItems={'center'} justify={'space-between'}>
                                                        <Grid item><ReportProblem style={{ verticalAlign: 'sub', fontSize: 18 }} /></Grid>
                                                        <Grid item>{t('cart-material.recalc')}</Grid>
                                                        <Grid item><Close style={{ verticalAlign: 'middle', fontSize: 18 }} /></Grid>
                                                    </Grid>
                                                </div>
                                            </Tooltip>
                                        }
                                    </Grid>
                                </Grid>
                            }>
                                <AlertTitle>{t('cart-material.note')} :</AlertTitle>
                                {manual_product.custom_information}
                            </Alert>
                        </Grid>
                    )
                }
                <CartCancellationRules
                    type="manual"
                    id={manual_product.id}
                    open={showManualCancellationRules}
                    text={manual_product.cancel_condition_text}
                    moved_with_recalc_cancel_condition_text={manual_product.moved_with_recalc_cancel_condition_text}
                    noRefund={manual_product.no_refund}
                    isCustom={false}
                    onConfirmCancelConditionRecalc={confirmCancelConditionRecalc}
                    onDeleteCancellationRules={onDeleteManualCancellationRules}
                    onToggle={() => setShowManualCancellationRules((state) => !state)}
                    onValidate={onValidateManualCancellationRules}
                />
            </Grid>
            <CartDeleteModal deleteModal={deleteModal} setDeleteModal={setDeleteModal} callback={onDelete} />
        </Grid>
    );
}

const useStyles = makeStyles(() => ({
    scrollMargin: {
        scrollMarginTop: '75px'
    },
    disableBorder: {
        border: 'none !important'
    },
    gridImage: {
        width: 250
    },
    gridContent: {
        padding: '0 15px'
    },
    gridPrice: {
        width: 100
    },
    deleteContainer: {
        textAlign: 'right'
    },
    delete: {
        cursor: 'pointer'
    },
    image: {
        borderRadius: 16,
        width: 235
    },
    selector: {
        margin: '16px 0 8px 0'
    },
    variant: {
        margin: '16px 0 8px 0'
    },
    containerMargin: {
        marginBottom: 4
    },
    updateQuotationStatus: {
        textDecoration: 'underline',
        marginBottom: 4,
        marginTop: 4,
        cursor: 'pointer'
    },
    addNote: {
        textDecoration: 'underline',
        marginBottom: 4,
        cursor: 'pointer'
    },
    manualCancellationRules: {
        textDecoration: 'underline',
        marginBottom: 4,
        cursor: 'pointer'
    },
    editManualProduct: {
        textDecoration: 'underline',
        marginBottom: 4,
        cursor: 'pointer'
    },
    name: {
        fontSize: 20,
        fontWeight: 'bold'
    },
    description: {
        whiteSpace: 'pre-wrap'
    },
    validateButton: {
        backgroundColor: '#E6592F',
        color: 'white'
    },
    booking: {
        borderRadius: 16,
        fontWeight: 700,
        padding: '4px 12px',
        margin: '4px 0',
        textTransform: 'uppercase'
    },
    booked: {
        border: '2px solid #4caf50',
        color: '#4caf50'
    },
    pending: {
        border: '2px solid #ff9800',
        color: '#ff9800'
    },
    cached: {
        float: 'left',
        marginRight: 8,
        cursor: 'pointer'
    },
    cancelled: {
        border: '2px solid black',
        color: 'black'
    },
    error: {
        border: '2px solid #f44336',
        color: '#f44336'
    },
    price: {
        textAlign: 'right',
        fontSize: 32,
        fontWeight: 'bold'
    },
    poiInPropertyCollapsed: {
        float: 'left',
        cursor: 'pointer',
        marginRight: 4
    },
    poiInPropertyIconCollapsed: {
        verticalAlign: 'text-top'
    },
    composition: {
        float: 'left',
        marginRight: 4
    },
    hideMargin: {
        textDecoration: 'underline',
        cursor: 'pointer'
    },
    hideProduct: {
        background: "#eeee",
        border: "solid 2px #E6592F",
        padding: 15,
        borderRadius: 10,
        marginBottom: 20
    },
    inQuotation: {
        border: "solid 2px #43D67D",
        padding: 15,
        borderRadius: 10,
        marginBottom: 20
    },
    isOption: {
        border: "solid 2px #eeee",
        padding: 15,
        borderRadius: 10,
        marginBottom: 20
    },
    progress: {
        color: green[500],
        position: 'absolute',
        top: "16%",
        left: "43%",
        marginLeft: -12
    },
    checkboxContainer: {
        position: 'relative',
        marginTop: '-5px'
    },
    cancelButton: {
        border: '1px solid',
        backgroundColor: 'white',
        color: '#E6592F'
    },
    calendar: {
        float: 'left',
        marginRight: 4
    },
    pin: {
        float: 'left',
        marginRight: 4
    },
    infoCustomInformation: {
        border: '1px solid #FF9800',
        color: 'white',
        background: '#FF9800',
        marginRight: 5
    },
    traverlerModification: {
        borderRadius: 16,
        padding: '1px 6px'
    },
    cancelConditionAlert: {
        "whiteSpace": 'pre-wrap',
        "&& .MuiAlert-action": {
            minWidth: 140
        }
    }
}));
