//Dependencies
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState, Fragment, useContext } from 'react';
import MomentAdapter from '@material-ui/pickers/adapter/moment';
//Core
import {
    Alert,
    Autocomplete,
    Button,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    IconButton,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import {
    TimeField,
    LocalizationProvider as XDatePickerLocalizationProvider
} from '@mui/x-date-pickers';
import { AdapterMoment as XDateAdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Close } from '@mui/icons-material';
import { Grid, makeStyles } from '@material-ui/core';
import { DateRangeDelimiter, DateRangePicker, LocalizationProvider } from '@material-ui/pickers';
import CheckBoxOutlineBlank from "@material-ui/icons/CheckBoxOutlineBlank";
import clsx from "clsx";
import { EditorState } from 'draft-js';
//Components
import CartManualProductFormPrice from './CartManualProductFormPrice';
import CartManualProductFormFlight from './CartManualProductFormFlight';
import CartManualProductFormManualFlight from './CartManualProductFormManualFlight';
import CartManualProductFormAccommodation from './CartManualProductFormAccommodation';
import { CartManualProductFormAddressPicker } from './CartManualProductFormAddressPicker';
import { OverlappingAccommodationsModal } from '../Accommodation/FunctionalComponent/OverlappingAccommodationsModal';
import CartManualTvaMargin from "./CartManualTvaMargin";
import CartManualProductFormCar from './CartManualProductFormCar';
import CheckBoxFilled from "@material-ui/icons/CheckBox";
import PictureModalCreateForm from "../Menu/MaterialTripList/picture/pictureModalCreateForm";
import RichEditor, { RichEditorStyleMap } from '../Menu/MaterialTripList/utils/editor/editor';
//Common
import { ProviderContext } from './utils/providerContext';
import { useShowError } from '../Utils/showError';
import { customColors } from '../Menu/MaterialTripList/utils/editor/customColors';
import { restoreRichEditorStateFromHtml } from '../Menu/MaterialTripList/utils/editor/restoreRichEditorStateFromHtml';
import { renderRichEditorContentHTML } from '../Menu/MaterialTripList/utils/editor/renderRichEditorContentHtml';
import GetCookie from '../Common/Functions/GetCookie';
import CheckBeforeRequest from '../Common/CheckBeforeRequest';
import { SetItinerary } from "../../Actions/Itinerary";

const EmptyState = EditorState.createEmpty();

const CartManualProductForm = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const language = useSelector(state => state.header.language);
    const data_trip = useSelector((state) => state.trip.data_trip);
    const trip_start_date = useSelector(state => state.trip.start_date);
    const trip_end_date = useSelector(state => state.trip.end_date);
    const itinerary_list = useSelector(state => state.itinerary.itinerary_list);
    const name = useSelector(state => state.cart.manual_product_name);
    const product_type = useSelector(state => state.cart.manual_product_type);
    const destination_associated = useSelector(state => state.cart.manual_product_destination_associated);
    const provider_id = useSelector(state => state.cart.manual_product_provider_id);
    const provider_name = useSelector(state => state.cart.manual_product_provider_name);
    const manual_product_groups = useSelector(state => state.cart.manual_product_groups);
    const manual_product_id = useSelector(state => state.cart.manual_product_id);
    const manual_product_geolocation = useSelector(state => state.cart.manual_product_geolocation);
    const price_id = useSelector(state => state.cart.manual_product_price_id);
    const default_pictures = useSelector(state => state.base.default_pictures);
    const start_date = useSelector(state => state.cart.manual_product_start_date);
    const start_hour = useSelector(state => state.cart.manual_product_start_hour);
    const end_date = useSelector(state => state.cart.manual_product_end_date);
    const end_hour = useSelector(state => state.cart.manual_product_end_hour);
    const stars = useSelector(state => state.cart.manual_product_stars);
    const rooms = useSelector(state => state.cart.manual_product_rooms);
    const picture = useSelector(state => state.cart.manual_product_picture);
    const cgos_reduction = useSelector(state => state.cart.manual_product_cgos_reduction);
    const breakfast_included = useSelector(state => state.cart.manual_product_breakfast_included);
    const lunch_included = useSelector(state => state.cart.manual_product_lunch_included);
    const diner_included = useSelector(state => state.cart.manual_product_diner_included);
    const resort_fees = useSelector(state => state.cart.manual_product_resort_fees);
    const resort_fees_currency = useSelector(state => state.cart.manual_product_resort_fees_currency);
    const flight_paste = useSelector(state => state.cart.manual_product_flight_paste);
    const flight_taxes = useSelector(state => state.cart.manual_product_flight_taxes);
    const baggages = useSelector(state => state.cart.manual_product_baggages);
    const margin_type = useSelector(state => state.cart.manual_product_margin_type);
    const margin_value = useSelector(state => state.cart.manual_product_margin_value);
    const purchase_price = useSelector(state => state.cart.manual_product_purchase_price);
    const purchase_currency = useSelector(state => state.cart.manual_product_purchase_currency);
    const description = useSelector(state => state.cart.manual_product_description);
    const cancellation_note = useSelector(state => state.cart.manual_product_cancellation_note);
    const no_refund = useSelector(state => state.cart.manual_product_no_refund);
    const note = useSelector(state => state.cart.manual_product_note);
    const manual_item_list = useSelector(state => state.cart.manual_item_list);
    const manual_product_poi = useSelector(state => state.cart.manual_product_poi);
    const manual_product_hide_hours = useSelector(state => state.cart.manual_product_hide_hours);
    const manual_product_hide_address = useSelector(state => state.cart.manual_product_hide_address);
    const providers = useSelector(state => state.trip.providers);
    const travelers = useSelector(state => state.trip.travelers);
    const manual_providers = useSelector(state => state.trip.manual_providers);
    const tva_margin = useSelector(state => state.cart.manual_product_tva_margin);
    const address_pickup = useSelector(state => state.cart.manual_product_address_pickup);
    const station_name_pickup = useSelector(state => state.cart.manual_product_station_name_pickup);
    const address_return = useSelector(state => state.cart.manual_product_address_return);
    const station_name_return = useSelector(state => state.cart.manual_product_station_name_return);
    const vehicle_code = useSelector(state => state.cart.manual_product_vehicle_code);
    const one_way_fees = useSelector(state => state.cart.manual_product_one_way_fees);
    const one_way_fees_currency = useSelector(state => state.cart.manual_product_one_way_fees_currency);
    const is_hidden_for_traveler_count_price = useSelector(state => state.cart.manual_product_is_hidden_for_traveler_count_price);
    const manual_product_form = useSelector(state => state.cart.manual_product_form);
    const user = useSelector(state => state.user.user);
    const [groups, setGroups] = useState([]);
    const [nameError, setNameError] = useState(false);
    const [providerError, setProviderError] = useState(false);
    const [geolocationError, setGeolocationError] = useState(false);
    const [datesError, setDatesError] = useState(false);
    const [travelersError, setTravelersError] = useState(false);
    const [roomNameError, setRoomNameError] = useState(false);
    const [purchaseCurrencyError, setPurchaseCurrencyError] = useState(false);
    const [resortFeesError, setResortFeesError] = useState(false);
    const [stepError, setStepError] = useState(false);
    const [flightPasteError, setFlightPasteError] = useState(false);
    const [carsErrors, setCarsErrors] = useState([]);
    const [providersOption, setProvidersOption] = useState([]);
    const [productProvider, setProductProvider] = useState(null);
    const [openPicturesModal, setOpenPicturesModal] = useState(false);
    const [status, setStatus] = useState('included');
    const [loading, setLoading] = useState(false);
    const [geolocation, setGeolocation] = useState({
        address: '',
        position: {
            latitude: null,
            longitude: null
        }
    });
    const [descriptionEditorState, setDescriptionEditorState] = useState(EmptyState);
    const showError = useShowError();
    const providerContext = useContext(ProviderContext);
    const quotation_code = JSON.parse(localStorage.getItem('config')).quotation_code;

    const onSetDates = dates => {
        dispatch({ type: 'CART_SET_MANUAL_PRODUCT_DATES', payload: { start_date: dates[0] === null ? null : dates[0].format('YYYY-MM-DD'), end_date: dates[1] === null ? null : dates[1].format('YYYY-MM-DD') } });
    };
    const onNameChange = event => {
        dispatch({ type: 'CART_MANUAL_PRODUCT_NAME', payload: event.target.value });
    };
    const onProductTypeChange = event => {
        dispatch({ type: 'CART_SET_MANUAL_PRODUCT_FLIGHT_PASTE', payload: null });
        if (parseInt(event.target.value) === 61 && quotation_code === "cercledesvoyages") {
            dispatch({ type: 'CART_MANUAL_PRODUCT_NO_REFUND_CHANGE', payload: true });
        }
        dispatch({
            type: 'CART_MANUAL_PRODUCT_TYPE',
            payload: { value: parseInt(event.target.value), default_pictures: default_pictures, start_date: window.moment.utc(trip_start_date).format('YYYY-MM-DD'), end_date: window.moment.utc(trip_end_date).format('YYYY-MM-DD') }
        });
        setPurchaseCurrencyError(false);
        setResortFeesError(false);
        setNameError(false);
        setFlightPasteError(false);
        setStepError(false);
        setProviderError(false);
        setDatesError(false);
        setRoomNameError(false);
        setTravelersError(false);
    };
    const onDestinationAssociatedChange = event => {
        dispatch({ type: 'CART_MANUAL_PRODUCT_DESTINATION_ASSOCIATED', payload: { value: parseInt(event.target.value), itinerary_list: itinerary_list } });
    };
    const onProviderChange = (event, value) => {
        setProductProvider(value);
        dispatch({ type: 'CART_MANUAL_PRODUCT_PROVIDER', payload: value });
    };
    const onProviderNameChange = event => {
        dispatch({ type: 'CART_MANUAL_PRODUCT_PROVIDER_NAME', payload: event.target.value });
    };
    const onCgosReductionChange = () => {
        dispatch({ type: 'CART_TOGGLE_CGOS_REDUCTION' });
    };
    const onDescriptionChange = event => {
        dispatch({ type: 'CART_MANUAL_PRODUCT_DESCRIPTION', payload: event.target.value });
    };
    const onNoteChange = event => {
        dispatch({ type: 'CART_MANUAL_PRODUCT_NOTE', payload: event.target.value });
    };
    const onCancelManualProduct = () => {
        if (manual_product_id !== null) {
            const element = document.getElementById(`manual-product-${manual_product_id}`);
            if (element !== undefined && element !== null) {
                element.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
            }
        }
        dispatch({ type: 'CART_MANUAL_PRODUCT_CANCEL' });
    };
    const toggleTraveler = id => () => {
        let tmp_groups = [...groups];
        if (tmp_groups.includes(id)) {
            tmp_groups.splice(tmp_groups.indexOf(id), 1);
        } else {
            tmp_groups.push(id);
        }
        setGroups(tmp_groups);
    };
    const onCheck = () => {
        setPurchaseCurrencyError(false);
        setResortFeesError(false);
        setNameError(false);
        setFlightPasteError(false);
        setStepError(false);
        setProviderError(false);
        setDatesError(false);
        setRoomNameError(false);
        setTravelersError(false);
        setGeolocationError(false);
        let pass_control = true;
        if (purchase_currency === null) {
            enqueueSnackbar(t('cart-material.manual-product-no-purchase-currency'), { variant: 'warning' });
            pass_control = false;
            setPurchaseCurrencyError(true);
        }
        if (resort_fees !== null && resort_fees_currency === null) {
            enqueueSnackbar(t('cart-material.manual-product-no-resort-fees-currency'), { variant: 'warning' });
            pass_control = false;
            setResortFeesError(true);
        }
        if (name === null || name === '') {
            enqueueSnackbar(t('cart-material.manual-product-no-name'), { variant: 'warning' });
            pass_control = false;
            setNameError(true);
        }
        if (product_type === 61 && (flight_paste === null || flight_paste === '')) {
            enqueueSnackbar(t('cart-material.manual-product-no-flight-paste'), { variant: 'warning' });
            pass_control = false;
            setFlightPasteError(true);
        }
        if ([0, 12, 1, 3].includes(product_type) && destination_associated === -1) {
            enqueueSnackbar(t('cart-material.manual-product-no-destination-associated'), { variant: 'warning' });
            pass_control = false;
            setStepError(true);
        }
        if ((![16, 18, 20, 21, 22].includes(product_type) && !manual_product_poi) && productProvider === null && provider_name === '') {
            enqueueSnackbar(t('cart-material.manual-product-no-provider-selected'), { variant: 'warning' });
            pass_control = false;
            setProviderError(true);
        }
        if ([0, 1, 2, 3, 4, 5, 7, 9, 10, 12, 13].includes(product_type) && (start_date === null || end_date === null)) {
            enqueueSnackbar(t('cart-material.manual-product-no-dates'), { variant: 'warning' });
            pass_control = false;
            setDatesError(true);
        }
        if (
            quotation_code === 'cercledesvoyages' &&
            [0, 1, 12].includes(product_type) &&
            (
                geolocation.address.trim().length === 0 ||
                geolocation.position.latitude === null ||
                geolocation.position.longitude === null
            )
        ) {
            enqueueSnackbar(t('cart-material.manual-product-no-geolocation'), { variant: 'warning' });
            pass_control = false;
            setGeolocationError(true);
        }
        if (product_type === 0) {
            rooms.map(room => {
                if (room.name === '') {
                    enqueueSnackbar(t('cart-material.manual-product-no-room-name'), { variant: 'warning' });
                    pass_control = false;
                    setRoomNameError(true);
                }
                if (!!room.group && room.group.length === 0) {
                    enqueueSnackbar(t('cart-material.manual-product-no-travelers'), { variant: 'warning' });
                    pass_control = false;
                    setTravelersError(true);
                }
            });
        } else if (
            product_type !== 18 &&
            product_type !== 20 &&
            product_type !== 21 &&
            product_type !== 22 &&
            groups.length === 0 &&
            !manual_product_poi
        ) {
            enqueueSnackbar(t('cart-material.manual-product-no-travelers'), { variant: 'warning' });
            pass_control = false;
            setTravelersError(true);
        }
        return pass_control;
    };
    const onConfirm = async (choice) => {
        const { headers } = CheckBeforeRequest();
        setLoading(true);
        if (product_type === 0) {
            let group_request = 0;
            await Promise.all(
                rooms.map(async (room) => {
                    try {
                        const response = await axios({
                            method: 'POST',
                            headers: headers,
                            url: `${API_HREF}client/${window.id_owner}/trip/${providerContext.module ? providerContext.tripId : GetCookie('trip_id')}/versions/${providerContext.module ? providerContext.version : GetCookie('trip_id_version')}/travelers-group/`,
                            data: {
                                travelers: room.group,
                                default: false
                            },
                            params: {
                                token: providerContext.module ?
                                    providerContext.tripToken :
                                    GetCookie('trip_token')
                            }
                        });
                        room.group = undefined;
                        room.group_passenger = undefined;
                        room.group_passenger_id = response.data.id;
                        group_request++;
                        if (group_request === rooms.length) {
                            await onCreateManualProduct(null, choice);
                        }
                    } catch (error) {
                        console.error(error);
                    }
                })
            );
        } else if (
            product_type === 18 ||
            product_type === 20 ||
            product_type === 21 ||
            product_type === 22 ||
            (product_type === 12 && manual_product_poi)
        ) {
            await onCreateManualProduct(null);
        } else if (groups.length > 0) {
            try {
                const response = await axios({
                    method: 'POST',
                    headers: headers,
                    url: `${API_HREF}client/${window.id_owner}/trip/${providerContext.module ? providerContext.tripId : GetCookie('trip_id')}/versions/${providerContext.module ? providerContext.version : GetCookie('trip_id_version')}/travelers-group/`,
                    data: {
                        travelers: groups,
                        default: false
                    },
                    params: {
                        token: providerContext.module ?
                            providerContext.tripToken :
                            GetCookie('trip_token')
                    }
                });
                await onCreateManualProduct(response.data.id);
            } catch (error) {
                console.error(error);
            }
        }
        setLoading(false);
    };
    const onCreateManualProduct = (group_passenger, choice) => {
        const { headers } = CheckBeforeRequest();
        let flight_segment = [];
        let price_change = true;
        if (price_id !== null && manual_product_id !== null) {
            manual_item_list.map(manual_product => {
                if (manual_product.id === manual_product_id) {
                    manual_product.prices.map(price => {
                        if (price.id === price_id) {
                            if (parseFloat(price.purchase_price) === parseFloat(purchase_price) &&
                                parseFloat(price.custom_value) === parseFloat(margin_value) &&
                                price.custom_rate_type === margin_type &&
                                (purchase_currency !== null ? purchase_currency === 47 ? 47 : purchase_currency.id : null) === price.purchase_currency
                            ) {
                                price_change = false;
                            }
                        }
                    });
                }
            });
        }
        const styles = {
            ...RichEditorStyleMap,
            ...(quotation_code ? customColors[quotation_code] : null)
        };

        let isOptional = choice ?
            choice === 'option' :
            undefined;

        if (props.isForProviderCreation) {
            isOptional = status === 'optional' ? true : undefined;
        }

        const data = {
            name: name,
            product_type: product_type === 61 ? 6 : product_type === -1 ? 11 : product_type,
            step: destination_associated === -1 ? null : destination_associated,
            provider_name: provider_name === '' ? null : provider_name ?? null,
            provider_id: productProvider ? productProvider.provider.id : null,
            start_date: start_date !== null ? window.moment.utc(start_date).set('hours', start_hour.split(':')[0]).set('minutes', start_hour.split(':')[1]).format() : null,
            end_date: end_date !== null ? window.moment.utc(end_date).set('hours', end_hour.split(':')[0]).set('minutes', end_hour.split(':')[1]).format() : null,
            address: geolocation.address.trim().length > 0 ?
                geolocation.address :
                null,
            latitude: geolocation.position.latitude?.toString().slice(0, 12),
            longitude: geolocation.position.longitude?.toString().slice(0, 12),
            stars: stars,
            breakfast_included: breakfast_included,
            lunch_included: lunch_included,
            diner_included: diner_included,
            rooms: product_type === 0 ? rooms : null,
            resort_fees: resort_fees,
            resort_fees_currency: resort_fees_currency !== null ? resort_fees_currency === 47 ? 47 : resort_fees_currency.id : null,
            flight_paste: flight_paste,
            flight_segment: flight_segment === null || flight_segment.length === 0 ? null : flight_segment,
            cgos_reduction: cgos_reduction,
            description: renderRichEditorContentHTML(
                styles,
                descriptionEditorState.getCurrentContent()
            ),
            no_refund: no_refund,
            cancel_condition_text: cancellation_note,
            custom_information: note !== '' ? note : null,
            group_passenger: group_passenger === null ? undefined : group_passenger,
            taxes: flight_taxes,
            nb_baggages: baggages,
            picture_id: picture !== null ? picture.id : null,
            forced_tva_price: product_type === 22 ? tva_margin : undefined,
            is_hidden_for_traveler_count_price: product_type === 21 || product_type === 22 || product_type === -1 || is_hidden_for_traveler_count_price,
            is_optional: isOptional,
            prices: price_change ? [{
                purchase_price: purchase_price,
                purchase_currency: purchase_currency !== null ? purchase_currency === 47 ? 47 : purchase_currency.id : null,
                custom_rate: true,
                custom_rate_type: (product_type === 20 || product_type === 21) ? 'FIX' : margin_type,
                custom_value: margin_value,
                id: price_id === null ? undefined : price_id
            }] : undefined,
            poi_type: product_type === 12 ? manual_product_poi : undefined,
            hide_hours: product_type === 12 || product_type === 4 ? manual_product_hide_hours : undefined,
            hide_address: product_type === 12 || product_type === 4 ? manual_product_hide_address : undefined,
            address_pickup: (product_type === 2 || product_type === 4) && address_pickup !== '' ? address_pickup : null,
            station_name_pickup: (product_type === 2 || product_type === 4) && station_name_pickup !== '' ? station_name_pickup : null,
            address_return: (product_type === 2 || product_type === 4) && address_return !== '' ? address_return : null,
            station_name_return: (product_type === 2 || product_type === 4) && station_name_return !== '' ? station_name_return : null,
            vehicle_code: (product_type === 2 || product_type === 4) && vehicle_code !== '' ? vehicle_code : null,
            one_way_fees: (product_type === 2 || product_type === 4) && one_way_fees !== '' ? one_way_fees : null,
            one_way_fees_currency: (product_type === 2 || product_type === 4) && !!one_way_fees_currency ? one_way_fees_currency.id : null
        };
        return axios({
            method: manual_product_id !== null ? 'PATCH' : 'POST',
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${providerContext.module ? providerContext.tripId : GetCookie('trip_id')}/versions/${providerContext.module ? providerContext.version : GetCookie('trip_id_version')}/manual-products/${manual_product_id !== null ? `${manual_product_id}/` : ''}`,
            data: props.dataTransformer ?
                props.dataTransformer(data) :
                data,
            params: {
                token: providerContext.module ?
                    providerContext.tripToken :
                    null
            }
        }).then(response => {
            console.log('response.data : ', response.data);
            if (manual_product_id !== null) {
                const element = document.getElementById(`manual-product-${manual_product_id}`);
                if (element !== undefined && element !== null) {
                    element.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
                }
                dispatch({ type: 'CART_EDIT_MANUAL_ITEM', payload: response.data });
                enqueueSnackbar(t('cart-material.manual-product-edited'), { variant: 'success' });
            } else {
                dispatch({ type: 'CART_ADD_MANUAL_ITEM', payload: response.data });
                if (response.data.product_type === 6) {
                    axios({
                        method: 'GET',
                        headers: headers,
                        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/itinerary/`,
                        params: {
                            token: providerContext.module ?
                                providerContext.tripToken :
                                GetCookie("trip_token")
                        }
                    }).then(response => {
                        dispatch(SetItinerary(response.data));
                    });
                }
                enqueueSnackbar(t('cart-material.manual-product-added'), { variant: 'success' });
                if (response.data.counter_offer_of) {
                    const event = new CustomEvent(
                        'counter-offer-created',
                        { detail: response.data.counter_offer_of }
                    );
                    document.dispatchEvent(event);
                }
            }
        }).catch(error => {
            showError(error);
        });
    };
    const onChangePicture = (pictures) => {
        dispatch({
            type: 'CART_SET_MANUAL_PRODUCT_PICTURE',
            payload: pictures[0]
        });
    };
    const onToggleManualProductPictureModal = () => {
        setOpenPicturesModal(true);
    };
    const onSetStartHour = (value) => {
        dispatch({ type: 'CART_SET_MANUAL_PRODUCT_START_HOUR', payload: value });
    };
    const onSetEndHour = (value) => {
        dispatch({ type: 'CART_SET_MANUAL_PRODUCT_END_HOUR', payload: value });
    };
    const onRemovePicture = () => {
        dispatch({ type: 'CART_SET_MANUAL_PRODUCT_PICTURE', payload: null });
    };
    const onNoRefundChange = event => {
        if (event.target.value) {
            dispatch({ type: 'CART_MANUAL_PRODUCT_CANCELLATION_NOTE', payload: t('cart-material.not-cancellable') });
        } else if (manual_product_id !== null) {
            const { headers } = CheckBeforeRequest();
            axios({
                method: 'GET',
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${providerContext.module ? providerContext.tripId : GetCookie('trip_id')}/versions/${providerContext.module ? providerContext.version : GetCookie('trip_id_version')}/manual-products/${manual_product_id}/get_cancellation_text/`,
                params: {
                    token: providerContext.module ?
                        providerContext.tripToken :
                        GetCookie("trip_token")
                }
            }).then(response => {
                dispatch({ type: 'CART_MANUAL_PRODUCT_CANCELLATION_NOTE', payload: response.data.cancellation_text });
            }).catch((error) => {
                console.error(error);
            });
        }
        dispatch({ type: 'CART_MANUAL_PRODUCT_NO_REFUND_CHANGE', payload: event.target.value });
    };
    const onCancellationRulesChange = event => {
        dispatch({ type: 'CART_MANUAL_PRODUCT_CANCELLATION_NOTE', payload: event.target.value });
    };
    const handlePOI = () => {
        dispatch({ type: 'CART_SET_MANUAL_POI' });
    };
    const handleHideHours = () => {
        dispatch({ type: 'CART_TOGGLE_MANUAL_HIDE_HOURS' });
    };
    const handleHideAddress = () => {
        dispatch({ type: "CART_TOGGLE_MANUAL_HIDE_ADDRESS" });
    };
    const onClose = () => {
        if (!loading) {
            dispatch({ type: 'CART_MANUAL_PRODUCT_CANCEL' });
        }
    };

    const onChangeGeolocation = (key, value) => {
        setGeolocation((state) => ({
            ...state,
            [key]: value
        }));
    };

    useEffect(() => {
        setGeolocation(manual_product_geolocation);
    }, [manual_product_geolocation]);

    useEffect(() => {
        const travelersIds = travelers.map((item) => {
            return item.id;
        });
        setGroups(
            manual_product_groups.filter((item) => {
                return travelersIds.includes(item);
            })

        );
    }, [manual_product_groups, travelers]);
    useEffect(() => {
        let option_provider = [];
        providers.map(provider => {
            if (provider.enabled_content_types.includes(product_type === 61 ? 6 : product_type === -1 ? 11 : product_type)) {
                option_provider.push(provider);
            }
        });
        manual_providers.map(provider => {
            if (provider.available_content_types.includes(product_type === 61 ? 6 : product_type === -1 ? 11 : product_type) && (provider.is_xml === undefined || quotation_code === 'cercledesvoyages' || quotation_code === 'tropicalementvotre') && (!provider.activation_countries || provider.activation_countries.length === 0 || provider.activation_countries.map(country => country.id).includes(data_trip?.destination?.data?.country?.id))) {
                option_provider.push(provider);
            }
        });
        if (option_provider.find(provider => provider.provider.id === provider_id) !== undefined) {
            setProductProvider(option_provider.find(provider => provider.provider.id === provider_id));
        }
        setProvidersOption(option_provider.sort((a, b) => a.provider.name < b.provider.name));
    }, [product_type, provider_id, manual_providers]);
    useEffect(() => {
        const element = document.getElementById('cart-manual-product-form');
        let option_provider = [];
        if (element !== undefined && element !== null) {
            let headerOffset = 70;
            let elementPosition = element.getBoundingClientRect().top;
            let offsetPosition = elementPosition + window.pageYOffset - headerOffset;
            window.scrollTo({ top: offsetPosition, behavior: 'smooth' });
        }
        providers.map(provider => {
            if (provider.enabled_content_types.includes(product_type === 61 ? 6 : product_type === -1 ? 11 : product_type)) {
                option_provider.push(provider);
            }
        });
        manual_providers.map(provider => {
            if (provider.available_content_types.includes(product_type === 61 ? 6 : product_type === -1 ? 11 : product_type) && (provider.is_xml === undefined || quotation_code === 'cercledesvoyages' || quotation_code === 'tropicalementvotre') && (!provider.activation_countries || provider.activation_countries.length === 0 || provider.activation_countries.map(country => country.id).includes(data_trip?.destination?.data?.country?.id))) {
                option_provider.push(provider);
            }
        });
        let tmp_groups = [];
        travelers.map((traveler) => tmp_groups.push(traveler.id));
        setGroups(tmp_groups);
        setProvidersOption(option_provider);
        if (option_provider.find(provider => provider.provider.id === provider_id) !== undefined) {
            setProductProvider(option_provider.find(provider => provider.provider.id === provider_id));
        }
    }, []);

    useEffect(() => {
        if (props.provider) {
            setProductProvider(props.provider);
        }
    }, [props.provider]);

    useEffect(() => {
        if (!manual_product_form) {
            setGeolocation({
                address: '',
                position: {
                    latitude: null,
                    longitude: null
                }
            });
            setProductProvider(null);
            setDescriptionEditorState(EmptyState);
        }
    }, [manual_product_form]);

    useEffect(() => {
        if (description) {
            const styles = {
                ...RichEditorStyleMap,
                ...(quotation_code ? customColors[quotation_code] : null)
            };
            const state = restoreRichEditorStateFromHtml(
                styles,
                description
            );
            setDescriptionEditorState(state);
        }
    }, [description]);

    return (
        <>
            <OverlappingAccommodationsModal
                onConfirm={onConfirm}
            >
                {
                    (checkOverlappingAccommodation) => (
                        <Dialog
                            open={manual_product_form}
                            onClose={onClose}
                            maxWidth="md"
                        >
                            <DialogTitle>
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    <Typography fontWeight="bold">
                                        {t('cart.manual-product-modal-title')}
                                    </Typography>
                                    <IconButton onClick={onClose}>
                                        <Close />
                                    </IconButton>
                                </Stack>
                            </DialogTitle>
                            <DialogContent sx={{ paddingTop: '8px !important' }}>
                                <Grid id={'cart-manual-product-form'} container>
                                    <Grid className={classes.gridContent} container item xs spacing={2}>
                                        <Grid item xs={12}>
                                            <FormControl fullWidth variant={'outlined'} error={nameError}>
                                                <InputLabel htmlFor={'custom-product-name'}>{t('cart-material.manual-product-name')}</InputLabel>
                                                <OutlinedInput
                                                    id={'custom-product-name'}
                                                    type={'text'}
                                                    onChange={onNameChange}
                                                    label={t('cart-material.manual-product-name')}
                                                    value={name}
                                                />
                                                {nameError && <FormHelperText>{t('cart-material.manual-product-no-name')}</FormHelperText>}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Select
                                                className={classes.select}
                                                variant={'outlined'}
                                                value={product_type}
                                                onChange={onProductTypeChange}
                                                disabled={props.isForProposition}
                                            >
                                                <MenuItem value={12}>{t('providers.activities')}</MenuItem>
                                                <MenuItem value={8}>{t('providers.insurances')}</MenuItem>
                                                {quotation_code !== 'verdie' && quotation_code !== 'cercledesvoyages' && <MenuItem value={11}>{t('providers.other_product')}</MenuItem>}
                                                {quotation_code === 'cercledesvoyages' && <MenuItem value={11}>{t('providers.other_visible_product')}</MenuItem>}
                                                {quotation_code === 'cercledesvoyages' && <MenuItem value={-1}>{t('providers.other_invisible_product')}</MenuItem>}
                                                {
                                                    quotation_code !== "cercledesvoyages" && (
                                                        <MenuItem value={7}>{t('providers.accommodations')}</MenuItem>
                                                    )
                                                }
                                                {
                                                    (quotation_code !== "cercledesvoyages" || manual_product_id !== null) && (
                                                        <MenuItem value={17}>{t('providers.travel_book')}</MenuItem>
                                                    )
                                                }
                                                <MenuItem value={9}>{t('providers.cruises')}</MenuItem>
                                                {
                                                    quotation_code !== "cercledesvoyages" && (
                                                        <MenuItem value={1}>{t('providers.excursions')}</MenuItem>
                                                    )
                                                }
                                                <MenuItem value={13}>{t('providers.ferries')}</MenuItem>
                                                {
                                                    quotation_code !== 'cercledesvoyages' ?
                                                        <MenuItem value={16}>{t('providers.administration_fees')}</MenuItem>
                                                        :
                                                        <MenuItem value={16}>{t('providers.administration_fees_other')}</MenuItem>
                                                }
                                                <MenuItem value={10}>{t('providers.touristic_guide')}</MenuItem>
                                                <MenuItem value={0}>{t('providers.hotels')}</MenuItem>
                                                <MenuItem value={18}>{t('providers.reduction')}</MenuItem>
                                                {
                                                    quotation_code !== "cercledesvoyages" && (
                                                        <MenuItem value={3}>{t('providers.restaurant')}</MenuItem>
                                                    )
                                                }
                                                <MenuItem value={5}>{t('providers.trains')}</MenuItem>
                                                <MenuItem value={4}>{t('providers.transfers')}</MenuItem>
                                                <MenuItem value={2}>{t('providers.rental_car')}</MenuItem>
                                                { /*<MenuItem value={ 6 }>{ t('providers.flight') }</MenuItem>*/}
                                                <MenuItem value={61}>{t('providers.manual_flight')}</MenuItem>
                                                {
                                                    ((quotation_code === "cercledesvoyages" || quotation_code === "continentsinsolites" || quotation_code === "touristforum") && manual_product_id === null ? manual_item_list.find((item) => item.product_type === 20) === undefined : manual_item_list.find((item) => item.id === manual_product_id) !== undefined) && (
                                                        <MenuItem value={20}>{t("header.adjustment_product_name")}</MenuItem>
                                                    )
                                                }
                                                {
                                                    quotation_code === 'cercledesvoyages' &&
                                                    <MenuItem value={21}>{t("providers.manual_margin_adjustment")}</MenuItem>
                                                }
                                                {
                                                    quotation_code === 'cercledesvoyages' &&
                                                    <MenuItem value={22}>{t("providers.tva_margin")}</MenuItem>
                                                }
                                            </Select>
                                        </Grid>
                                        <Grid item xs={6}>
                                            {
                                                ![6, 18, 8, 16, 11, -1, 20, 21, 22, 61, 2].includes(product_type) &&
                                                <FormControl fullWidth error={stepError} disabled={props.isForProposition}>
                                                    <Select variant={'outlined'} value={destination_associated} onChange={onDestinationAssociatedChange}>
                                                        <MenuItem value={-1}>{t('cart-material.manual-product-choose-destination')}</MenuItem>
                                                        {itinerary_list.map(step => step.step_type === 'STEP' && <MenuItem key={`manual-product-step-${step.id}`} value={step.id}>{step.destination.data.name !== undefined ? step.destination.data.name : step.destination.data.international_name}</MenuItem>)}
                                                    </Select>
                                                    {stepError && <FormHelperText>{t('cart-material.manual-product-no-destination-associated')}</FormHelperText>}
                                                </FormControl>
                                            }
                                        </Grid>
                                        {
                                            [12, 4].includes(product_type) &&
                                            <>
                                                {
                                                    [12].includes(product_type) &&
                                                    <Grid item xs={12}>
                                                        <FormControlLabel control={
                                                            <Checkbox checked={manual_product_poi} onChange={handlePOI} name={"activity-poi"} color={"primary"} />
                                                        } label={t("cart-material.exploration")}
                                                        />
                                                    </Grid>
                                                }
                                                <Grid item xs={6}>
                                                    <FormControlLabel control={
                                                        <Checkbox checked={manual_product_hide_hours} onChange={handleHideHours} name={"activity-hide-hours"} color={"primary"} />
                                                    } label={t("cart-material.hide-hours")}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormControlLabel control={
                                                        <Checkbox checked={manual_product_hide_address} onChange={handleHideAddress} name={"activity-hide-address"} color={"primary"} />
                                                    } label={t("cart-material.hide-address")}
                                                    />
                                                </Grid>
                                            </>
                                        }
                                        {
                                            ![0, 18, 6, 20, 21, 22, 61, 16].includes(product_type) &&
                                            <Fragment>
                                                <Grid item xs={12}>
                                                    <LocalizationProvider dateLibInstance={window.moment} dateAdapter={MomentAdapter} locale={language}>
                                                        <DateRangePicker
                                                            startText={t('accommodation.start_date')}
                                                            endText={t('accommodation.end_date')}
                                                            value={[(start_date !== null ? start_date : trip_start_date), (end_date !== null ? end_date : trip_end_date)]}
                                                            onChange={onSetDates}
                                                            minDate={trip_start_date}
                                                            maxDate={trip_end_date}
                                                            calendars={2}
                                                            desktopModeMediaQuery={'@media (min-width: 0px)'}
                                                            renderInput={(startProps, endProps) => {
                                                                startProps.helperText = undefined;
                                                                startProps.fullWidth = true;
                                                                endProps.helperText = undefined;
                                                                endProps.fullWidth = true;
                                                                return (
                                                                    <Fragment>
                                                                        <TextField {...startProps} error={datesError} />
                                                                        <TextField {...endProps} error={datesError} />
                                                                    </Fragment>
                                                                );
                                                            }}
                                                        />
                                                    </LocalizationProvider>
                                                    {datesError && <Typography className={classes.datesErrorText}>{t('cart-material.manual-product-no-dates')}</Typography>}
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <XDatePickerLocalizationProvider dateAdapter={XDateAdapterMoment} locale={language}>
                                                        <TimeField
                                                            value={window.moment.utc(start_hour, 'HH:mm')}
                                                            label={t('cart-material.manual-product-start-hour')}
                                                            onChange={(value) => {
                                                                if (value) {
                                                                    onSetStartHour(value.format('HH:mm'));
                                                                }
                                                            }}
                                                            fullWidth
                                                        />
                                                    </XDatePickerLocalizationProvider>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <XDatePickerLocalizationProvider dateAdapter={XDateAdapterMoment} locale={language}>
                                                        <TimeField
                                                            value={window.moment.utc(end_hour, 'HH:mm')}
                                                            label={t('cart-material.manual-product-end-hour')}
                                                            onChange={(value) => {
                                                                if (value) {
                                                                    onSetEndHour(value.format('HH:mm'));
                                                                }
                                                            }}
                                                            fullWidth
                                                        />
                                                    </XDatePickerLocalizationProvider>
                                                </Grid>
                                            </Fragment>
                                        }
                                        {
                                            props.isForProviderCreation &&
                                            <Grid item xs={12}>
                                                <FormControl fullWidth>
                                                    <Select value={status} onChange={(event) => setStatus(event.target.value)}>
                                                        <MenuItem value="included">
                                                            {t('cart-material.included-in-quotation')}
                                                        </MenuItem>
                                                        <MenuItem value="optional">
                                                            {t('cart-material.option-product')}
                                                        </MenuItem>
                                                    </Select>
                                                </FormControl>
                                                {
                                                    status === 'optional' &&
                                                    <Alert severity="info" sx={{ marginTop: 1.5 }}>
                                                        {t('cart-material.option-product-hint')}
                                                    </Alert>
                                                }
                                            </Grid>
                                        }
                                        {
                                            [0, 1, 12, 4].includes(product_type) &&
                                            <Grid item xs={12}>
                                                <CartManualProductFormAddressPicker
                                                    {...geolocation}
                                                    errors={
                                                        geolocationError ?
                                                            {
                                                                address: geolocation.address.trim().length === 0,
                                                                position: {
                                                                    latitude: geolocation.position.latitude === null,
                                                                    longitude: geolocation.position.longitude === null
                                                                }
                                                            } :
                                                            undefined
                                                    }
                                                    onChangeLocation={onChangeGeolocation}
                                                />
                                            </Grid>
                                        }
                                        {
                                            !providerContext.module &&
                                            ![16, 18, 20, 21, 22].includes(product_type) && !manual_product_poi &&
                                            <Fragment>
                                                {
                                                    (
                                                        quotation_code !== 'visiteurs' ||
                                                        user?.client_full?.type !== 2
                                                    ) &&
                                                    <Grid item xs={12}>
                                                        <Autocomplete
                                                            id={"manual-product-choose-provider-label"}
                                                            options={providersOption}
                                                            value={productProvider}
                                                            getOptionLabel={(option) => `${option.provider.name}`}
                                                            getOptionSelected={(option, value) => option.provider.id === value.provider.id}
                                                            onChange={onProviderChange}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label={t('cart-material.manual-product-choose-provider')}
                                                                    margin={"normal"}
                                                                    variant={"outlined"}
                                                                    InputProps={{ ...params.InputProps, type: 'search' }}
                                                                    error={providerError}
                                                                    helperText={
                                                                        providerError ?
                                                                            t('cart-material.manual-product-no-provider-selected') :
                                                                            undefined
                                                                    }
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                }
                                                {
                                                    ((quotation_code !== "cercledesvoyages" && user?.client_full?.type !== 2) || quotation_code === "visiteurs") && (
                                                        <Grid item xs={12}>
                                                            {
                                                                provider_id === null && product_type !== 61 &&
                                                                <FormControl fullWidth variant={'outlined'}>
                                                                    <InputLabel htmlFor={'manual-product-provider'}>{t('cart-material.manual-product-provider-name')}</InputLabel>
                                                                    <OutlinedInput
                                                                        id={'manual-product-provider'}
                                                                        type={'text'}
                                                                        onChange={onProviderNameChange}
                                                                        label={t('cart-material.manual-product-provider-name')}
                                                                        value={provider_name}
                                                                    />
                                                                </FormControl>
                                                            }
                                                        </Grid>
                                                    )
                                                }
                                            </Fragment>
                                        }
                                        {
                                            product_type !== 0 && product_type !== 18 && product_type !== 20 && product_type !== 21 && product_type !== 22 && !manual_product_poi &&
                                            <Grid item xs={12}>
                                                <div className={clsx(classes.travelersContainer, { [classes.travelersError]: travelersError })}>
                                                    <Typography>{t('cart-material.manual-product-travelers')} :</Typography>
                                                    {travelers.map((traveler) => {
                                                        let label_name = "";
                                                        let default_name = false;
                                                        let age = window.moment.utc(trip_end_date).diff(window.moment.utc(traveler.birth_date), "y");
                                                        if (traveler.first_name === t('global.first_name') && traveler.last_name === t('global.last_name')) {
                                                            default_name = true;
                                                        }
                                                        if (default_name) {
                                                            if (age >= 18) {
                                                                label_name = `${t("trip.traveler")} ${t("flight_search.adult")}`;
                                                            } else {
                                                                label_name = `${t("trip.traveler")} ${t("flight_search.child")} (${age})`;
                                                            }
                                                        } else {
                                                            label_name = `${traveler.first_name} ${traveler.last_name} ${age < 18 ? `(${age})` : ''}`;
                                                        }
                                                        return (
                                                            <FormControlLabel key={traveler.id} control={<Checkbox checked={groups.includes(traveler.id)} onChange={toggleTraveler(traveler.id)} />}
                                                                label={`${label_name}`}
                                                            />
                                                        );
                                                    }
                                                    )}
                                                </div>
                                            </Grid>
                                        }
                                        {product_type === 0 && <CartManualProductFormAccommodation roomNameError={roomNameError} datesError={datesError} travelersError={travelersError} />}
                                        {product_type === 6 && <CartManualProductFormFlight />}
                                        {product_type === 61 && <CartManualProductFormManualFlight flightPasteError={flightPasteError} />}
                                        {(product_type === 2 || product_type === 4) && <CartManualProductFormCar carsErrors={carsErrors} product_type={product_type} />}
                                        {
                                            (product_type === 18 || product_type === 20 || product_type === 21) &&
                                            <Fragment>
                                                {
                                                    quotation_code === "verdie" && (
                                                        <Grid item xs={12}>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        icon={<CheckBoxOutlineBlank fontSize={'large'} />}
                                                                        checkedIcon={<CheckBoxFilled fontSize={'large'} />}
                                                                        checked={cgos_reduction}
                                                                        onChange={onCgosReductionChange}
                                                                    />
                                                                }
                                                                label={t('cart-material.manual-product-cgos-reduction')}
                                                            />
                                                        </Grid>
                                                    )
                                                }
                                                <Grid item xs={12}><Typography>{t('cart-material.manual-product-negative-price')}</Typography></Grid>
                                            </Fragment>
                                        }
                                        {
                                            product_type !== 22 ?
                                                <CartManualProductFormPrice /> :
                                                <CartManualTvaMargin />
                                        }
                                        {
                                            !manual_product_poi &&
                                            <Grid item xs={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <Typography>{t('flight.cancellation_rules')} :</Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <FormControl fullWidth variant={'outlined'}>
                                                            <Select id={'no-refund'} value={no_refund} onChange={onNoRefundChange}>
                                                                <MenuItem value={false}>{t('cart-material.cancellable-with-conditions')}</MenuItem>
                                                                <MenuItem value={true}>{t('cart-material.not-cancellable')}</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FormControl fullWidth variant={'outlined'}>
                                                            <InputLabel htmlFor={'cancellation-rules'}>{t('cart-material.manual-cancellation-rules')}</InputLabel>
                                                            <OutlinedInput
                                                                id={'cancellation-rules'}
                                                                type={'text'}
                                                                multiline
                                                                rows={4}
                                                                onChange={onCancellationRulesChange}
                                                                label={t('cart-material.manual-cancellation-rules')}
                                                                value={cancellation_note === t('cart-material.not-cancellable') ? '' : cancellation_note}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        }
                                        <Grid item xs={12}>
                                            <Typography sx={{ marginTop: 2, marginBottom: 1 }}>
                                                {
                                                    quotation_code !== "cercledesvoyages" &&
                                                        quotation_code !== "continentsinsolites" &&
                                                        quotation_code !== "touristforum" &&
                                                        quotation_code !== 'tropicalementvotre' ?
                                                        t('cart-material.manual-product-description') :
                                                        t('cart-material.manual-product-client-description')
                                                } :
                                            </Typography>
                                            <RichEditor
                                                values={{}}
                                                pictures={null}
                                                editorState={descriptionEditorState}
                                                setEditorState={setDescriptionEditorState}
                                                controlledState
                                            />
                                        </Grid>
                                        {
                                            !props.isForProposition &&
                                            <Grid item xs={12}>
                                                <FormControl fullWidth variant={'outlined'}>
                                                    <InputLabel htmlFor={'manual-product-note'}>
                                                        {quotation_code !== "cercledesvoyages" && quotation_code !== "continentsinsolites" && quotation_code !== "touristforum" ? t('cart-material.manual-product-note') : t('cart-material.manual-product-intern-note')}
                                                    </InputLabel>
                                                    <OutlinedInput
                                                        id={'manual-product-note'}
                                                        type={'text'}
                                                        rowsMax={Infinity}
                                                        rows={3}
                                                        multiline
                                                        onChange={onNoteChange}
                                                        label={quotation_code !== "cercledesvoyages" && quotation_code !== "continentsinsolites" && quotation_code !== "touristforum" ? t('cart-material.manual-product-note') : t('cart-material.manual-product-intern-note')}
                                                        value={note}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        }
                                        <Grid item xs={12}>
                                            <Divider sx={{ marginTop: 3, marginBottom: 3 }} />
                                            <Typography fontWeight="bold" component="div" sx={{ marginBottom: 2 }}>
                                                {t('shared.circuit-step-image')}
                                            </Typography>
                                            {
                                                !picture &&
                                                <Button
                                                    onClick={onToggleManualProductPictureModal}
                                                >
                                                    {t('shared.add')}
                                                </Button>
                                            }
                                            {
                                                picture &&
                                                <Card sx={{ maxWidth: 300 }}>
                                                    <CardMedia
                                                        component={'img'}
                                                        alt={picture.alt}
                                                        height={200}
                                                        image={picture.url ?? ''}
                                                    />
                                                    <CardContent>
                                                        <Typography>{picture.name}</Typography>
                                                    </CardContent>
                                                    <CardActions>
                                                        <Button onClick={onToggleManualProductPictureModal}>
                                                            {t('cart-material.cart-construction-replace')}
                                                        </Button>
                                                        <Button
                                                            onClick={onRemovePicture}
                                                        >
                                                            {t('shared.delete-picture')}
                                                        </Button>
                                                    </CardActions>
                                                </Card>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={onCancelManualProduct} disabled={loading}>
                                    {t('global.cancel')}
                                </Button>
                                <Button
                                    variant="outlined"
                                    onClick={() => {
                                        if (onCheck()) {
                                            if (
                                                [0, 7].includes(product_type) &&
                                                !manual_product_id
                                            ) {
                                                checkOverlappingAccommodation(
                                                    window.moment
                                                        .utc(start_date)
                                                        .set('hours', start_hour.split(':')[0])
                                                        .set('minutes', start_hour.split(':')[1])
                                                        .toISOString(),
                                                    window.moment
                                                        .utc(end_date)
                                                        .set('hours', end_hour.split(':')[0])
                                                        .set('minutes', end_hour.split(':')[1])
                                                        .toISOString()
                                                );
                                            } else {
                                                onConfirm();
                                            }
                                        }
                                    }}
                                    disabled={loading}
                                >
                                    {
                                        loading &&
                                        <CircularProgress size="20px" />
                                    }
                                    {
                                        !loading &&
                                        t('global.validate')
                                    }
                                </Button>
                            </DialogActions>
                        </Dialog>
                    )
                }
            </OverlappingAccommodationsModal>
            {
                openPicturesModal &&
                <PictureModalCreateForm
                    pictureTypeOnUpload="MANUAL_PRODUCT"
                    onChangePictures={onChangePicture}
                    onClose={() => setOpenPicturesModal(false)}
                    singleFile
                />
            }
        </>
    );
};

const useStyles = makeStyles({
    gridImage: {
        width: 250
    },
    pictureContainer: {
        position: 'relative'
    },
    removePicture: {
        position: 'absolute',
        right: 8,
        top: 8,
        cursor: 'pointer',
        backgroundColor: 'white',
        borderRadius: '50%',
        padding: 2
    },
    imageButton: {
        marginTop: 8
    },
    gridContent: {
        marginLeft: 8
    },
    image: {
        borderRadius: 16
    },
    select: {
        width: '100%'
    },
    travelersContainer: {
        border: '1px solid rgba(0, 0, 0, 0.25)',
        borderRadius: 4,
        padding: '12px 12px 0 12px',
        color: 'rgba(0, 0, 0, 0.54)'
    },
    travelersError: {
        borderColor: 'red'
    },
    datesErrorText: {
        color: 'red',
        fontSize: 12
    },
    buttonSeparator: {
        margin: '16px 0 0 0'
    },
    manualProductCancelButton: {
        border: '1px solid',
        backgroundColor: 'white',
        color: '#E6592F'
    },
    manualProductCreateButton: {
        backgroundColor: '#E6592F',
        color: 'white'
    },
    menuHeight: {
        maxHeight: 216
    }
});

export default CartManualProductForm;
