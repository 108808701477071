import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import { WithRouterProps } from "react-router";
import {
    Box,
    Button,
    Grid,
    Paper,
    Stack,
    Typography,
    styled
} from "@mui/material";
import { ArrowRightAlt, ShoppingCart } from "@mui/icons-material";
import { ProviderBookingNavbar } from "./ProviderBookingNavbar";
import { ProviderBookingContinueModal } from "./ProviderBookingContinueModal";
import { ProviderTrip } from "../ProviderQuotation/providerTrip";
import { ProviderProducts } from "../ProviderQuotation/providerProducts";
import { ProviderContext } from "../CartMaterial/utils/providerContext";
import { useCartProducts } from "../Itinerary/network/cartProducts";
import { useFilteredCartProducts } from "../Itinerary/network/filteredCartProducts";
import { AppState } from "../../Reducers/Reducers";

type Props = WithRouterProps

export default function ProviderBooking(props: Props): JSX.Element {
    const providerId = props.params.providerId ?
        parseInt(props.params.providerId) :
        null;
    const tripId = props.params.tripId;
    const tripVersion = props.params.tripVersion;
    const tripToken = props.params.tripToken;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const travelers = useSelector((state: AppState) => state.trip.travelers);
    const trip = useSelector((state: AppState) => state.trip.all_data);
    const [openContinueModal, setOpenContinueModal] = useState(false);
    const cart = useCartProducts();
    const filteredCart = useFilteredCartProducts();
    const providerContext = useMemo((): Parameters<typeof ProviderContext['Provider']>[0]['value'] => {
        return {
            module: 'booking',
            tripId: tripId ? parseInt(tripId) : 0,
            version: tripVersion ? parseInt(tripVersion) : 0,
            providerId: providerId ?? 0,
            tripToken: tripToken ?? ''
        };
    }, [
        tripId,
        tripVersion,
        tripToken
    ]);
    const products = useMemo(() => {
        return {
            accommodations: cart.accommodations.filter((item) => {
                return item.accommodation.provider === providerId &&
                    (
                        item.type === 'manual' ||
                        item.accommodation.is_custom
                    ) &&
                    item.accommodation.is_provider_quotation_displayed &&
                    !item.accommodation.provider_refused;
            }),
            assistances: cart.assistances.filter((item) => {
                return item.assistance.provider === providerId &&
                    (
                        item.type === 'manual' ||
                        item.assistance.is_custom
                    ) &&
                    item.assistance.is_provider_quotation_displayed &&
                    !item.assistance.provider_refused;
            }),
            cars: cart.cars.filter((item) => {
                return item.car.provider === providerId &&
                    (
                        item.type === 'manual' ||
                        item.car.is_custom
                    ) &&
                    item.car.is_provider_quotation_displayed &&
                    !item.car.provider_refused;
            }),
            flights: cart.flights.filter((item) => {
                return item.flight.provider === providerId &&
                    (
                        item.type === 'manual' ||
                        item.flight.is_custom
                    ) &&
                    item.flight.is_provider_quotation_displayed &&
                    !item.flight.provider_refused;
            }),
            manualProducts: filteredCart.manualProducts.filter((item) => {
                return item.provider === providerId &&
                    item.is_provider_quotation_displayed &&
                    !item.provider_refused;
            }),
            pois: cart.pois.filter((item) => {
                return item.poi.provider === providerId &&
                    (
                        item.type === 'manual' ||
                        item.poi.is_custom
                    ) &&
                    item.poi.is_provider_quotation_displayed &&
                    !item.poi.provider_refused;
            }),
            transfers: cart.transfers.filter((item) => {
                return item.transfer.provider === providerId &&
                    (
                        item.type === 'manual' ||
                        item.transfer.is_custom
                    ) &&
                    item.transfer.is_provider_quotation_displayed &&
                    !item.transfer.provider_refused;
            })
        };
    }, [cart, filteredCart]);

    return (
        <ProviderContext.Provider value={providerContext}>
            <Page>
                <ProviderBookingNavbar {...props} />
                <Box sx={{ padding: 2.5 }}>
                    <Typography
                        fontWeight={100}
                        textAlign="center"
                        maxWidth={{
                            xs: '100%',
                            lg: '50%'
                        }}
                        sx={{ margin: 'auto' }}
                    >
                        <div>
                            <Trans
                                i18nKey="cart-material.provider-booking-title"
                                values={{
                                    name: trip?.user_to ?
                                        [
                                            trip.user_to.first_name,
                                            trip.user_to.last_name
                                        ].filter((item) => item).join(' ') :
                                        trip?.owner.name
                                }}
                                components={{ span: <span style={{ fontWeight: 'bold' }} /> }}
                            />
                        </div>
                        <div>
                            <Trans
                                i18nKey="cart-material.provider-booking-hint"
                                components={{ span: <span style={{ fontWeight: 'bold' }} /> }}
                            />
                        </div>
                    </Typography>
                    <Grid
                        container
                        spacing={5}
                        direction={{
                            xs: 'column-reverse',
                            lg: 'row'
                        }}
                        sx={{
                            marginTop: 2.5,
                            paddingX: {
                                md: 2,
                                lg: 4
                            }
                        }}
                    >
                        <Grid item lg={8} sx={{ width: '100%' }}>
                            <Stack
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                spacing={1}
                                sx={{ marginBottom: 1.5 }}
                            >
                                <ShoppingCart color="primary" />
                                <Typography variant="h6" color="primary" component="div">
                                    {t('cart-material.provider-booking-your-products')}
                                </Typography>
                            </Stack>
                            <Stack spacing={2.5}>
                                <ProviderProducts products={products} disableCounterOffer />
                            </Stack>
                        </Grid>
                        <Grid item lg={4} sx={{ width: '100%' }}>
                            <Box
                                sx={{
                                    position: 'sticky',
                                    top: '36px'
                                }}
                            >
                                <Paper
                                    elevation={1}
                                    sx={{
                                        paddingTop: 2.5,
                                        paddingBottom: 3.5,
                                        paddingLeft: 3.5,
                                        paddingRight: 3.5,
                                        borderRadius: '4px',
                                        marginBottom: 2.5
                                    }}
                                >
                                    <Typography
                                        variant="h6"
                                        fontWeight="bold"
                                        component="div"
                                        sx={{ marginBottom: 1 }}
                                    >
                                        {t('cart-material.provider-booking-travelers')}
                                    </Typography>
                                    {
                                        travelers?.map((traveler, index) => {
                                            const birthDay = window.moment.utc(traveler.birth_date, 'YYYY-MM-DD');
                                            const name = [
                                                traveler.first_name,
                                                traveler.last_name
                                            ].filter((item): item is NonNullable<typeof item> => {
                                                return !!item;
                                            }).join(' ');
                                            return (
                                                <Typography key={index}>
                                                    {
                                                        t(
                                                            'cart-material.provider-booking-traveler',
                                                            {
                                                                title: traveler.title,
                                                                name,
                                                                birthDay: birthDay.format('L'),
                                                                age: window.moment.utc().diff(birthDay, 'years')
                                                            }
                                                        )
                                                    }
                                                </Typography>
                                            );
                                        })
                                    }
                                </Paper>
                                <Button
                                    variant="contained"
                                    endIcon={<ArrowRightAlt />}
                                    onClick={() => setOpenContinueModal(true)}
                                    fullWidth
                                >
                                    {t('cart-material.provider-quotation-confirm')}
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Page>
            <ProviderTrip />
            <ProviderBookingContinueModal
                open={openContinueModal}
                providerId={providerId ?? -1}
                version={parseInt(tripVersion ?? '-1')}
                onClose={() => setOpenContinueModal(false)}
            />
        </ProviderContext.Provider>
    );
}

const Page = styled('div')(() => ({
    minHeight: '100vh'
}));
