//Dependencies
import clsx from 'clsx';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState, Fragment, useRef, useContext } from 'react';
//Core
import { Chip, Stack } from '@mui/material';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import Collapse from '@material-ui/core/Collapse';

//Icons
import { ErrorOutline } from '@mui/icons-material';
import Close from '@material-ui/icons/Close';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ReportProblem from '@material-ui/icons/ReportProblemOutlined';

//Components
import CartFlightNote from './CartFlightNote';
import CartFlightManualBooking from './CartFlightManualBooking';
import CartFlightManualCancellationRules from './CartFlightManualCancellationRules';
import CartDeleteModal from './CartDeleteModal';
import {
    CartConstructionProductsTableItemProviderQuotationStatus
} from "./CartConstructionProductsTableItemProviderQuotationStatus";
import {
    CartPackageItemChangeProviderQuotationStatusButtons
} from './CartPackageItemChangeProviderQuotationStatusButtons';
import { CartConstructionProviderQuotationStatusLayout } from './CartConstructionProviderQuotationStatusLayout';
//Common
import { CartCancellationRules } from './CartCancellationRules';
import { isHTMLEmpty } from './utils/isHTMLEmpty';
import { isProductPackaged } from './utils/isProductPackaged';
import GetCookie from '../Common/Functions/GetCookie';
import CheckBeforeRequest from '../Common/CheckBeforeRequest';
import CheckResponse from '../Flight/FlightSelected/Functions/CheckResponse';
import GetPricesId from '../Cart/Functions/Margin/GetPricesId';
//Actions
import { Flight } from '../Itinerary/network/flight';
import { ProductPrice } from '../Itinerary/objects/productPrice';
import { Currency } from '../../Reducers/objects/currency';
import { Itinerary } from '../Itinerary/objects/itinerary';
import { AppState } from '../../Reducers/Reducers';
import { ProviderContext } from './utils/providerContext';

type Props = {
    flight: Flight
}

export function CartFlightCardDetails({ flight }: Props): JSX.Element {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const quotation_code = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;

    const user = useSelector((state: AppState) => state.user?.user);
    const end_date = useSelector((state: AppState) => state.trip.end_date);
    const trip = useSelector((state: AppState) => state.trip.data_trip);
    const language = useSelector((state: AppState) => state.header.tmp_language);
    const currency_list = useSelector((state: AppState) => state.base.currency_list);
    const itinerary_list = useSelector((state: AppState) => state.itinerary.itinerary_list);
    const see_new_price = useSelector((state: AppState) => state.cart.see_new_price);
    const [showNote, setShowNote] = useState(false);
    const [showManualCancellationRules, setShowManualCancellationRules] = useState(false);
    const [showManualBooking, setShowManualBooking] = useState(false);
    const [margin, setMargin] = useState<{
        id?: number,
        ids?: {
            room_id: number,
            price_id: number
        }[],
        rate_type: 'PER' | 'FIX',
        purchase: number,
        purchase_converted: number,
        purchase_currency?: Currency,
        factor_used: string,
        value: number,
        static_value?: number,
        markup: boolean,
        percent: number,
        static_percent?: number,
        selling: number,
        selling_currency?: Currency,
        custom_rate?: boolean,
        custom_rate_type?: string
    } | null>(null);
    const [errors, setErrors] = useState<{
        found: {
            start: boolean,
            end: boolean
        },
        error: {
            start: Itinerary | null,
            end: Itinerary | null
        } | null
    } | null>(null);
    const [deleteModal, setDeleteModal] = useState(false);
    const [marginValue, setMarginValue] = useState<number | null>(null);
    const [marginPercent, setMarginPercent] = useState<number | null>(null);
    const [openCancel, setOpenCancel] = useState(false);

    const manualBookingRef = useRef<HTMLDivElement>(null);
    const providerContext = useContext(ProviderContext);

    let condition: string[] = [];
    if (flight.cancel_info && flight.cancel_info !== '') {
        condition = flight.cancel_info.toLowerCase().split("--");
    }

    const scrollIntoManuaBookingView = () => {
        manualBookingRef.current?.scrollIntoView({ block: 'center', inline: 'center', behavior: 'smooth' });
    };

    const onManualBooking = () => {
        setShowManualBooking(!showManualBooking);
    };

    const onDeleteManualCancellationRules = () => {
        const { headers } = CheckBeforeRequest();
        axios({
            method: 'PATCH',
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/flight/${flight.id}/`,
            data: {
                no_refund: true,
                cancel_condition_text: null
            }
        }).then(response => {
            let new_flight = [];
            new_flight.push(response.data);
            let arr = CheckResponse(new_flight, end_date);
            dispatch({ type: 'FLIGHT_EDIT_CART_BY_ID', payload: arr[0] });
            setShowManualCancellationRules(!showManualCancellationRules);
        }).catch(error => {
            console.log(error);
            setShowManualCancellationRules(!showManualCancellationRules);
        });
    };

    const onAddNote = () => {
        setShowNote(!showNote);
    };

    const onDeleteNote = () => {
        const { headers } = CheckBeforeRequest();
        axios({
            method: 'PATCH',
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/flight/${flight.id}/`,
            data: {
                custom_information: null
            }
        }).then(response => {
            let new_flight = [];
            new_flight.push(response.data);
            let arr = CheckResponse(new_flight, end_date);
            dispatch({ type: 'FLIGHT_EDIT_CART_BY_ID', payload: arr[0] });
            enqueueSnackbar(t('cart-material.note-deleted'), { variant: 'success' });
        }).catch(error => {
            console.log(error);
            enqueueSnackbar(t('cart-material.note-not-deleted'), { variant: 'error' });
        });
    };

    const onDelete = () => {
        const { headers } = CheckBeforeRequest();
        if (flight.booking_status && flight.booking_status.status_booking === 'CANCELLED') {
            axios({
                method: 'PATCH',
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/flight/${flight.id}/`,
                data: {
                    is_displayed: false
                }
            }).then((response) => {
                let new_flight = [];
                new_flight.push(response.data);
                let arr = CheckResponse(new_flight, end_date);
                dispatch({ type: 'FLIGHT_EDIT_CART_BY_ID', payload: arr[0] });
                enqueueSnackbar(t('cart-material.flight-deleted'), { variant: 'success' });
            }).catch(error => {
                console.log(error);
                enqueueSnackbar(t('cart-material.flight-not-deleted'), { variant: 'error' });
            });
        } else {
            axios({
                method: 'DELETE',
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/flight/${flight.id}/`
            }).then(() => {
                dispatch({ type: 'FLIGHT_REMOVE_FLIGHT_FROM_CART_BY_ID', payload: flight.id });
                enqueueSnackbar(t('cart-material.flight-deleted'), { variant: 'success' });
            }).catch(error => {
                console.log(error);
                enqueueSnackbar(t('cart-material.flight-not-deleted'), { variant: 'error' });
            });
        }
    };

    const onValidateManualCancellationRules = (noRefund: boolean, content: string) => {
        const { headers } = CheckBeforeRequest();
        axios({
            method: 'PATCH',
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/flight/${flight.id}/`,
            data: {
                no_refund: noRefund,
                cancel_condition_text: isHTMLEmpty(content) ? null : content
            }
        }).then(response => {
            let new_flight = [];
            new_flight.push(response.data);
            let arr = CheckResponse(new_flight, end_date);
            dispatch({ type: 'FLIGHT_EDIT_CART_BY_ID', payload: arr[0] });
            setShowManualCancellationRules(false);
        }).catch(error => {
            console.log(error);
            setShowManualCancellationRules(false);
        });
    };

    const getMargin = (
        tmp_margin: NonNullable<typeof margin>,
        prices: ProductPrice[]
    ) => {
        if (user?.client_full?.type === 1 || user?.client_full?.type === 3 || quotation_code === 'verdie') {
            for (let i = 0; i < prices.length; i++) {
                if (prices[i]!.master_price) {
                    tmp_margin.purchase += parseFloat(prices[i]!.purchase_price);
                    tmp_margin.purchase_converted += parseFloat(prices[i]!.purchase_price) * parseFloat(prices[i]!.factor_used);
                    tmp_margin.purchase_currency = currency_list.find(currency => currency.id === prices[i]!.purchase_currency);
                    tmp_margin.selling += parseFloat(prices[i]!.selling_price);
                    tmp_margin.selling_currency = currency_list.find(currency => currency.id === prices[i]!.selling_currency);
                    tmp_margin.percent += parseFloat(prices[i]!.percentage_between);
                    tmp_margin.custom_rate = prices[i]!.custom_rate;
                    tmp_margin.custom_rate_type = prices[i]!.custom_rate_type ?? undefined;
                    tmp_margin.id = prices[i]!.id;
                    break;
                }
            }
            if (tmp_margin.id === null) {
                for (let i = 0; i < prices.length; i++) {
                    if (prices[i]!.owner === user?.client) {
                        tmp_margin.purchase += parseFloat(prices[i]!.purchase_price);
                        tmp_margin.purchase_converted += parseFloat(prices[i]!.purchase_price) * parseFloat(prices[i]!.factor_used);
                        tmp_margin.purchase_currency = currency_list.find(currency => currency.id === prices[i]!.purchase_currency);
                        tmp_margin.selling += parseFloat(prices[i]!.selling_price);
                        tmp_margin.selling_currency = currency_list.find(currency => currency.id === prices[i]!.selling_currency);
                        tmp_margin.percent += parseFloat(prices[i]!.percentage_between);
                        tmp_margin.custom_rate = prices[i]!.custom_rate;
                        tmp_margin.custom_rate_type = prices[i]!.custom_rate_type ?? undefined;
                        tmp_margin.id = prices[i]!.id;
                        break;
                    }
                }
            }
        } else {
            for (let i = 0; i < prices.length; i++) {
                if (prices[i]!.owner === user?.client) {
                    tmp_margin.purchase += parseFloat(prices[i]!.purchase_price);
                    tmp_margin.purchase_converted += parseFloat(prices[i]!.purchase_price) * parseFloat(prices[i]!.factor_used);
                    tmp_margin.purchase_currency = currency_list.find(currency => currency.id === prices[i]!.purchase_currency);
                    tmp_margin.selling += parseFloat(prices[i]!.selling_price);
                    tmp_margin.selling_currency = currency_list.find(currency => currency.id === prices[i]!.selling_currency);
                    tmp_margin.percent += parseFloat(prices[i]!.percentage_between);
                    tmp_margin.custom_rate = prices[i]!.custom_rate;
                    tmp_margin.custom_rate_type = prices[i]!.custom_rate_type ?? undefined;
                    tmp_margin.id = prices[i]!.id;
                    break;
                }
            }
        }
    };

    const toggleDeleteModal = () => {
        setDeleteModal(!deleteModal);
    };

    const confirmCustomInformationRecalc = () => {
        const { headers } = CheckBeforeRequest();
        axios({
            method: 'PATCH',
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/flight/${flight.id}/`,
            data: {
                moved_with_recalc_custom_information: false
            }
        }).then(response => {
            let new_flight = [];
            new_flight.push(response.data);
            let arr = CheckResponse(new_flight, end_date);
            dispatch({ type: 'FLIGHT_EDIT_CART_BY_ID', payload: arr[0] });
        }).catch(error => {
            console.log(error);
        });
    };

    const confirmCancelConditionRecalc = () => {
        const { headers } = CheckBeforeRequest();
        axios({
            method: 'PATCH',
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/flight/${flight.id}/`,
            data: {
                moved_with_recalc_cancel_condition_text: false
            }
        }).then(response => {
            let new_flight = [];
            new_flight.push(response.data);
            let arr = CheckResponse(new_flight, end_date);
            dispatch({ type: 'FLIGHT_EDIT_CART_BY_ID', payload: arr[0] });
        }).catch(error => {
            console.log(error);
        });
    };

    const confirmTravelerModification = () => {
        const { headers } = CheckBeforeRequest();
        axios({
            method: 'PATCH',
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/flight/${flight.id}/`,
            data: {
                traveler_modification: null
            }
        }).then(response => {
            let new_flight = [];
            new_flight.push(response.data);
            let arr = CheckResponse(new_flight, end_date);
            dispatch({ type: 'FLIGHT_EDIT_CART_BY_ID', payload: arr[0] });
        }).catch(error => {
            console.log(error);
        });
    };

    const handleSeeNewPrice = () => {
        dispatch({
            type: 'CART_SEE_NEW_PRICE_MODAL',
            payload: {
                see_new_price: !see_new_price,
                data: see_new_price ? null : {
                    url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/flight/${flight.id}/`,
                    id: flight.id,
                    old_price: flight.prices[GetPricesId(flight.prices, user?.client, user, true)],
                    new_price: flight.provider_wanted_price,
                    product: flight,
                    custom_dispatch: 'FLIGHT_EDIT_CART_BY_ID'
                }
            }
        });
    };

    useEffect(() => {
        if (showManualBooking) {
            scrollIntoManuaBookingView();
        }
    });

    useEffect(() => {
        if (marginValue && marginValue !== margin?.value) {
            let tmp_margin = margin ?
                { ...margin } :
                null;
            if (tmp_margin) {
                tmp_margin.value = marginValue;
                tmp_margin.rate_type = 'FIX';
                if (tmp_margin.markup) {
                    tmp_margin.percent = parseFloat(((tmp_margin.value / tmp_margin.purchase_converted) * 100).toFixed(2));
                } else {
                    tmp_margin.percent = parseFloat(((tmp_margin.value / tmp_margin.selling) * 100).toFixed(2));
                }
                setMarginPercent(tmp_margin.percent);
                setMargin(tmp_margin);
            }
        }
    }, [marginValue]);

    useEffect(() => {
        if (marginPercent && marginPercent !== margin?.percent) {
            let tmp_margin = margin ?
                { ...margin } :
                null;
            if (tmp_margin) {
                tmp_margin.percent = marginPercent;
                tmp_margin.rate_type = 'PER';
                if (tmp_margin.markup) {
                    tmp_margin.value = parseFloat((tmp_margin.purchase_converted * (tmp_margin.percent / 100)).toFixed(2));
                } else {
                    tmp_margin.value = parseFloat((tmp_margin.selling * (tmp_margin.percent / 100)).toFixed(2));
                }
                setMarginValue(tmp_margin.value);
                setMargin(tmp_margin);
            }
        }
    }, [marginPercent]);

    useEffect(() => {
        if (showNote) {
            const element = document.getElementById(`flight-${flight.id}-note`);
            if (element) {
                element.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
            }
        }
    });

    useEffect(() => {
        if (showManualCancellationRules) {
            const element = document.getElementById(`flight-${flight.id}-manual-cancellation-rules`);
            if (element) {
                element.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
            }
        }
    });

    useEffect(() => {
        let tmp_margin: NonNullable<typeof margin> = {
            purchase: 0,
            purchase_converted: 0,
            selling: 0,
            value: 0,
            percent: 0,
            rate_type: 'PER',
            factor_used: '0',
            markup: false
        };
        getMargin(tmp_margin, flight.prices);
        let found = {
            start: false,
            end: true
        };
        let iti_error: {
            start: Itinerary | null,
            end: Itinerary | null
        } = {
            start: null,
            end: null
        };
        itinerary_list.map(step => {
            if (step.destination && flight.start_destination && step.destination.id === flight.start_destination.id && (found.start === false || (found.start && iti_error.start))) {
                found.start = true;
                if (!(window.moment.utc(flight.start_date).isSameOrAfter(window.moment.utc(step.start_date), 'd') && window.moment.utc(flight.start_date).isSameOrBefore(window.moment.utc(step.end_date), 'd'))) {
                    iti_error.start = step;
                } else {
                    iti_error.start = null;
                }
            }
            /*
            if (step.destination && flight.end_destination && step.destination.id === flight.end_destination.id && (found.end === false || (found.end && iti_error.end))) {
                found.end = true;
                if (!(window.moment.utc(flight.end_date).isSameOrAfter(window.moment.utc(step.start_date), 'd') && window.moment.utc(flight.end_date).isSameOrBefore(window.moment.utc(step.end_date), 'd'))) {
                    iti_error.end = step;
                } else {
                    iti_error.end = null;
                }
            }
            */
        });
        tmp_margin.value = parseFloat((tmp_margin.selling - tmp_margin.purchase_converted).toFixed(2));
        tmp_margin.static_value = tmp_margin.value;
        tmp_margin.markup = Math.abs(tmp_margin.percent - (100 - (tmp_margin.purchase_converted / tmp_margin.selling) * 100)) > Math.abs(tmp_margin.percent - ((tmp_margin.value / tmp_margin.purchase_converted) * 100));
        tmp_margin.static_percent = tmp_margin.percent;
        tmp_margin.custom_rate = tmp_margin.custom_rate;
        setMargin(tmp_margin);
        setErrors({
            found: found,
            error: iti_error
        });
    }, [flight]);

    return (
        <Grid
            id={`product-${flight.id}`}
            container
            item
            xs
            direction={"column"}
            spacing={2}
        >
            <Grid item xs={12}>
                <Grid container direction="row" justify={'space-between'} alignItems={'center'}>
                    {
                        user?.client_full?.type !== 2 &&
                        <Grid item xs={12}>
                            <Stack
                                display="inline-flex"
                                direction="row"
                                alignItems="center"
                                spacing={1}
                            >
                                <CartConstructionProviderQuotationStatusLayout
                                    status={
                                        <CartConstructionProductsTableItemProviderQuotationStatus
                                            item={flight}
                                        />
                                    }
                                    buttons={
                                        <CartPackageItemChangeProviderQuotationStatusButtons
                                            item={{
                                                ...flight,
                                                type: 'flights',
                                                isCustom: flight.is_custom
                                            }}
                                        />
                                    }
                                />
                            </Stack>
                        </Grid>
                    }
                    <Grid item>
                        <Grid container direction={"row-reverse"} spacing={4}>
                            {
                                user?.client_full?.type !== 2 &&
                                <Grid item style={{ fontWeight: 'bold' }}>
                                    <Grid container direction={'column'} justify={'center'} alignItems={'flex-end'}>
                                        {
                                            flight.traveler_modification &&
                                            <Grid item>
                                                <Chip
                                                    color="warning"
                                                    label={
                                                        flight.traveler_modification === 'SET_IN_TRIP' ?
                                                            t('cart-material.add-to-cart-traveler') :
                                                            t('cart-material.add-to-option-traveler')
                                                    }
                                                    size="small"
                                                    icon={<ErrorOutline color="inherit" />}
                                                    onDelete={confirmTravelerModification}
                                                />
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container item xs spacing={2}>
                <Grid className={classes.gridContainer} container item>
                    <Grid className={classes.mainRow} container item xs={12} justify={'space-between'} spacing={2} alignItems={'center'}>
                        <Grid item xs={12}>
                            {
                                flight.refund_detail && flight.refund_detail && flight.refund_detail !== "" &&
                                <div>
                                    <Typography>{flight.refund_detail}</Typography>
                                    <Typography>{flight.refund_detail_text}</Typography>
                                    <br />
                                </div>
                            }
                            {
                                condition.length > 0 &&
                                <Fragment>
                                    <Typography><strong>Conditions d'annulation détaillé :</strong></Typography>
                                    {
                                        condition.map((el, el_index) => {
                                            let tmp = el.split("- ");
                                            return (
                                                <Fragment key={el_index}>
                                                    {
                                                        tmp.map((el1, el1_index) => {
                                                            let nameCapitalized = el1.charAt(0).toUpperCase() + el1.slice(1);
                                                            return (<div key={el1_index}>{nameCapitalized}</div>);
                                                        })
                                                    }
                                                </Fragment>
                                            );
                                        })
                                    }
                                </Fragment>
                            }
                        </Grid>
                        <Grid item xs={2}>
                            {
                                !isProductPackaged({
                                    product: flight,
                                    stackInfos: trip?.stack_info ?? null
                                }) &&
                                <Fragment>
                                    {
                                        flight.is_available ? (
                                            <Fragment>
                                                {
                                                    flight.price_change && (flight.price_change.price_variation !== 0 || !flight.price_change.is_available) &&
                                                    <Grid container justify={'flex-end'} alignItems={'center'}>
                                                        {
                                                            flight.price_change.is_available &&
                                                            <Grid item>
                                                                {flight.price_change.price_variation > 0 ? <ArrowUpward className={classes.red} /> : <ArrowDownward className={classes.green} />}
                                                            </Grid>
                                                        }
                                                        <Grid item>
                                                            <Typography className={flight.price_change.price_variation > 0 || !flight.price_change.is_available ? classes.red : classes.green}>
                                                                {
                                                                    !flight.price_change.is_available ?
                                                                        t('cart-material.product-unavailable') :
                                                                        flight.price_change.price_variation.toLocaleString(
                                                                            language,
                                                                            { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                                                                        )
                                                                }
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                }
                                            </Fragment>
                                        ) : (
                                            <Fragment>
                                                <Typography className={classes.unavailable} align={'center'}>{t('cart-material.product-unavailable')}</Typography>
                                            </Fragment>
                                        )
                                    }
                                </Fragment>
                            }
                        </Grid>
                        {
                            (user?.client_full?.type !== 2 || !flight.deletion_restriction) &&
                            (flight.booking_status === null || flight.booking_status.status_booking === 'ERROR' || (quotation_code !== 'marcovasco' && flight.booking_status.status_booking === 'CANCELLED')) &&
                            <Tooltip title={t('cart-material.delete-product')}><Close className={classes.delete} onClick={toggleDeleteModal} /></Tooltip>
                        }
                    </Grid>
                </Grid>
                <Grid container item spacing={2}>
                    <Grid item xs={12}>
                        {
                            flight.booking_status &&
                            flight.booking_status.status_booking === 'CONFIRMED' &&
                            flight.booking_process_state !== 'CANCEL' &&
                            flight.booking_status.last_ticketing_date &&
                            <Typography className={clsx(classes.booking, classes.booked)}>
                                {
                                    flight.booking_status.last_ticketing_date &&
                                    <Fragment>
                                        <br />
                                        {t('cart-material.flight-last-ticketing-date')} : {window.moment.utc(flight.booking_status.last_ticketing_date).format('DD/MM/YYYY')}
                                    </Fragment>
                                }
                            </Typography>
                        }
                    </Grid>
                </Grid>
                {
                    flight.booking_status && flight.booking_status.status_booking === 'UNAVAILABLE' &&
                    <Grid item xs={12}>
                        <Alert variant={'filled'} severity={'error'}>{t('cart-material.booking-unavailable')} : {flight.booking_status.item_reference}</Alert>
                    </Grid>
                }
                {
                    flight.booking_status && (flight.booking_status.status_booking === 'ERROR' || flight.booking_status.status_booking === 'UNAVAILABLE') && flight.booking_errors && flight.booking_errors.length > 0 &&
                    <Grid item xs={12}>
                        <Alert variant={'filled'} severity={'error'}>
                            <AlertTitle>{t('cart-material.error')} :</AlertTitle>
                            {flight.booking_errors.map(error => <Typography key={`booking-error-${error.created_date}`}>{window.moment.utc(error.created_date).format('DD/MM/YYYY')} : {error.detail}</Typography>)}
                        </Alert>
                    </Grid>
                }
                {
                    errors && errors.found.start && errors.error?.start &&
                    <Grid item xs={12}>
                        <Alert variant={'filled'} severity={'error'}>
                            {t('cart-material.itinerary-error-1')}{' '}
                            {errors.error?.start.destination?.data?.name}{' '}
                            {t('global.from')}{' '}
                            {window.moment.utc(errors.error?.start.start_date).format('DD/MM/YYYY')}{' '}
                            {t('global.to')}{' '}
                            {window.moment.utc(errors.error?.start.end_date).format('DD/MM/YYYY')}.{' '}
                            {t('cart-material.itinerary-error-2')}.
                        </Alert>
                    </Grid>
                }
                {/*
                    errors && !errors.found.start && !errors.found.end &&
                    <Grid item xs={ 12 }>
                        <Alert className={ classes.errorAlert } classes={{ icon: classes.alertIcon }} variant={ 'filled' } severity={ 'error' }>{ t('cart-material.no-destination-found') }</Alert>
                    </Grid>
                */}
                {/*
                    errors && !errors.found.start && errors.found.end &&
                    <Grid item xs={ 12 }>
                        <Alert className={ classes.errorAlert } classes={{ icon: classes.alertIcon }} variant={ 'filled' } severity={ 'error' }>{ t('cart-material.no-start-destination-found') }</Alert>
                    </Grid>
                */}
                {/*
                    errors && errors.found.start && !errors.found.end &&
                    <Grid item xs={ 12 }>
                        <Alert className={ classes.errorAlert } classes={{ icon: classes.alertIcon }} variant={ 'filled' } severity={ 'error' }>{ t('cart-material.no-end-destination-found') }</Alert>
                    </Grid>
                */}
                {
                    showManualBooking &&
                    <Grid item xs={12} style={{ marginTop: 15 }} ref={manualBookingRef}>
                        <CartFlightManualBooking flight={flight} onManualBooking={onManualBooking} />
                    </Grid>
                }
                {
                    !providerContext.module &&
                    <>
                        {
                            showNote ? (
                                <Grid id={`flight-${flight.id}-note`} item xs={12}>
                                    <CartFlightNote flight={flight} onAddNote={onAddNote} />
                                </Grid>
                            ) : flight.custom_information && (
                                <Grid item xs={12}>
                                    <Alert className={classes.cancelConditionAlert} variant={'filled'} severity={'info'} action={
                                        <Grid container direction={'column'} spacing={2} alignItems={'flex-end'}>
                                            <Grid item>
                                                <Grid container spacing={2} alignItems={'center'}>
                                                    <Grid item>
                                                        <Button className={classes.editCustomInformation} color={'inherit'} size={'small'} onClick={onAddNote}>
                                                            {t('global.edit')}
                                                        </Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Close className={classes.deleteCustomInformation} onClick={onDeleteNote} />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item style={{ width: '100%' }}>
                                                {
                                                    flight.moved_with_recalc_custom_information &&
                                                    <Tooltip title={t('cart-material.recalc-product-note')}>
                                                        <div
                                                            className={clsx(classes.traverlerModification, classes.delete)}
                                                            onClick={confirmCustomInformationRecalc}
                                                            style={{ fontSize: 13, color: 'white', background: '#ff9800', border: '2px solid #ff9800' }}
                                                        >
                                                            <Grid container alignItems={'center'} justify={'space-between'}>
                                                                <Grid item><ReportProblem style={{ verticalAlign: 'sub', fontSize: 18 }} /></Grid>
                                                                <Grid item>{t('cart-material.recalc')}</Grid>
                                                                <Grid item><Close style={{ verticalAlign: 'middle', fontSize: 18 }} /></Grid>
                                                            </Grid>
                                                        </div>
                                                    </Tooltip>
                                                }
                                            </Grid>
                                        </Grid>
                                    }>
                                        <AlertTitle>{t('cart-material.note')} :</AlertTitle>
                                        {flight.custom_information}
                                    </Alert>
                                </Grid>
                            )
                        }
                    </>
                }
                {
                    user?.client_full?.type !== 2 && flight.provider_wanted_price &&
                    <Grid item xs={12}>
                        <Alert variant={'filled'} severity={'error'} action={
                            <Button color={"inherit"} size={'small'} onClick={handleSeeNewPrice}>{t('global.see')}</Button>
                        }>
                            <AlertTitle>{t('cart-material.provider-wanted-price')}</AlertTitle>
                        </Alert>
                    </Grid>
                }
                <CartCancellationRules
                    type="flights"
                    id={flight.id}
                    open={showManualCancellationRules}
                    text={
                        flight.cancel_condition_text ||
                        (
                            ['verdie'].includes(quotation_code) ?
                                flight.cancel_info :
                                ''
                        )
                    }
                    moved_with_recalc_cancel_condition_text={flight.moved_with_recalc_cancel_condition_text}
                    noRefund={flight.no_refund}
                    isCustom={flight.is_custom}
                    onConfirmCancelConditionRecalc={confirmCancelConditionRecalc}
                    onDeleteCancellationRules={onDeleteManualCancellationRules}
                    onToggle={() => setShowManualCancellationRules((state) => !state)}
                    onValidate={onValidateManualCancellationRules}
                />
            </Grid>
            <CartDeleteModal deleteModal={deleteModal} setDeleteModal={setDeleteModal} callback={onDelete} />
        </Grid>
    );
}

const useStyles = makeStyles(() => ({
    disableBorder: {
        border: 'none !important'
    },
    smallText: {
        fontSize: 10
    },
    gridContainer: {
        marginBottom: 8,
        borderRadius: 8
    },
    mainRow: {
        margin: 0,
        position: 'relative'
    },
    composition: {
        float: 'left',
        marginRight: 4
    },
    priceDetail: {
        textDecoration: 'underline',
        cursor: 'pointer'
    },
    red: {
        color: 'red'
    },
    green: {
        color: 'green'
    },
    firstRow: {
        backgroundColor: '#daf6ff',
        margin: 0
    },
    secondRow: {
        margin: 0
    },
    relative: {
        position: 'relative',
        textAlign: 'center'
    },
    noBaggage: {
        color: 'gray',
        fontSize: 48
    },
    baggage: {
        color: '#E6592F',
        fontSize: 48
    },
    baggageNumber: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        color: 'white'
    },
    moreDetailContainer: {
        paddingBottom: 8
    },
    moreDetail: {
        textDecoration: 'underline',
        cursor: 'pointer'
    },
    selector: {
        margin: '4px 0 8px 0'
    },
    variant: {
        margin: '16px 0 8px 0'
    },
    updateQuotationStatus: {
        textDecoration: 'underline',
        marginBottom: 4,
        cursor: 'pointer'
    },
    addNote: {
        textDecoration: 'underline',
        marginBottom: 4,
        cursor: 'pointer'
    },
    manualCancellationRules: {
        textDecoration: 'underline',
        marginBottom: 4,
        cursor: 'pointer'
    },
    manualBookingHand: {
        float: 'left',
        marginRight: 8
    },
    bookingDetail: {
        textDecoration: 'underline',
        fontSize: 12,
        textAlign: 'right',
        marginBottom: 4,
        cursor: 'pointer'
    },
    needToBook: {
        color: '#4caf50',
        textAlign: 'right'
    },
    traverlerModification: {
        borderRadius: 16,
        padding: '1px 6px'
    },
    booking: {
        borderRadius: 16,
        fontWeight: 700,
        padding: '4px 12px',
        margin: '4px 0',
        textTransform: 'uppercase'
    },
    booked: {
        border: '2px solid #4caf50',
        color: '#4caf50'
    },
    pending: {
        border: '2px solid #ff9800',
        color: '#ff9800',
        cursor: 'pointer'
    },
    cached: {
        float: 'left',
        marginRight: 8,
        cursor: 'pointer'
    },
    cancelled: {
        border: '2px solid black',
        color: 'black'
    },
    error: {
        border: '2px solid #f44336',
        color: '#f44336'
    },
    cancellable: {
        color: '#4caf50',
        border: '1px solid',
        cursor: 'pointer',
        borderRadius: 8,
        fontSize: 12,
        padding: 2
    },
    notCancellable: {
        color: '#f44336',
        border: '1px solid',
        cursor: 'pointer',
        borderRadius: 8,
        fontSize: 12,
        padding: 2
    },
    infoCustomInformation: {
        border: '1px solid #FF9800',
        color: 'white',
        background: '#FF9800',
        marginRight: 5
    },
    editCustomInformation: {
        border: '1px solid'
    },
    deleteCustomInformation: {
        cursor: 'pointer',
        marginLeft: 8
    },
    unavailable: {
        color: 'red'
    },
    priceUnavailable: {
        fontSize: '20px !important'
    },
    delete: {
        position: 'absolute',
        right: 4,
        top: 4,
        cursor: 'pointer'
    },
    validateButton: {
        backgroundColor: '#E6592F',
        color: 'white'
    },
    price: {
        textAlign: 'right',
        fontSize: 32,
        fontWeight: 'bold'
    },
    catalogedPrice: {
        textAlign: 'right',
        cursor: 'pointer',
        textDecoration: 'underline',
        fontSize: 12
    },
    hideMargin: {
        textDecoration: 'underline',
        cursor: 'pointer'
    },
    hideProduct: {
        background: "#eeee",
        border: "solid 2px #E6592F",
        padding: 15,
        borderRadius: 10,
        marginBottom: 20
    },
    inQuotation: {
        border: "solid 2px #43D67D",
        padding: 15,
        borderRadius: 10,
        marginBottom: 20
    },
    isOption: {
        border: "solid 2px #eeee",
        padding: 15,
        borderRadius: 10,
        marginBottom: 20
    },
    progress: {
        color: green[500],
        position: 'absolute',
        top: "16%",
        left: "43%",
        marginLeft: -12
    },
    checkboxContainer: {
        position: 'relative',
        //height: '100%',
        //width: 50,
        marginTop: '-5px'
    },
    cancelButton: {
        border: '1px solid',
        backgroundColor: 'white',
        color: '#E6592F'
    },
    cancelConditionAlert: {
        "whiteSpace": 'pre-wrap',
        "&& .MuiAlert-action": {
            minWidth: 140
        }
    },
    cancelConditionAlertVerdie: {
        whiteSpace: 'pre-wrap'
    }
}));
