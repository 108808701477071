import React, {Fragment} from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import CheckBeforeRequest from "./CheckBeforeRequest";
import GetCookie from "./Functions/GetCookie";
import Notify from "./Notify";
import swal from "sweetalert";

//---- CSS ----//
import "../../Style/PassengerParameters.css";
import { SetTravelers, SetTravelerGroups } from "../../Actions/Trip";
import { TogglePassengers } from "../../Actions/Header";
import { SetTripToDuplicateAndOpenDuplicateModalAndSetStep } from "../../Actions/Menu";
import UpdateCart from "../Itinerary/Functions/UpdateCart.js";
import AddToCart from "../../Actions/Accommodation";
import {SingleDatePicker} from "react-dates";

let request_running = 0;

@connect((store) => {
    return {
        travelers: store.trip.travelers,
        traveler_groups: store.trip.traveler_groups,
        accommodation_cart: store.accommodation.cart,
        flight_cart: store.flight.cart,
        car_cart: store.cars_search.cart,
        manual_item_list: store.cart.manual_item_list,
        user: store.user.user,
        end_date: store.trip.end_date,
        trip: store.trip.data_trip,
        allData: store.trip.all_data,
    }
})
@withTranslation()
export default class PassengerParametersUpgrade extends React.Component {
    constructor(props, context) {
        super(props, context);
        const { travelers, traveler_groups } = this.props;
        let groups = [];
        for (let i = 0; i < traveler_groups.length; i++) {
            if (traveler_groups[i].default) {
                let tmp_group = Object.assign({}, traveler_groups[i]);
                tmp_group.open = false;
                groups.push(tmp_group);
            }
        }
        this.state = {
            groups: groups,
            running: false,
            group_open: false,
            travelers: [...travelers],
            focus_id: null
        };
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.node_array = [];
        this.node_room = null;
    }
    handleClickOutside(event) {
        let new_groups = [];
        for (let i = 0; i < this.state.groups.length; i++) {
            new_groups.push(this.state.groups[i]);
        }
        for (let i = 0; i < this.node_array.length; i++) {
            if (this.node_array[i].node && this.node_array[i].node !== null && !this.node_array[i].node.contains(event.target)) {
                if (new_groups[this.node_array[i].index] !== undefined)
                    new_groups[this.node_array[i].index].open = false;
            }
        }
        if (this.node_room && this.node_room !== null && !this.node_room.contains(event.target)) {
            this.setState({group_open: false});
        }
        this.setState({groups: new_groups});
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({
                input_focus: 0,
            });
        }
    }
    componentWillMount() {
        const { groups, travelers } = this.state;
        if (groups.length === 0 && travelers.length === 0) {
            let new_groups = [];
            new_groups.push({
                open: false,
                travelers: [],
                travelers_list: [],
            })
        this.setState({groups: new_groups}, () => {
            this.addTraveler("ADULT");
        });
        }
        
    }
    setWrapperRef(node) {
        this.wrapperRef = node;
    }
    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }
    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }
    addTraveler(type) {
        const { pass_check, headers } = CheckBeforeRequest();
        const { end_date } = this.props;
        const { t } = this.props;
        const { travelers } = this.state;
        const component = this;
        let adults = 1;
        let children = 1;
        for (let i = 0; i < travelers.length; i++) {
            if (moment.utc(end_date).diff(moment.utc(travelers[i].birth_date), "y") >= 18) {
                adults++;
            } else {
                children++;
            }
        }
        if (pass_check) {
            let request = {
                first_name: t("global.first_name"),
                last_name: t("global.last_name"),
                birth_date: (type === "ADULT" ? moment.utc().subtract(30, "y").format("YYYY-MM-DD") : moment.utc().subtract(5, "y").format("YYYY-MM-DD"))
            };
            $.ajax({
                method: "POST",
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/travelers/`,
                data: JSON.stringify(request),
                success: function (data) {
                    console.log(data);
                    let new_travelers = [...travelers];
                    data.room = null;
                    new_travelers.push(data);
                    component.setState({travelers: new_travelers});
                },
                error: function (error) {
                    console.log(error);
                }
            });
        }
    }
    removeTraveler(id) {
        const { travelers, groups } = this.state;
        let new_travelers = [];
        for (let i = 0; i < travelers.length; i++) {
            if (travelers[i].id !== id) {
                new_travelers.push(travelers[i]);
            } else {
                if (travelers[i].room !== null) {
                    for (let j = 0; j < groups[travelers[i].room].travelers.length; j++) {
                        if (groups[travelers[i].room].travelers[j] === id) {
                            groups[travelers[i].room].travelers.splice(j, 1);
                            break;
                        }
                    }
                    for (let j = 0; j < groups[travelers[i].room].travelers_list.length; j++) {
                        if (groups[travelers[i].room].travelers_list[j].id === id) {
                            groups[travelers[i].room].travelers_list.splice(j, 1);
                            break;
                        }
                    }
                    if (groups[travelers[i].room].travelers_list.length === 0) {
                        groups.splice(travelers[i].room, 1);
                    }
                }
            }
        }
        this.setState({travelers: new_travelers, groups: groups});
    }
    toggleRoom(index_room) {
        const { groups } = this.state;
        let new_groups = [...groups];
        new_groups[index_room].open = !new_groups[index_room].open;
        this.setState({groups: new_groups});
    }
    toggleTravelerInRoom(index_room, traveler) {
        const { groups, travelers } = this.state;
        const { t, user } = this.props;
        let new_groups = [...groups];
        let new_travelers = [...travelers];
        if (new_groups[index_room].length > 4 && user.client_full.type === 2) {
            Notify(t("trip.agency_max_room_capacity_error"), {status:'danger',pos:'top-center'});
        } else if (new_groups[index_room].length > 20) {
            Notify(t("trip.to_max_room_capacity_error"), {status:'danger',pos:'top-center'});
        } else {
            if (traveler.room === null) {
                new_groups[index_room].travelers.push(traveler.id);
                new_groups[index_room].travelers_list.push(traveler);
                for (let i = 0; i < new_travelers.length; i++) {
                    if (new_travelers[i].id === traveler.id) {
                        new_travelers[i].room = index_room;
                    }
                }
            } else {
                let new_groups_travelers = [];
                let new_groups_travelers_list = [];
                for (let i = 0; i < new_groups[index_room].travelers.length; i++) {
                    if (new_groups[index_room].travelers[i] !== traveler.id) {
                        new_groups_travelers.push(new_groups[index_room].travelers[i]);
                        new_groups_travelers_list.push(new_groups[index_room].travelers_list[i]);
                    }
                }
                new_groups[index_room].travelers = new_groups_travelers;
                new_groups[index_room].travelers_list = new_groups_travelers_list;
                for (let i = 0; i < new_travelers.length; i++) {
                    if (new_travelers[i].id === traveler.id) {
                        new_travelers[i].room = null;
                    }
                }
            }
            this.setState({groups: new_groups, travelers: new_travelers});
        }
    }
    changeGroupLength(length) {
        const { groups, travelers } = this.state;
        const { t, user } = this.props;
        if (length > 4 && user.client_full.type === 2) {
            Notify(t("trip.agency_max_room_error"), {status:'danger',pos:'top-center'});
        } else {
            let new_groups = [];
            for (let i = 0; i < groups.length; i++) {
                if (i < length) {
                    new_groups.push(groups[i]);
                }
            }
            let new_travelers = [...travelers];
            for (let i = 0; i < new_travelers.length; i++) {
                if (new_travelers.room !== null && new_travelers.room > length) {
                    new_travelers.room = null;
                }
            }
            if (new_groups.length < length) {
                let nb_to_add = length - new_groups.length;
                for (let j = 0; j < nb_to_add; j++) {
                    new_groups.push({
                        open: false,
                        travelers: [],
                        travelers_list: [],
                    })
                }
            }
            this.setState({travelers: new_travelers, groups: new_groups, group_open: false});
        }
    }
    updateChildrenAge(id, age) {
        const { pass_check, headers } = CheckBeforeRequest();
        if (pass_check) {
            let request = {
                birth_date: moment.utc().subtract(age, "y").format("YYYY-MM-DD")
            };
            const component = this;
            $.ajax({
                method: "PATCH",
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/travelers/${id}/`,
                data: JSON.stringify(request),
                success: function (data) {
                    console.log(data);
                    let new_travelers = [];
                    for (let i = 0; i < component.state.travelers.length; i++) {
                        if (component.state.travelers[i].id !== id) {
                            new_travelers.push(component.state.travelers[i]);
                        } else {
                            new_travelers.push(data);
                        }
                    }
                    component.setState({travelers: new_travelers});
                },
                error: function (error) {
                    console.log(error);
                }
            });
        }
    }
    dispatchChange() {
        const { dispatch, t, accommodation_cart, car_cart, flight_cart, manual_item_list, trip, allData } = this.props;
        const { pass_check, headers } = CheckBeforeRequest();
        request_running -= 1;
        let component = this;
        let nb_cart_item = (accommodation_cart.length) + (car_cart.length) + (flight_cart.length) + (manual_item_list.length);
        if (request_running === 0 && pass_check) {
            $.ajax({
                method: "GET",
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/travelers-group/?token=${GetCookie("trip_token")}&ordering=-default&limit=10000`,
                headers: headers,
                success: function (data) {
                    let trav_arr = [];
                    dispatch(SetTravelerGroups(data.results));
                    dispatch(TogglePassengers(false));
                    dispatch(SetTripToDuplicateAndOpenDuplicateModalAndSetStep(null, false, 1));
                    for (let i = 0; i < data.results.length; i++) {
                        if (data.results[i].default === true) {
                            trav_arr.push(data.results[i].id);
                        }
                    }
                    if (nb_cart_item > 0) {
                        UpdateCart(
                            "COMP",
                            dispatch,
                            null,
                            null,
                            trav_arr,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            !allData?.circuit && trip?.identifier ?
                                JSON.parse(trip.identifier).adalte_rooms :
                                null
                        );
                    }
                    Notify(t("trip.traveler_edit_success"), { status: "success", pos: "top-center"})
                },
                error: function (error) {
                    console.log(error);
                    component.setState({running: false});
                }
            });
        }
    }
    groupChecked() {
        const { with_data, end_date, traveler_groups, dispatch, t } = this.props;
        const { groups, travelers } = this.state;
        const { pass_check, headers } = CheckBeforeRequest();
        const component = this;
        this.setState({running: true}, function () {
            request_running = 0;
            let check = true;
            for (let i = 0; i < groups.length; i++) {
                let has_adult = false;
                for (let j = 0; j < groups[i].travelers_list.length; j++) {
                    if (moment().diff(moment(groups[i].travelers_list[j].birth_date), "y") >= 18) {
                        has_adult = true;
                    }
                }
                if (!has_adult) {
                    Notify(t("trip.empty_room_or_no_adult_error"), {status:'danger',pos:'top-center'});
                    this.setState({running: false});
                    check = false;
                }
            }
            if (check) {
                if (with_data) {
                    travelers.map((traveler) => {
                        $.ajax({
                            method: "PATCH",
                            headers: headers,
                            url: `${API_HREF}client/${window.id_owner}/travelers/${traveler.id}/`,
                            data: JSON.stringify({
                                title: traveler.title,
                                last_name: traveler.last_name,
                                first_name: traveler.first_name,
                                birth_date: moment.utc(traveler.birth_date).format("YYYY-MM-DD")
                            }),
                            success: function (data) {
                                console.log(data);
                            },
                            error: function (error) {
                                console.log(error);
                            }
                        });
                    });
                }
                for (let i = 0; i < traveler_groups.length; i++) {
                    if (traveler_groups[i].default) {
                        if (pass_check) {
                            let request = {
                                default: false
                            };
                            request_running += 1;
                            $.ajax({
                                method: "PATCH",
                                headers: headers,
                                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/travelers-group/${traveler_groups[i].id}/`,
                                data: JSON.stringify(request),
                                success: function (data) {
                                    console.log(data);
                                    component.dispatchChange();
                                },
                                error: function (error) {
                                    console.log(error);
                                    component.setState({running: false});
                                }
                            });
                        }
                    }
                }
                if (pass_check) {
                    let request = {
                        travelers: []
                    };
                    for (let i = 0; i < travelers.length; i++) {
                        request.travelers.push(travelers[i].id);
                    }
                    $.ajax({
                        method: "PATCH",
                        headers: headers,
                        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/?token=${GetCookie("trip_token")}`,
                        data: JSON.stringify(request),
                        success: function (data) {
                            $.each(data.travelers, function (index_travelers, value_travelers) {
                                let age = moment(end_date).diff(moment(value_travelers.birth_date), "y");
                                if (age >= 12) {
                                    value_travelers.flight_type = "ADT";
                                } else if (age >= 2) {
                                    value_travelers.flight_type = "CNN";
                                } else {
                                    value_travelers.flight_type = "INF";
                                }
                                value_travelers.passenger_group = null;
                            });
                            dispatch(SetTravelers(data.travelers));
                        },
                        error: function (error) {
                            console.log(error);
                            component.setState({running: false});
                        }
                    });
                }
                for (let i = 0; i < groups.length; i++) {
                    if (pass_check) {
                        let request = {
                            default: true,
                            travelers: groups[i].travelers
                        };
                        request_running += 1;
                        $.ajax({
                            method: "POST",
                            headers: headers,
                            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/travelers-group/`,
                            data: JSON.stringify(request),
                            success: function (data) {
                                component.dispatchChange();
                                console.log(data);
                            },
                            error: function (error) {
                                console.log(error);
                                component.setState({running: false});
                            }
                        });
                    }
                }
            }
        });
    }
    createGroups() {
        const { setSwalOpen, t } = this.props;
        const { running, travelers } = this.state;
        const component = this;
        let notified = false;
        if (running === false) {
            for (let i = 0; i < travelers.length; i++) {
                if (travelers[i].room === null && notified === false) {
                    Notify(t("trip.traveler_not_assigned_to_room"), {status: "warning", pos: "top-center"});
                    notified = true;
                    if (setSwalOpen !== undefined && setSwalOpen !== null) {
                        setSwalOpen(true);
                    }
                    swal({
                        title: t("trip.traveler_not_assigned_to_room"),
                        buttons: {
                            cancel: {
                                text: "Annuler",
                                value: 0,
                                visible: true,
                                className: "btn-danger",
                                closeModal: true,
                            },
                            add: {
                                text: "Continuer",
                                value: 1,
                                visible: true,
                                closeModal: true
                            }
                        }
                    }).then((value) => {
                        if (setSwalOpen !== undefined && setSwalOpen !== null) {
                            setSwalOpen(false);
                        }
                        if (value === 1) {
                            component.groupChecked();
                        }
                    });
                }
            }
            if (!notified) {
                component.groupChecked();
            }
        }
    }
    render() {
        const { with_data, end_date, t } = this.props;
        const { focus_id, running, travelers, groups } = this.state;
        let adults = [];
        let children = [];
        this.node_array = [];
        for (let i = 0; i < travelers.length; i++) {
            if (moment.utc(end_date).diff(moment.utc(travelers[i].birth_date), "y") >= 18) {
                adults.push(travelers[i]);
                adults[adults.length - 1].room = null;
            } else {
                children.push(travelers[i]);
                children[children.length - 1].room = null;
            }
        }
        for (let i = 0; i < groups.length; i++) {
            for (let j = 0; j < groups[i].travelers.length; j++) {
                for (let k = 0; k < adults.length; k++) {
                    if (groups[i].travelers[j] === adults[k].id) {
                        adults[k].room = i;
                    }
                }
                for (let k = 0; k < children.length; k++) {
                    if (groups[i].travelers[j] === children[k].id) {
                        children[k].room = i;
                    }
                }

            }
        }
        return (
            <div>
                <div className={"row col-md-12 mt30 ft-pl50 mb20"}>
                    <div className={ "col-md-6" } style={{float:"left"}}>
                        <div>
                            <label className={"home-modal-selector-label "}>
                                <i className={ "icon-traveler-with-a-suitcase ft-pr5" }/>{t("global.traveler_number")}
                            </label>
                            <div className={ "trip-new-room-composition" } onClick={() => { this.setState({input_focus: 1}); } }>
                                <span>{ `${travelers.length} ${(travelers.length > 1 ? t("home.modal.travelers") : t("home.modal.traveler"))}` }</span>
                                <i className={ "trip_new_room_composition_close" }/>
                            </div>
                            {
                                this.state.input_focus === 1 && (
                                    <div ref={ this.setWrapperRef } id={"selector-container"} className={ "ft-selector-container active" }>
                                        <div className={ "ft-selector-option-border" }>
                                            <span><i className={"icon-businessman-with-handbag-standing-silhouette ft-pr5"} /> {t("global.adults")}</span>
                                            <div className={"ft-container-btn-right"}>
                                                <button type={ "button" } className={ adults.length < 1 ? "ft-add-remove-btn" : "ft-add-remove-btn active" } onClick={() => { this.removeTraveler(adults[adults.length - 1].id); }}><span>-</span></button>
                                                <span className={ "ft-nb-passenger-selected" }>{ adults.length }</span>
                                                <button type={ "button" } className={ "ft-add-btn active" } onClick={() => { this.addTraveler("ADULT"); }}><span>+</span></button>
                                            </div>
                                        </div>
                                        <div className={ "ft-selector-option-border" }>
                                            <span> <i className={"icon-kids-couple ft-pr5"} /> { t("global.children")} (1-17)</span>
                                            <div className={"ft-container-btn-right"}>
                                                <button type={ "button" } className={ children.length < 1 ? "ft-add-remove-btn" : "ft-add-remove-btn active" } onClick={() => { this.removeTraveler(children[children.length - 1].id); }}><span>-</span></button>
                                                <span className={ "ft-nb-passenger-selected" }>{ children.length }</span>
                                                <button type={ "button" } className={ "ft-add-btn active" } onClick={() => { this.addTraveler("CHILDREN"); }}><span>+</span></button>
                                            </div>
                                        </div>
                                        {
                                            children.map((child, index) => {
                                                let age = moment.utc(end_date).diff(moment.utc(child.birth_date), "y");
                                                return (
                                                    <div key={ index } className={ "ft-selector-option-border" }>
                                                        <span className={ "ml15" }>{ t("global.age") + " " + child.last_name }</span>
                                                        <div className={ "ft-container-btn-right ft-age-selector" }>
                                                            <select id={ "children_" + index } className={ "form-control trip_common_children_age" } defaultValue={ age }
                                                                    onChange={
                                                                        (event) => { this.updateChildrenAge(child.id, event.target.value); }
                                                                    }>
                                                                <option value={ 1 }>1</option>
                                                                <option value={ 2 }>2</option>
                                                                <option value={ 3 }>3</option>
                                                                <option value={ 4 }>4</option>
                                                                <option value={ 5 }>5</option>
                                                                <option value={ 6 }>6</option>
                                                                <option value={ 7 }>7</option>
                                                                <option value={ 8 }>8</option>
                                                                <option value={ 9 }>9</option>
                                                                <option value={ 10 }>10</option>
                                                                <option value={ 11 }>11</option>
                                                                <option value={ 12 }>12</option>
                                                                <option value={ 13 }>13</option>
                                                                <option value={ 14 }>14</option>
                                                                <option value={ 15 }>15</option>
                                                                <option value={ 16 }>16</option>
                                                                <option value={ 17 }>17</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <div className={ "col-md-6" }>
                        <label className={"home-modal-selector-label "}> <i className={"icon-door ft-pr5"} /> {t("home.modal.room_number")}</label>
                        <div ref={(node) => { this.node_room = node; }}>
                            <div className={ "trip-new-room-composition" } onClick={() => { this.setState({group_open: !this.state.group_open}); }}><span>{ groups.length } { (groups.length > 0 ? t("global.rooms") : t("global.room")) }</span></div>
                            <div className={ this.state.group_open ? "passenger-group-flight-info-passenger-choice-container active" : "passenger-group-flight-info-passenger-choice-container" }>
                                {
                                    adults.map((adult, index_adult) => {
                                        return (
                                            <div key={ index_adult } className={ "passenger-group-flight-info-passenger-choice" } onClick={() => { this.changeGroupLength(index_adult + 1); }}><span>{ `${(index_adult + 1)} ${(index_adult > 0 ? t("global.rooms") : t("global.room"))}` }</span></div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        {
                            /*
                                                    <select className={ "ft-basic-selector full-width" } value={ groups.length } onChange={(event) => { this.changeGroupLength(event.target.value); }} data-name={ "room-input" } id={ "room" }>
                            {
                                adults.map((adult, index_adult) => {
                                    return (
                                        <option key={ index_adult } value={ (index_adult + 1) }>{ `${(index_adult + 1)} ${(index_adult > 0 ? t("global.rooms") : t("global.room"))}` }</option>
                                    )
                                })
                            }
                        </select>
                             */
                        }

                    </div>
                    <div className={"room-compo row"}>
                        <h4 className={"col-md-12 ft-dark-green"}>{t("home.modal.select_room")}</h4>
                        {
                            groups.map((room, index_room) => {
                                return (
                                    <div ref={(node) => { this.node_array.push({node: node, index: index_room}); }} key={ index_room } className={ "trip-new-room-composition-container trip-new-room-composition-traveler-list-container col-md-4" }>
                                        <label className={"ft-light-green"} >{t('home.modal.room')} { index_room + 1 }</label>
                                        <div className={ false ? "ft-input-err trip-new-room-composition" : "trip-new-room-composition" } onClick={() => { this.toggleRoom(index_room); }}>
                                            <i className={ "icon-traveler-with-a-suitcase ft-pr5" }/>
                                            <span> { room.travelers.length > 1 ? room.travelers.length + " " + t('home.modal.travelers') : room.travelers.length + " " + t('home.modal.traveler')}</span>
                                            <i className={ room.open ? "trip-new-room-composition-open" : "trip-new-room-composition-close" }/>
                                        </div>
                                        <div className={ room.open ? "passenger-group-flight-info-passenger-choice-container active" : "passenger-group-flight-info-passenger-choice-container" }>
                                            {
                                                adults.map((adult, index_adult) => {
                                                    if (adult.room === index_room || adult.room === null)
                                                        return (
                                                            <div key={ index_adult } className={ "passenger-group-flight-info-passenger-choice" } onClick={() => { this.toggleTravelerInRoom(index_room, adult); }}>
                                                                {
                                                                    (adult.room === index_room) && (
                                                                        <i className={ "icon-check ft-pr5"}/>
                                                                    )
                                                                }
                                                                <span>{ adult.last_name }</span>
                                                            </div>
                                                        )
                                                })
                                            }
                                            {
                                                children.map((child, index_child) => {
                                                    let age = moment.utc(end_date).diff(moment.utc(child.birth_date), "y");
                                                    if (child.room === index_room || child.room === null)
                                                        return (
                                                            <div key={ index_child } className={ "passenger-group-flight-info-passenger-choice" } onClick={() => { this.toggleTravelerInRoom(index_room, child); }}>
                                                                {
                                                                    (child.room === index_room) && (
                                                                        <i className={ "icon-check ft-pr5"}/>
                                                                    )
                                                                }
                                                                <span>{ age > 1 ? child.last_name + " (" + age + " " + t("global.years") + ")" : child.last_name + " (" + age + " " + t("global.year") + ")" }</span>
                                                            </div>
                                                        )
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                {
                    with_data &&
                    <div className={ 'traveler-main-container' }>
                        {
                            adults.map((adult, index_adult) => (
                                <div key={ index_adult } className={ 'traveler-container' }>
                                    <select className={ 'traveler-title-selector' } defaultValue={ adult.title } onChange={(e) => { adult.title = e.target.value }}>
                                        <option value={ "MR" }>{ t('title_value.mr') }</option>
                                        <option value={ "MRS" }>{ t('title_value.mrs') }</option>
                                        <option value={ "MISS" }>{ t('title_value.miss') }</option>
                                        {/* <option value={ "MX" }>{ t('title_value.mx') }</option> */}
                                    </select>
                                    <input className={ 'traveler-input' } type={ "text" } defaultValue={ adult.first_name } placeholder={ t("menu.profile.surname") } onChange={(e) => { adult.first_name = e.target.value }}/>
                                    <input className={ 'traveler-input' } type={ "text" } defaultValue={ adult.last_name } placeholder={ t("global.name") } onChange={(e) => { adult.last_name = e.target.value }}/>
                                    <SingleDatePicker
                                        id={ `birthdate-${adult.id}` }
                                        placeholder={ t("menu.trip_list.birthdate") }
                                        date={ moment.utc(adult.birth_date) }
                                        onDateChange={(date) => { adult.birth_date = date;this.setState({focus_id: null}); }}
                                        readOnly
                                        focused={ (focus_id === adult.id) }
                                        onFocusChange={({focused}) => { this.setState({focus_id: focused ? adult.id : null}); }}
                                        isOutsideRange={() => false}
                                        numberOfMonths={ 1 }
                                        hideKeyboardShortcutsPanel
                                    />
                                </div>
                            ))
                        }
                        {
                            children.map((child, index_child) => (
                                <div key={ index_child } className={ 'traveler-container' }>
                                    <select className={ 'traveler-title-selector' } defaultValue={ child.title } onChange={(e) => { child.title = e.target.value }}>
                                        <option value={ "MR" }>{ t('title_value.mr') }</option>
                                        <option value={ "MRS" }>{ t('title_value.mrs') }</option>
                                        <option value={ "MISS" }>{ t('title_value.miss') }</option>
                                        {/* <option value={ "MX" }>{ t('title_value.mx') }</option> */}
                                    </select>
                                    <input className={ 'traveler-input' } type={ "text" } defaultValue={ child.first_name } placeholder={ t("menu.profile.surname") } onChange={(e) => { child.first_name = e.target.value }}/>
                                    <input className={ 'traveler-input' } type={ "text" } defaultValue={ child.last_name } placeholder={ t("global.name") } onChange={(e) => { child.last_name = e.target.value }}/>
                                    <SingleDatePicker
                                        id={ `birthdate-${child.first_name}` }
                                        placeholder={ t("menu.trip_list.birthdate") }
                                        date={ moment.utc(child.birth_date) }
                                        onDateChange={(date) => { child.birth_date = date;this.setState({focus_id: null}); }}
                                        readOnly
                                        focused={ (focus_id === child.id) }
                                        onFocusChange={({focused}) => { this.setState({focus_id: focused ? child.id : null}); }}
                                        isOutsideRange={() => false}
                                        numberOfMonths={ 1 }
                                        hideKeyboardShortcutsPanel
                                    />
                                </div>
                            ))
                        }
                        <br/>
                    </div>
                }
                <div className={"block-center text-center"}>
                    <button onClick={() => { this.createGroups(); }} className={"btn-orange edit-passenger-header-btn"} >
                        {
                            running ? (
                                <div className={"spinner-orange"}/>
                            ) : (
                                <Fragment>
                                    {t("global.validate")}
                                </Fragment>
                            )
                        }
                    </button>
                </div>
            </div>
        )
    }
}