import React from "react";
import { useTranslation } from "react-i18next";
import { useDrag } from "react-dnd";
import {
    Button,
    Checkbox,
    Paper,
    Stack,
    Typography,
    styled
} from "@mui/material";
import { OpenWith, ShoppingCart } from "@mui/icons-material";
import { mapValues } from "lodash";
import { useGetManualProductType } from "./utils/getManualProductType";
import { useCartProducts } from "../Itinerary/network/cartProducts";
import { PackageInput } from "./CartPackagePackagesList";

export type CartPackageDraggableItemProps = {
    id: number,
    roomIds?: number[],
    type: keyof ReturnType<typeof useCartProducts> | 'activities' | 'manual' | 'manual-flights',
    productType: number,
    isCustom: boolean,
    name: string,
    date: string,
    agent_accepted: boolean,
    agent_refused: boolean,
    agent_comment: string | null,
    provider_comment: string | null,
    provider_created: boolean,
    provider_accepted: boolean,
    provider_refused: boolean,
    provider_want_modification: boolean,
    counter_offer_of: number | null
}

type Props = ({
    isRemovableFromPackages: true,
    onChangePackages: React.Dispatch<React.SetStateAction<PackageInput>>
} | {
    isRemovableFromPackages?: false,
}) & {
    item: CartPackageDraggableItemProps,
    itemActions?: (item: CartPackageDraggableItemProps) => React.ReactNode,
    selectedItems?: CartPackageDraggableItemProps[],
    onChangeSelection?: (item: CartPackageDraggableItemProps, checked: boolean) => void,
    disableDnd?: boolean
}

export function CartPackageDraggableItem(props: Props): JSX.Element {
    const { t } = useTranslation();
    //eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, drag] = useDrag(() => ({
        type: 'package-product',
        item: props.selectedItems?.filter((item) => {
            return `${item.productType}-${item.id}` !== `${props.item.productType}-${props.item.id}`;
        }).concat([props.item]) ?? [props.item],
        canDrag() {
            return !props.disableDnd;
        }
    }), [props.selectedItems]);
    const getManualProductType = useGetManualProductType();

    const onRemove = () => {
        if (props.isRemovableFromPackages) {
            props.onChangePackages((state) => {
                return mapValues(
                    state,
                    (item) => {
                        return {
                            ...item,
                            products: item.products.filter((item) => {
                                return `${item.type}-${item.id}` !== `${props.item.type}-${props.item.id}`;
                            })
                        };
                    }
                );
            });
        }
    };

    const onChangeSelection = (checked: boolean) => {
        if (props.onChangeSelection) {
            props.onChangeSelection(props.item, checked);
        }
    };

    return (
        <ListItem ref={drag} disableDnd={props.disableDnd}>
            <Stack direction="row" alignItems="flex-start" spacing={1}>
                {
                    !props.disableDnd &&
                    <Checkbox
                        checked={
                            props.selectedItems &&
                            props.selectedItems.findIndex((item) => {
                                return `${item.productType}-${item.id}` === `${props.item.productType}-${props.item.id}`;
                            }) >= 0
                        }
                        onChange={(_, checked) => onChangeSelection(checked)}
                    />
                }
                <Paper elevation={1} sx={{ padding: 3, flex: 1 }}>
                    <Stack
                        direction="row"
                        alignItems={
                            props.isRemovableFromPackages ?
                                'stretch' :
                                'center'
                        }
                        justifyContent="space-between"
                        spacing={3}
                    >
                        <Stack>
                            <Typography fontWeight={700}>
                                {props.item.name}
                            </Typography>
                            <Typography color="GrayText">
                                {
                                    props.item.type === 'accommodations' &&
                                    t('cart-material.cart-construction-hotel')
                                }
                                {
                                    props.item.type === 'cars' &&
                                    t('cart-material.car')
                                }
                                {
                                    props.item.type === 'flights' &&
                                    t('cart-material.flight')
                                }
                                {
                                    props.item.type === 'activities' &&
                                    t('cart-material.cart-construction-activity')
                                }
                                {
                                    props.item.type === 'pois' &&
                                    t('cart-material.cart-construction-poi')
                                }
                                {
                                    props.item.type === 'transfers' &&
                                    t('cart-material.cart-construction-transfer')
                                }
                                {
                                    props.item.type === 'assistances' &&
                                    t('cart-material.assistance')
                                }
                                {
                                    props.item.type.includes('manual') &&
                                    getManualProductType({ product_type: props.item.productType })
                                }
                            </Typography>
                            <Typography
                                variant="caption"
                                component="div"
                                dangerouslySetInnerHTML={{
                                    __html: props.item.date
                                }}
                                sx={{
                                    '& span': {
                                        whiteSpace: 'nowrap'
                                    }
                                }}
                            />
                        </Stack>
                        {
                            props.isRemovableFromPackages &&
                            <Stack
                                alignItems="flex-end"
                                justifyContent="space-between"
                            >
                                {
                                    !props.disableDnd ?
                                        <OpenWith /> :
                                        <ShoppingCart />
                                }
                                <Button
                                    onClick={onRemove}
                                    sx={{ position: 'relative', top: 10 }}
                                >
                                    {t('cart-material.cart-construction-package-remove')}
                                </Button>
                            </Stack>
                        }
                        {
                            !props.isRemovableFromPackages &&
                            <>
                                {
                                    !props.disableDnd &&
                                    <OpenWith />
                                }
                                {
                                    props.itemActions &&
                                    props.itemActions(props.item)
                                }
                            </>
                        }
                    </Stack>
                </Paper>
            </Stack>
        </ListItem>
    );
}

const ListItem = styled(
    'li',
    {
        shouldForwardProp(name) {
            return name !== 'disableDnd';
        }
    }
)<{
    disableDnd?: boolean
}>((props) => ({
    cursor: !props.disableDnd ? 'move' : undefined,
    marginBottom: props.theme.spacing(1.5)
}));
