import React, { Fragment } from "react";
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Typography from "@material-ui/core/Typography";
import CardContent from '@material-ui/core/CardContent';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Skeleton from '@material-ui/lab/Skeleton';
import Alert from '@material-ui/lab/Alert';
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { enqueueSnackbar, closeSnackbar } from 'notistack';
import { isNumber } from "lodash";
import axios from "axios";
import { withStyles } from "@material-ui/core/styles";
import { CartConstructionReplaceProductContext } from "../CartMaterial/utils/cartConstructionReplaceProductContext";
import CheckBeforeRequest from "../Common/CheckBeforeRequest";
import GetCookie from "../Common/Functions/GetCookie";
import MosaicPicture from "./FunctionalComponent/MosaicPicture";
import AccommodationRoom from "./FunctionalComponent/AccommodationRoom";
import Picture from "./FunctionalComponent/Picture";
import AccommodationCardHeader from "./FunctionalComponent/AccommodationCardHeader";
import AccommodationCardPictures from "./FunctionalComponent/AccommodationCardPictures";
import AccommodationCardContent from "./FunctionalComponent/AccommodationCardContent";
import AccommodationCardPrices from "./FunctionalComponent/AccommodationCardPrices";
import AccommodationCardFacility from "./FunctionalComponent/AccommodationCardFacility";
import AccommodationCardStandaloneHotel from "./FunctionalComponent/AccommodationCardStandaloneHotel";
import GetPictureBySize from "./Functions/GetPictureBySize";
import TripAdvisor from "./FunctionalComponent/TripAdvisor";
import AccommodationCardPreCart from "./FunctionalComponent/AccommodationCardPreCart";
import AccommodationAddToCart from "./FunctionalComponent/AccommodationAddToCart";
import AccommodationCancellationInfo from "./FunctionalComponent/AccommodationCancellationInfo";
import AccommodationAdditionalInfo from "./FunctionalComponent/AccommodationAdditionalInfo";
import AccommodationCheckInInfo from "./FunctionalComponent/AccommodationCheckInInfo";
import AccommodationTaxesInfo from "./FunctionalComponent/AccommodationTaxesInfo";
import AccommodationMissingTravelers from "./FunctionalComponent/AccommodationMissingTravelers";
import moment from "moment";
import { SetCart } from "../../Actions/Accommodation";
import Divider from "@material-ui/core/Divider";
import AccommodationCardMap from "./FunctionalComponent/AccommodationCardMap";
import withWidth, { isWidthDown, isWidthUp } from "@material-ui/core/withWidth";
import AccommodationCardResponsiveChoose from "./FunctionalComponent/AccommodationCardResponsiveChoose";
import AccommodationCardPricesModal from "./FunctionalComponent/AccommodationCardPricesModal";
import clsx from "clsx";
import SortByKey from "../Common/SortByKey";
import { withContext } from "../CartMaterial/utils/withContext";
import { SetItineraryTypeDetails } from "../../Actions/ItineraryType";
import { CartConstructionReplaceProductProviders } from "../CartMaterial/CartConstructionReplaceProductProviders";
import {
    CartConstructionReplaceProductModalStep
} from "../CartMaterial/objects/cartConstructionReplaceProductModalStep";
import AccommodationCancellationText from './FunctionalComponent/AccommodationCancellationText';
import AccommodationLoadAdditionalProducts from './FunctionalComponent/AccommodationLoadAdditionalProducts';

const styles = {
    container: {
        maxWidth: 1128
    },
    filterTitle: {
        float: "left",
        marginTop: 10,
        marginRight: 8
    },
    stickyPaper: {
        position: "sticky",
        top: 80,
        borderRadius: 12
    },
    alignCenter: {
        textAlign: "center"
    },
    staticPriceContainerSkeleton: {
        position: 'fixed',
        bottom: 0
    },
    noPaddingTop: {
        paddingTop: '0px !important'
    },
    noMarginBottom: {
        marginBottom: 0
    },
    smallFont: {
        fontSize: '0.7rem'
    }
};

let manual_providers_interval = null;

@connect((store) => {
    return {
        user: store.user.user,
        price_list: store.accommodation.price_list,
        current_groups: store.accommodation.current_groups,
        start_date: store.accommodation.start_date,
        end_date: store.accommodation.end_date,
        cart: store.accommodation.cart,
        additional_products: store.accommodation.additional_products,
        list_cancellable: store.accommodation.cancellable,
        list_meal_plan: store.accommodation.meal_plan,
        providers: store.trip.providers,
        manual_providers: store.trip.manual_providers,
        selected_providers: store.accommodation.selected_providers,
        itinerary_list: store.itinerary.itinerary_list,
        active: store.base.active,
        currency: store.trip.currency,
        travelers: store.trip.travelers,
        traveler_groups: store.trip.traveler_groups,
        language: store.header.tmp_language,
        data_trip: store.trip.data_trip,
        map: store.base.map,
        replace_id: store.accommodation.replace_id,
        itinerary_type_details_data: store.itinerary_type.itinerary_type_details_data,
        all_data: store.trip.all_data
    };
})
@withTranslation()
@withStyles(styles)
@withWidth()
@withContext(CartConstructionReplaceProductContext)
export default class MaterialAccommodationCard extends React.Component {
    constructor(props) {
        super(props);
        const { data_trip, list_cancellable, list_meal_plan, selected_providers, itinerary_type_details_data } = this.props;
        this.state = {
            detail: null,
            pictures: [
                "/Img/accommodation_default.jpg",
                "/Img/accommodation_default.jpg",
                "/Img/accommodation_default.jpg",
                "/Img/accommodation_default.jpg",
                "/Img/accommodation_default.jpg"
            ],
            start_date: null,
            end_date: null,
            min_date: null,
            max_date: null,
            prices: null,
            prices_loaded: false,
            diff_day: 0,
            provider_loading: null,
            current_group: null,
            current_groups: null,
            current_travelers: 0,
            breakfast_included: list_meal_plan === 0 || list_meal_plan === 1 || list_meal_plan === 2 || list_meal_plan === 3,
            lunch_included: list_meal_plan === 2 || list_meal_plan === 3,
            dinner_included: list_meal_plan === 1 || list_meal_plan === 2 || list_meal_plan === 3,
            all_inclusive: list_meal_plan === 3,
            cancellable: list_cancellable,
            selected_providers: selected_providers,
            group_rooms: null,
            nb_results: 0,
            selected_room: null,
            tripadvisor: null,
            tripadvisor_modal_open: false,
            pictures_modal_open: false,
            current_picture: 0,
            group_modal_open: false,
            pictures_mosaic_modal_open: false,
            pre_cart: [],
            add_to_cart: false,
            pass_length: true,
            already_in_cart: false,
            add_directly_to_variant: data_trip.trip.circuit_type || data_trip.trip.package_type,
            provider_loaded: null,
            modal_pictures: [],
            facility_modal_open: false,
            standalone_modal_open: false,
            destination: null,
            adding: false,
            price_modal_open: false,
            unmount_after_cart: false,
            missing_travelers: null,
            variant_name: itinerary_type_details_data !== null && itinerary_type_details_data.variant_name !== undefined ? [...itinerary_type_details_data.variant_name] : []
        };
    }
    componentWillMount() {
        const { itinerary_list, t, router } = this.props;
        let has_step = false;
        for (let i = 0; i < itinerary_list.length; i++) {
            if (itinerary_list[i].step_type === "STEP") {
                has_step = true;
                break;
            }
        }
        if (!has_step) {
            enqueueSnackbar(t("apps_navigation.no_destination"), {
                variant: "warning"
            });
            if (!router.location.pathname.includes('/apps/cart')) {
                router.push(`/${window.url_name}/apps/accommodation`);
            }
        }
    }
    componentDidUpdate(prevProps) {
        const { itinerary_list, active } = this.props;
        let active_itinerary_step = null;
        for (let i = 0; i < itinerary_list.length; i++) {
            if (itinerary_list[i].step_type === "STEP") {
                if (active_itinerary_step === null) {
                    active_itinerary_step = itinerary_list[i];
                }
                if (itinerary_list[i].id === active) {
                    active_itinerary_step = itinerary_list[i];
                }
            }
        }

        let prev_active_itinerary_step = null;
        for (let i = 0; i < prevProps.itinerary_list.length; i++) {
            if (prevProps.itinerary_list[i].step_type === "STEP") {
                if (prev_active_itinerary_step === null) {
                    prev_active_itinerary_step = prevProps.itinerary_list[i];
                }
                if (prevProps.itinerary_list[i].id === prevProps.active) {
                    prev_active_itinerary_step = prevProps.itinerary_list[i];
                }
            }
        }
        if (
            prev_active_itinerary_step?.start_date !== active_itinerary_step?.start_date ||
            prev_active_itinerary_step?.end_date !== active_itinerary_step?.end_date
        ) {
            let new_start_date = moment.utc(active_itinerary_step.start_date).format("YYYY-MM-DD");
            let new_end_date = moment.utc(active_itinerary_step.end_date).format("YYYY-MM-DD");
            this.setState({
                start_date: new_start_date,
                end_date: new_end_date,
                min_date: new_start_date,
                max_date: new_end_date
            });
        }
    }
    componentDidMount() {
        const { headers } = CheckBeforeRequest();
        const { cart, params, price_list, current_groups, start_date, end_date, travelers, traveler_groups, itinerary_list, active, language, dispatch, manual_providers } = this.props;
        const { id } = params;
        const component = this;
        let active_itinerary_step = null;
        for (let i = 0; i < itinerary_list.length; i++) {
            if (itinerary_list[i].step_type === "STEP") {
                if (active_itinerary_step === null) {
                    active_itinerary_step = itinerary_list[i];
                }
                if (itinerary_list[i].id === active) {
                    active_itinerary_step = itinerary_list[i];
                }
            }
        }
        let new_current_groups = [];
        let current_travelers = 0;
        if (current_groups === null) {
            traveler_groups.map((traveler_group) => {
                if (traveler_group.default) {
                    new_current_groups.push({ ...traveler_group });
                    current_travelers += traveler_group.travelers.length;
                }
            });
        } else {
            new_current_groups = current_groups.slice();
            current_groups.map((current_group) => {
                current_travelers += current_group.travelers.length;
            });
        }
        axios({
            method: "GET",
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/accommodations/${id}/?format=json&trip=${GetCookie("trip_id")}`
        }).then(function (response) {
            let default_data = response.data.data[0];
            let custom_pictures = [];
            let default_pictures = [];
            for (let i = 0; i < response.data.data.length; i++) {
                if (!response.data.data[i].is_giata) {
                    default_data = response.data.data[i];
                }
            }
            let pictures = [];
            if (default_data.cover_picture !== null && default_data.cover_picture.url !== '') {
                pictures.push(GetPictureBySize(default_data.cover_picture, "BIG"))
            }
            if (default_data.custom_pictures.length !== 0) {
                custom_pictures = default_data.custom_pictures.filter(picture => picture.url !== '');
            }
            for (let i = 0; i < custom_pictures.length && pictures.length < 5; i++) {
                if (default_data.cover_picture === null || default_data.cover_picture.id !== custom_pictures[i].id) {
                    pictures.push(GetPictureBySize(custom_pictures[i], "BIG"));
                }
            }
            if (default_data.custom_pictures.length === 0 && !default_data.ignore_giata_pictures) {
                if (default_data.pictures.length !== 0) {
                    default_pictures = default_data.pictures.filter(picture => picture.url !== '');
                }
                for (let i = 0; i < default_pictures.length && pictures.length < 5; i++) {
                    if (default_data.cover_picture === null || default_data.cover_picture.id !== default_pictures[i].id) {
                        pictures.push(GetPictureBySize(default_pictures[i], "BIG"));
                    }
                }
            }
            while (pictures.length < 5) {
                pictures.push("/Img/accommodation_default.jpg");
            }
            default_data.dist = [];
            default_data.sports = [];
            default_data.meals = [];
            default_data.room = [];
            default_data.facility = [];
            default_data.payment = [];
            default_data.building = [];
            for (let i = 0; i < default_data.facts.length; i++) {
                for (let j = 0; j < default_data.facts.length; j++) {
                    if (i !== j && default_data.facts[i].codename === default_data.facts[j].codename) {
                        default_data.facts.splice(i, 1);
                        i = 0;
                        j = 0;
                    }
                }
            }
            for (let i = 0; i < default_data.facts.length; i++) {
                if (default_data.facts[i].codename.includes("hoteltype") || default_data.facts[i].codename === "located_on_main_road" || default_data.facts[i].codename === "year_construction" || default_data.facts[i].codename === "year_renovation" || default_data.facts[i].codename === "annexe_buildings" || default_data.facts[i].codename === "num_floors_main" || default_data.facts[i].codename === "num_floors_annexe" || default_data.facts[i].codename === "m2_garden" || default_data.facts[i].codename === "m2_terrace" || default_data.facts[i].codename === "num_rooms_total" || default_data.facts[i].codename === "num_rooms_single" || default_data.facts[i].codename === "num_rooms_double" || default_data.facts[i].codename === "num_suites" || default_data.facts[i].codename === "num_apartments" || default_data.facts[i].codename === "num_junior_suites" || default_data.facts[i].codename === "num_studios" || default_data.facts[i].codename === "num_bungalows" || default_data.facts[i].codename === "num_villas") {
                    default_data.building.push(default_data.facts[i]);
                } else if (default_data.facts[i].codename.includes("dist")) {
                    default_data.dist.push(default_data.facts[i]);
                } else if (default_data.facts[i].codename.includes("sports") && default_data.facts[i].codename !== "sports_numberofpools" && default_data.facts[i].codename !== "sports_poolheated" && default_data.facts[i].codename !== "sports_poolfreshwater" && default_data.facts[i].codename !== "sports_poolsaltwater" && default_data.facts[i].codename !== "sports_poolbar") {
                    default_data.sports.push(default_data.facts[i]);
                } else if (default_data.facts[i].codename.includes("meals") && default_data.facts[i].codename !== "meals_specialoffers" && default_data.facts[i].codename !== "meals_lunchcarte" && default_data.facts[i].codename !== "meals_dinnerchoice") {
                    default_data.meals.push(default_data.facts[i]);
                } else if (default_data.facts[i].codename.includes("room") && default_data.facts[i].codename !== "facility_roomservice" && default_data.facts[i].codename !== "facility_conferenceroom") {
                    default_data.room.push(default_data.facts[i]);
                } else if (default_data.facts[i].codename.includes("facility")) {
                    default_data.facility.push(default_data.facts[i]);
                } else if (default_data.facts[i].codename.includes("beach")) {
                    default_data.facility.push(default_data.facts[i]);
                } else if (default_data.facts[i].codename.includes("payment")) {
                    default_data.payment.push(default_data.facts[i]);
                }
            }
            let has_id = false;
            axios({
                method: "GET",
                url: `https://api.tripadvisor.com/api/partner/2.0/location_mapper/${default_data.latitude},${default_data.longitude}/`,
                params: {
                    key: "f6ca103238494fe0b3f101796f8ced28-mapper",
                    category: "hotels",
                    q: default_data.name,
                    lang: language
                }
            }).then(function (response) {
                if (response.data.data !== undefined && response.data.data.length > 0) {
                    axios({
                        method: "GET",
                        url: `https://api.tripadvisor.com/api/partner/2.0/location/${response.data.data[0].location_id}/`,
                        params: {
                            key: "f6ca103238494fe0b3f101796f8ced28",
                            lang: language
                        }
                    }).then(function (response) {
                        component.setState({ tripadvisor: response.data });
                    }).catch(function () {
                        //TODO: show snackbar error
                    });
                }
            }).catch(function () {
                //TODO: show snackbar error
            });
            let new_start_date = (start_date !== null ? start_date : moment.utc(active_itinerary_step.start_date).format("YYYY-MM-DD"));
            let new_end_date = (end_date !== null ? end_date : moment.utc(active_itinerary_step.end_date).format("YYYY-MM-DD"));
            let already_in_cart = false;
            let travelers_cpy = travelers.slice();
            cart.map((room) => {
                if (
                    moment.utc(new_start_date, "YYYY-MM-DD").isBetween(
                        moment.utc(room.start_date, "YYYY-MM-DD"),
                        moment.utc(room.end_date, "YYYY-MM-DD"),
                        'days',
                        '[]'
                    ) &&
                    moment.utc(new_end_date, "YYYY-MM-DD").isBetween(
                        moment.utc(room.start_date, "YYYY-MM-DD"),
                        moment.utc(room.end_date, "YYYY-MM-DD"),
                        'days',
                        '[]'
                    )
                ) {
                    already_in_cart = true;
                    if (room.booking_status !== null && (room.booking_status.status_booking === "BOOKED" || room.booking_status.status_booking === "CONFIRMED")) {
                        room.group_passenger.travelers.map((traveler) => {
                            for (let i = 0; i < travelers_cpy.length; i++) {
                                if (traveler === travelers_cpy[i].id) {
                                    travelers_cpy.splice(i, 1);
                                    i = 0;
                                }
                            }
                        });
                    }
                }
            });
            let missing_travelers = null;
            if (travelers_cpy.length !== travelers.length && travelers_cpy.length > 0) {
                missing_travelers = travelers_cpy;
            }
            if (manual_providers.length === 0) {
                manual_providers_interval = setInterval(() => {
                    component.checkManualProviders({ detail: default_data, pictures: pictures, current_groups: new_current_groups, missing_travelers: missing_travelers, current_travelers: current_travelers, destination: active_itinerary_step, min_date: new_start_date, start_date: new_start_date, max_date: new_end_date, end_date: new_end_date, diff_day: moment.utc(new_end_date).diff(moment.utc(new_start_date), "d"), already_in_cart: already_in_cart })
                }, 500);
            } else {
                component.setState({ detail: default_data, pictures: pictures, current_groups: new_current_groups, missing_travelers: missing_travelers, current_travelers: current_travelers, destination: active_itinerary_step, min_date: new_start_date, start_date: new_start_date, max_date: new_end_date, end_date: new_end_date, diff_day: moment.utc(new_end_date).diff(moment.utc(new_start_date), "d"), already_in_cart: already_in_cart }, function () {
                    component.getProviderPrices();
                });
            }
        }).catch(function (error) {
            //TODO: show snackbar error
        });
        dispatch({ type: "MATERIAL_UPDATE_SEEN", payload: { id: id } });
    }
    componentWillUnmount() {
        const { traveler_groups, itinerary_list, start_date, end_date, replace_id, dispatch } = this.props;
        const { destination, current_groups, unmount_after_cart } = this.state;
        if (unmount_after_cart) {
            if (moment.utc(destination.start_date).isSame(moment.utc(start_date), 'd')) {
                if (moment.utc(destination.end_date).isSame(moment.utc(end_date), 'd')) {
                    let new_current_groups = [];
                    traveler_groups.map((traveler_group) => {
                        if (traveler_group.default) {
                            new_current_groups.push({ ...traveler_group });
                        }
                    });
                    for (let i = 0; i < itinerary_list.length; i++) {
                        if (destination.id === itinerary_list[i].id) {
                            if ((itinerary_list[i + 1] !== undefined && itinerary_list[i + 1].step_type === "STEP") || replace_id !== null) {
                                const dest_bounds = JSON.parse(itinerary_list[i + 1].destination.data.bounds.replace(/'/g, '"'));
                                dispatch({
                                    type: "MATERIAL_ACCOMMODATION_CARD_UNMOUNT",
                                    payload: {
                                        active: itinerary_list[i + 1].id,
                                        bounds: {
                                            north: dest_bounds.north,
                                            east: dest_bounds.east,
                                            south: dest_bounds.south,
                                            west: dest_bounds.west
                                        },
                                        zoom: itinerary_list[i + 1].destination.data.zoom_level,
                                        start_date: itinerary_list[i + 1].start_date.split('T')[0],
                                        end_date: itinerary_list[i + 1].end_date.split('T')[0],
                                        destination: itinerary_list[i + 1],
                                        current_groups: new_current_groups,
                                        reset: true
                                    }
                                });
                            }
                            break;
                        }
                    }
                } else {
                    const dest_bounds = JSON.parse(destination.destination.data.bounds.replace(/'/g, '"'));
                    dispatch({
                        type: "MATERIAL_ACCOMMODATION_CARD_UNMOUNT",
                        payload: {
                            active: destination.id,
                            bounds: {
                                north: dest_bounds.north,
                                east: dest_bounds.east,
                                south: dest_bounds.south,
                                west: dest_bounds.west
                            },
                            zoom: destination.destination.data.zoom_level,
                            start_date: moment.utc(end_date).format("YYYY-MM-DD"),
                            end_date: moment.utc(destination.end_date).format("YYYY-MM-DD"),
                            destination: destination,
                            current_groups: current_groups,
                            reset: true
                        }
                    });
                }
            }
        }
        dispatch({ type: "MATERIAL_RESET_REPLACE_ID" });
    }
    checkManualProviders = (state) => {
        const { manual_providers } = this.props;
        const component = this;
        if (manual_providers.length > 0) {
            clearInterval(manual_providers_interval);
            component.setState(state, function () {
                component.getProviderPrices();
            });
        }
    };
    getProviderPricesCallback = (request_count) => {
        if (request_count === 0) {
            this.setState({ prices_loaded: true });
        }
    };
    getProviderPrices = () => {
        const { detail, destination, current_groups, start_date, end_date, cancellable, breakfast_included, lunch_included, dinner_included, selected_providers } = this.state;
        const { all_data, context, providers, manual_providers, currency, user, params } = this.props;
        const { id } = params;
        const { headers } = CheckBeforeRequest();
        let component = this;
        const potential_providers = detail?.potential_providers;
        const potential_custom_providers = detail?.potential_custom_providers;
        let new_rooms = "";
        let prices = [];
        let request_count = 0;
        const toBeAwaitedPromises = [];
        for (let i = 0; i < current_groups.length; i++) {
            new_rooms += `&rooms=${current_groups[i].id}`;
        }
        for (let i = 0; i < potential_providers.length; i++) {
            if (selected_providers.length === 0 || selected_providers.includes(potential_providers[i].id)) {
                request_count++;
                let provider = null;
                let weight = "100";
                let preferred_lvl = null;
                for (let j = 0; j < providers.length; j++) {
                    if (potential_providers[i].id === providers[j].provider.id && all_data.branch_code === providers[j].branch_code) {
                        provider = providers[j].provider;
                        if (user.client_full.type === 2) {
                            for (let h = 0; h < providers[j].configuration.extra_params.length; h++) {
                                if (providers[j].configuration.extra_params[h].key === "weight") {
                                    weight = providers[j].configuration.extra_params[h].value;
                                }
                                if (providers[j].configuration.extra_params[h].key === "preferred_lvl") {
                                    preferred_lvl = providers[j].configuration.extra_params[h].value;
                                }
                            }
                        }
                    }
                }
                if (provider) {
                    toBeAwaitedPromises.push(
                        axios({
                            method: "POST",
                            headers: headers,
                            url: `${API_HREF}client/${window.id_owner}/accommodations/minimal_prices/?arrival_date=${start_date}&departure_date=${end_date}&provider_id=${potential_providers[i].id}${new_rooms}&start_destination=${destination.destination.id}&currency=${currency.id}&trip=${GetCookie("trip_id")}`,
                            data: {
                                hotels_identifiers: [id]
                            }
                        }).then(function (response) {
                            if (response.data.length > 0) {
                                console.log('card room');
                                let price;
                                let name;
                                if (!cancellable) {
                                    if (breakfast_included && lunch_included && dinner_included) {
                                        price = response.data[0].full_price[0];
                                        name = response.data[0].details.full_name;
                                    } else if (breakfast_included && lunch_included) {
                                        price = response.data[0].half_price[0];
                                        name = response.data[0].details.half_name;
                                    } else if (breakfast_included) {
                                        price = response.data[0].breakfast_price[0];
                                        name = response.data[0].details.breakfast_name;
                                    } else {
                                        price = response.data[0].prices[0];
                                        name = response.data[0].details.name;
                                    }
                                } else if (breakfast_included && lunch_included && dinner_included) {
                                    price = response.data[0].c_full_price[0];
                                    name = response.data[0].c_full_details.name;
                                } else if (breakfast_included && lunch_included) {
                                    price = response.data[0].c_half_price[0];
                                    name = response.data[0].details.c_half_name;
                                } else if (breakfast_included) {
                                    price = response.data[0].c_breakfast_price[0];
                                    name = response.data[0].details.c_breakfast_name;
                                } else {
                                    price = response.data[0].cancellable_price[0];
                                    name = response.data[0].details.cancellable_name;
                                }
                                prices.push({
                                    data: provider,
                                    preferred_lvl: preferred_lvl,
                                    weight: weight,
                                    price: price !== null ? parseFloat(price.selling_price) : 0,
                                    name: name,
                                    prices: response.data[0],
                                    price_currency: price !== null ? price.selling_currency : response.data[0].prices[0].selling_currency
                                });
                                prices = prices.sort((a, b) => {
                                    return a.price - b.price;
                                });
                                component.setState({ prices: prices.slice() });
                                //if (!cancellable) {
                                //if ()
                                //prices.push({
                                //data: provider,
                                //preferred_lvl: preferred_lvl,
                                //weight: weight,
                                //price: parseFloat(response.data[0].prices[0].selling_price),
                                //prices: response.data[0],
                                //price_currency: response.data[0].prices[0].selling_currency
                                //});
                                //prices = prices.sort((a, b) => {
                                //return a.price - b.price;
                                //});
                                //component.setState({prices: prices.slice()});
                                //} else if (response.data[0].cancellable_price !== null) {
                                //prices.push({
                                //data: provider,
                                //preferred_lvl: preferred_lvl,
                                //weight: weight,
                                //price: response.data[0].cancellable_price[0] !== null ? parseFloat(response.data[0].cancellable_price[0].selling_price) : 0,
                                //prices: response.data[0],
                                //price_currency: response.data[0].cancellable_price[0] !== null ? response.data[0].cancellable_price[0].selling_currency : response.data[0].prices[0].selling_currency
                                //});
                                //prices = prices.sort((a, b) => {
                                //return a.price - b.price;
                                //});
                                //component.setState({prices: prices.slice()});
                                //}
                            }
                            request_count--;
                            component.getProviderPricesCallback(request_count);
                            return response;
                        }).catch(function (error) {
                            //TODO: show snackbar error
                            request_count--;
                            component.getProviderPricesCallback(request_count);
                        })
                    );
                }
            }
        }
        for (let i = 0; i < potential_custom_providers.length; i++) {
            if (selected_providers.length === 0 || selected_providers.includes(potential_custom_providers[i].provider_id)) {
                let provider = null;
                for (let j = 0; j < manual_providers.length; j++) {
                    if (manual_providers[j].provider.id === potential_custom_providers[i].provider_id) {
                        provider = manual_providers[j].provider;
                    }
                }
                request_count++;
                toBeAwaitedPromises.push(
                    axios({
                        method: "POST",
                        headers: headers,
                        url: `${API_HREF}client/${window.id_owner}/accommodations/minimal_prices/?arrival_date=${start_date}&departure_date=${end_date}&custom_provider=true&provider_id=${potential_custom_providers[i].provider_id}${new_rooms}&start_destination=${destination.destination.id}&currency=${currency.id}&trip=${GetCookie("trip_id")}`,
                        data: {
                            hotels_identifiers: [id]
                        }
                    }).then(function (response) {
                        if (response.data.length > 0) {
                            console.log('test provider : ', provider);
                            let price;
                            let name;
                            if (!cancellable) {
                                if (breakfast_included && lunch_included && dinner_included) {
                                    price = response.data[0].full_price[0];
                                    name = response.data[0].details.full_name;
                                } else if (breakfast_included && lunch_included) {
                                    price = response.data[0].half_price[0];
                                    name = response.data[0].details.half_name;
                                } else if (breakfast_included) {
                                    price = response.data[0].breakfast_price[0];
                                    name = response.data[0].details.breakfast_name;
                                } else {
                                    price = response.data[0].prices[0];
                                    name = response.data[0].details.name;
                                }
                            } else if (breakfast_included && lunch_included && dinner_included) {
                                price = response.data[0].c_full_price[0];
                                name = response.data[0].details.c_full_name;
                            } else if (breakfast_included && lunch_included) {
                                price = response.data[0].c_half_price[0];
                                name = response.data[0].details.c_half_name;
                            } else if (breakfast_included) {
                                price = response.data[0].c_breakfast_price[0];
                                name = response.data[0].details.c_breakfast_name;
                            } else {
                                price = response.data[0].cancellable_price[0];
                                name = response.data[0].details.cancellable_name;
                            }
                            prices.push({
                                data: provider,
                                preferred_lvl: null,
                                weight: "100",
                                price: price !== null ? parseFloat(price.selling_price) : 0,
                                name: name,
                                prices: response.data[0],
                                price_currency: price !== null ? price.selling_currency : response.data[0].prices[0].selling_currency
                            });
                            prices = prices.sort((a, b) => {
                                return a.price - b.price;
                            });
                            component.setState({ prices: prices.slice() });
                        }
                        request_count--;
                        component.getProviderPricesCallback(request_count);
                        return response;
                    }).catch(function (error) {
                        //TODO: show snackbar error
                        request_count--;
                        component.getProviderPricesCallback(request_count);
                    })
                );
            }
        }
        if (context.enableReplace) {
            context.onChangeLoading(true);
            for (const promise of toBeAwaitedPromises) {
                promise.then((response) => {
                    if (response.data.length > 0) {
                        context.onChangeLoading(false);
                    }
                }).catch((error) => console.log(error));
            }
            Promise.allSettled(toBeAwaitedPromises).then(() => context.onChangeLoading(false));
        }
    };
    newGroupCallback = (new_current_groups, groups_length, current_travelers, callback) => {
        const { t } = this.props;
        const { pre_cart } = this.state;
        if (new_current_groups.length === groups_length) {
            let current_groups = [];
            new_current_groups.map(group => {
                let new_group = { ...group };
                new_group.travelers = [...group.travelers];
                new_group.travelers_list = [...group.travelers_list];
                current_groups.push(new_group);
            });
            if (pre_cart.length !== 0) {
                enqueueSnackbar(t("accommodation.new-travelers-compo"), {
                    variant: "warning"
                });
            }
            this.setState({ current_groups: current_groups, group_modal_open: false, current_travelers: current_travelers, missing_travelers: null, pre_cart: [], current_group: { group: current_groups[0], index_group: 1 }, selected_room: null, group_rooms: null, provider_loaded: null }, function () {
                callback(new_current_groups.slice());
                this.getProviderPrices();
            });
        }
    };
    checkEqualGroups = (group_1, group_2) => {
        if (!Array.isArray(group_1) || !Array.isArray(group_2) || group_1.length !== group_2.length) {
            return false;
        }
        let cpy_group_1 = group_1.concat().sort();
        let cpy_group_2 = group_2.concat().sort();
        for (let i = 0; i < cpy_group_1.length; i++) {
            if (cpy_group_1[i] !== cpy_group_2[i]) {
                return false;
            }
        }
        return true;
    };
    setNewGroupsFromHeader = () => {
        const { current_groups, traveler_groups } = this.props;
        let new_current_groups = [];
        let current_travelers = 0;
        if (current_groups === null) {
            traveler_groups.map((traveler_group) => {
                if (traveler_group.default) {
                    new_current_groups.push({ ...traveler_group });
                    current_travelers += traveler_group.travelers.length;
                }
            });
        } else {
            new_current_groups = current_groups.slice();
            current_groups.map((current_group) => {
                current_travelers += current_group.travelers.length;
            });
        }
        this.setState({ current_groups: new_current_groups, current_travelers: current_travelers });
    };
    setNewGroups = (groups, callback) => {
        const { current_groups } = this.state;
        const { traveler_groups, t } = this.props;
        const { headers } = CheckBeforeRequest();
        let component = this;
        let new_current_groups = [];
        let current_travelers = 0;
        for (let i = 0; i < groups.length; i++) {
            if (groups[i].travelers.length === 0) {
                groups.splice(i, 1);
                i = 0;
            }
        }
        if (groups.length === 0) {
            this.setState({group_modal_open: false, missing_travelers: null});
        } else {
            let same = groups.length === current_groups.length;
            if (same) {
                groups.map((group) => {
                    let already_exists = false;
                    current_groups.map((current_group) => {
                        if (!already_exists && this.checkEqualGroups(group.travelers_list, current_group.travelers_list)) {
                            already_exists = true;
                        }
                    });
                    if (!already_exists) {
                        same = false;
                    }
                });
            }
            if (!same) {
                let has_adult = true;
                groups.map((group) => {
                    let has_group_adult = false;
                    group.travelers_list.map((traveler) => {
                        if (moment().diff(traveler.birth_date, "y") > 18) {
                            has_group_adult = true;
                        }
                    });
                    if (!has_group_adult) {
                        has_adult = false;
                    }
                });
                if (!has_adult) {
                    enqueueSnackbar(t("accommodation.room_without_adult"), {
                        variant: "warning",
                    });
                } else {
                    groups.map((group) => {
                        let already_exists = false;
                        group.travelers = [];
                        group.travelers_list.map((traveler) => {
                            group.travelers.push(traveler.id);
                        });
                        traveler_groups.map((traveler_group) => {
                            if (!already_exists && this.checkEqualGroups(group.travelers, traveler_group.travelers)) {
                                already_exists = true;
                                new_current_groups.push(traveler_group);
                                current_travelers += traveler_group.travelers.length;
                                this.newGroupCallback(new_current_groups, groups.length, current_travelers, callback);
                            }
                        });
                        if (!already_exists) {
                            axios({
                                method: "POST",
                                headers: headers,
                                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/travelers-group/?token=${GetCookie("trip_token")}`,
                                data: {
                                    travelers: group.travelers,
                                    default: false
                                },
                            }).then(function (response) {
                                new_current_groups.push(response.data);
                                current_travelers += response.data.travelers.length;
                                component.newGroupCallback(new_current_groups, groups.length, current_travelers, callback);
                            }).catch(function (error) {
                                //TODO: show snackbar error
                            });
                        }
                    });
                }
            } else {
                this.setState({group_modal_open: false, missing_travelers: null});
            }
        }
    };

    getRoomPrices = (provider_data) => {
        const { pre_cart, destination, start_date, end_date, current_groups } = this.state;
        const { cart, user, params, t } = this.props;
        const { id } = params;
        const { headers } = CheckBeforeRequest();
        let component = this;
        this.setState({
            provider_loading: provider_data.id
        });
        let rooms = "";
        for (let i = 0; i < current_groups.length; i++) {
            rooms += "&rooms=" + current_groups[i].id;
        }
        return new Promise((resolve) => {
            axios({
                method: "GET",
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/accommodations/rooms_prices/?${rooms}`,
                params: {
                    arrival_date: start_date,
                    departure_date: end_date,
                    custom_provider: provider_data.custom,
                    hotels_identifiers: id,
                    provider_id: provider_data.id,
                    trip: GetCookie("trip_id"),
                    start_destination: destination.destination.id
                }
            }).then(function (response) {
                response.data.map((group_rooms) => {
                    group_rooms.rooms.map((room) => {
                        cart.map((cart_room) => {
                            if ((cart_room.booking_status === null || cart_room.booking_status.status_booking !== "CANCELLED") && moment.utc(cart_room.start_date, "YYYY-MM-DD").isSameOrAfter(moment.utc(start_date, "YYYY-MM-DD")) && moment.utc(cart_room.end_date, "YYYY-MM-DD").isSameOrBefore(moment.utc(end_date, "YYYY-MM-DD")) && (cart_room.room_code !== null && room.room_code !== null && cart_room.room_code === room.room_code)) {
                                room.variants.map((variant) => {
                                    if (variant.remaining_stock !== null) {
                                        variant.remaining_stock -= 1;
                                    }
                                });
                            }
                        });
                        let has_breakfast_variant = false;
                        let has_lunch_variant = false;
                        let has_dinner_variant = false;
                        let has_cancellable_variant = false;
                        room.price_from = null;
                        room.variants.map((variant) => {
                            for (let i = 0; i < variant.price_stay.length; i++) {
                                if (user.client === variant.price_stay[i].owner) {
                                    variant.showing_price = variant.price_stay[i].selling_price;
                                    variant.showing_currency = variant.price_stay[i].selling_currency;
                                    variant.fees_curreency = variant.price_stay[i].purchase_currency;
                                    if (variant.bed_type !== null) {
                                        variant.bed_type = variant.bed_type.split('|');
                                    }
                                    if (room.price_from === null || parseFloat(room.price_from) > parseFloat(variant.showing_price)) {
                                        room.price_from = variant.showing_price;
                                        room.price_from_currency = variant.showing_currency;
                                    }
                                }
                            }
                            if (variant.breakfast_included) {
                                has_breakfast_variant = true;
                            }
                            if (variant.lunch_included) {
                                has_lunch_variant = true;
                            }
                            if (variant.dinner_included) {
                                has_dinner_variant = true;
                            }
                            // if (variant.all_inclusive) {
                            //     has_breakfast_variant = true;
                            //     has_lunch_variant = true;
                            //     has_dinner_variant = true;
                            // }
                            if (variant.is_cancellable) {
                                has_cancellable_variant = true;
                            }
                        });
                        room.variants = room.variants.sort((a, b) => {
                            return parseFloat(a.showing_price) - parseFloat(b.showing_price);
                        });
                        room.has_cancellable_variant = has_cancellable_variant;
                        room.has_breakfast_variant = has_breakfast_variant;
                        room.has_lunch_variant = has_lunch_variant;
                        room.has_dinner_variant = has_dinner_variant;
                    });
                    group_rooms.rooms = group_rooms.rooms.sort((a, b) => {
                        return parseFloat(a.price_from) - parseFloat(b.price_from);
                    });
                });
                let current_group = {
                    group: current_groups[0],
                    index_group: 1
                };
                if (pre_cart.length > 0) {
                    let next = null;
                    current_groups.map((group, index_group) => {
                        let has_room = false;
                        pre_cart.map((room) => {
                            if (!has_room && group.id === room.passenger_group_id) {
                                has_room = true;
                            }
                        });
                        if (!has_room && next === null) {
                            next = {
                                group: group,
                                index_group: index_group + 1
                            };
                        }
                    });
                    current_group = next;
                }
                console.log("response.data : ", response.data);
                component.setState({
                    provider_loading: null,
                    provider_loaded: provider_data,
                    nb_results: response.data[0].rooms.length,
                    group_rooms: response.data,
                    current_group: current_group,
                    price_modal_open: false
                }, function () {
                    const element = document.getElementById('current-provider');
                    if (element !== undefined && element !== null) {
                        element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
                    }
                });
            }).catch(function (error) {
                component.setState({
                    provider_loading: null
                });
                enqueueSnackbar(t("accommodation.request.no_results"), {
                    variant: "warning"
                });
            }).finally(resolve);
        });
    };
    setRoomMappingResults = (results) => {
        this.setState({
            provider_loading: results.provider_loading,
            provider_loaded: results.provider_loaded,
            nb_results: results.nb_results,
            group_rooms: results.group_rooms,
            current_group: results.current_group,
            price_modal_open: results.price_modal_open
        });
    };
    toggleMosaicModal = (pictures) => {
        const { pictures_mosaic_modal_open } = this.state;
        this.setState({ pictures_mosaic_modal_open: !pictures_mosaic_modal_open, modal_pictures: pictures });
    };
    togglePictureModal = (pictures, index_picture = 0) => {
        const { pictures_modal_open } = this.state;
        this.setState({ pictures_modal_open: !pictures_modal_open, modal_pictures: pictures, current_picture: index_picture });
    };
    setCurrentPicture = (new_current_picture) => {
        this.setState({ current_picture: new_current_picture });
    };
    toggleTripadvisorModal = () => {
        const { tripadvisor_modal_open } = this.state;
        this.setState({ tripadvisor_modal_open: !tripadvisor_modal_open });
    };
    toggleFacilityModal = () => {
        const { facility_modal_open } = this.state;
        this.setState({ facility_modal_open: !facility_modal_open });
    };
    toggleStandaloneModal = () => {
        const { standalone_modal_open } = this.state;
        const { dispatch } = this.props;
        this.setState({ standalone_modal_open: !standalone_modal_open });
        dispatch({ type: 'CART_LEAVE_CART' });
    };
    standaloneSuccess = () => {
        const { router } = this.props;
        const { standalone_modal_open } = this.state;
        this.setState({ standalone_modal_open: !standalone_modal_open });
        this.setState({ unmount_after_cart: true }, function () {
            if (!router.location.pathname.includes('/apps/cart')) {
                router.push(`/${window.url_name}/apps/accommodation`);
            }
        });
    };
    togglePriceModal = () => {
        const { price_modal_open } = this.state;
        this.setState({ price_modal_open: !price_modal_open });
    };
    onDateChange = (dates) => {
        this.setState({ start_date: dates[0] === null ? null : dates[0].format("YYYY-MM-DD"), end_date: dates[1] === null ? null : dates[1].format("YYYY-MM-DD"), diff_day: dates[0] !== null && dates[1] !== null ? moment.utc(dates[1]).diff(moment.utc(dates[0]), "d") : 0, prices: [] }, function () {
            this.getProviderPrices();
        });
    };
    toggleGroupModal = () => {
        const { group_modal_open } = this.state;
        this.setState({ group_modal_open: !group_modal_open });
    };
    toggleBreakfastIncluded = () => {
        const { user } = this.props;
        const { group_rooms, current_group, breakfast_included, lunch_included, dinner_included, all_inclusive, cancellable, prices } = this.state;
        let nb_results = 0;
        let new_value = !breakfast_included;
        let prices_cpy = prices.slice();
        prices_cpy.map((price) => {
            price.price = 0;
            if (!new_value && !lunch_included && !dinner_included && !all_inclusive && !cancellable) {
                price.price = price.prices.prices !== null ? price.prices.prices[0].selling_price : 0;
            } else if (!new_value && !lunch_included && !dinner_included && cancellable) {
                price.price = price.prices.cancellable_price !== null ? price.prices.cancellable_price[0].selling_price : 0;
            } else if (new_value && !cancellable) {
                price.price = price.prices.breakfast_price !== null ? price.prices.breakfast_price[0].selling_price : 0;
            } else if (new_value && dinner_included && !cancellable) {
                price.price = price.prices.half_price !== null ? price.prices.half_price[0].selling_price : 0;
            } else if (new_value && lunch_included && dinner_included && !cancellable) {
                price.price = price.prices.full_price !== null ? price.prices.full_price[0].selling_price : 0;
            } else if (new_value && cancellable) {
                price.price = price.prices.c_breakfast_price !== null ? price.prices.c_breakfast_price[0].selling_price : 0;
            } else if (new_value && dinner_included && cancellable) {
                price.price = price.prices.c_half_price !== null ? price.prices.c_half_price[0].selling_price : 0;
            } else if (new_value && lunch_included && dinner_included && cancellable) {
                price.price = price.prices.c_full_price !== null ? price.prices.c_full_price[0].selling_price : 0;
            }
        });
        group_rooms.map((group) => {
            if (group.passenger_group_id === current_group.group.id) {
                group.rooms.map((room) => {
                    room.price_from = null;
                    if ((!new_value || (new_value && room.has_breakfast_variant)) && (!lunch_included || (lunch_included && room.has_breakfast_variant && room.has_dinner_variant)) && (!dinner_included || (dinner_included && room.has_dinner_variant && room.has_breakfast_variant && room.has_lunch_variant)) && (!all_inclusive || (all_inclusive && room.variants.find((variant) => variant.all_inclusive) !== undefined)) && (!cancellable || (cancellable && room.has_cancellable_variant))) {
                        room.variants.map((variant) => {
                            if ((!new_value || (new_value && (variant.breakfast_included))) && (!lunch_included || (lunch_included && variant.breakfast_included && variant.dinner_included)) && (!dinner_included || (dinner_included && (variant.dinner_included && variant.breakfast_included && variant.lunch_included))) && (!all_inclusive || (all_inclusive && variant.all_inclusive)) && (!cancellable || (cancellable && variant.is_cancellable))) {
                                for (let i = 0; i < variant.price_stay.length; i++) {
                                    if (user.client === variant.price_stay[i].owner) {
                                        if (room.price_from === null || room.price_from > variant.showing_price) {
                                            room.price_from = variant.showing_price;
                                        }
                                    }
                                }
                            }
                        });
                        nb_results += 1;
                    }
                });
            }
        });
        this.setState({ breakfast_included: new_value, nb_results: nb_results, selected_room: null, group_rooms: group_rooms, prices: prices_cpy });
    };
    toggleLunchIncluded = () => {
        const { user } = this.props;
        const { group_rooms, current_group, breakfast_included, lunch_included, dinner_included, all_inclusive, cancellable, prices } = this.state;
        let nb_results = 0;
        let new_value = !lunch_included;
        let prices_cpy = prices.slice();
        prices_cpy.map((price) => {
            price.price = 0;
            if (!breakfast_included && !new_value && !dinner_included && !all_inclusive && !cancellable) {
                price.price = price.prices.prices !== null ? price.prices.prices[0].selling_price : 0;
            } else if (!breakfast_included && !new_value && !dinner_included && !all_inclusive && cancellable) {
                price.price = price.prices.cancellable_price !== null ? price.prices.cancellable_price[0].selling_price : 0;
            } else if (breakfast_included && !cancellable) {
                price.price = price.prices.breakfast_price !== null ? price.prices.breakfast_price[0].selling_price : 0;
            } else if (breakfast_included && new_value && dinner_included && !cancellable) {
                price.price = price.prices.full_price !== null ? price.prices.full_price[0].selling_price : 0;
            } else if (breakfast_included && cancellable) {
                price.price = price.prices.c_breakfast_price !== null ? price.prices.c_breakfast_price[0].selling_price : 0;
            } else if (breakfast_included && new_value && dinner_included && cancellable) {
                price.price = price.prices.c_full_price !== null ? price.prices.c_full_price[0].selling_price : 0;
            } else if (new_value) {
                price.price = price.prices.half_price !== null ? price.prices.half_price[0].selling_price : 0;
            }
        });
        group_rooms.map((group) => {
            if (group.passenger_group_id === current_group.group.id) {
                group.rooms.map((room) => {
                    room.price_from = null;
                    if ((!breakfast_included || (breakfast_included && room.has_breakfast_variant)) && (!new_value || (new_value && room.has_dinner_variant && room.has_breakfast_variant)) && (!dinner_included || (dinner_included && room.has_dinner_variant && room.has_breakfast_variant && room.has_lunch_variant)) && (!all_inclusive || (all_inclusive && room.variants.find((variant) => variant.all_inclusive) !== undefined)) && (!cancellable || (cancellable && room.has_cancellable_variant))) {
                        room.variants.map((variant) => {
                            if ((!breakfast_included || (breakfast_included && variant.breakfast_included)) && (!new_value || (new_value && variant.breakfast_included && variant.dinner_included)) && (!dinner_included || (dinner_included && variant.dinner_included)) && (!cancellable || (cancellable && variant.is_cancellable))) {
                                for (let i = 0; i < variant.price_stay.length; i++) {
                                    if (user.client === variant.price_stay[i].owner) {
                                        if (room.price_from === null || room.price_from > variant.showing_price) {
                                            room.price_from = variant.showing_price;
                                        }
                                    }
                                }
                            }
                        });
                        nb_results += 1;
                    }
                });
            }
        });
        this.setState({ lunch_included: new_value, nb_results: nb_results, selected_room: null, group_rooms: group_rooms, prices: prices_cpy });
    };
    toggleDinnerIncluded = () => {
        const { user } = this.props;
        const { group_rooms, current_group, breakfast_included, lunch_included, dinner_included, all_inclusive, cancellable, prices } = this.state;
        let nb_results = 0;
        let new_value = !dinner_included;
        let prices_cpy = prices.slice();
        prices_cpy.map((price) => {
            price.price = 0;
            if (!breakfast_included && !lunch_included && !new_value && !all_inclusive && !cancellable) {
                price.price = price.prices.prices !== null ? price.prices.prices[0].selling_price : 0;
            } else if (!breakfast_included && !lunch_included && !new_value && cancellable) {
                price.price = price.prices.cancellable_price !== null ? price.prices.cancellable_price[0].selling_price : 0;
            } else if (breakfast_included && !cancellable) {
                price.price = price.prices.breakfast_price !== null ? price.prices.breakfast_price[0].selling_price : 0;
            } else if (breakfast_included && new_value && !cancellable) {
                price.price = price.prices.half_price !== null ? price.prices.half_price[0].selling_price : 0;
            } else if (breakfast_included && lunch_included && new_value && !cancellable) {
                price.price = price.prices.full_price !== null ? price.prices.full_price[0].selling_price : 0;
            } else if (breakfast_included && cancellable) {
                price.price = price.prices.c_breakfast_price !== null ? price.prices.c_breakfast_price[0].selling_price : 0;
            } else if (breakfast_included && new_value && cancellable) {
                price.price = price.prices.c_half_price !== null ? price.prices.c_half_price[0].selling_price : 0;
            } else if (breakfast_included && lunch_included && new_value && cancellable) {
                price.price = price.prices.c_full_price !== null ? price.prices.c_full_price[0].selling_price : 0;
            }
        });
        group_rooms.map((group) => {
            if (group.passenger_group_id === current_group.group.id) {
                group.rooms.map((room) => {
                    room.price_from = null;
                    if ((!breakfast_included || (breakfast_included && room.has_breakfast_variant)) && (!lunch_included || (lunch_included && room.has_breakfast_variant && room.has_dinner_variant)) && (!new_value || (new_value && room.has_dinner_variant && room.has_breakfast_variant && room.has_lunch_variant)) && (!all_inclusive || (all_inclusive && room.variants.find((variant) => variant.all_inclusive) !== undefined)) && (!cancellable || (cancellable && room.has_cancellable_variant))) {
                        room.variants.map((variant) => {
                            if ((!breakfast_included || (breakfast_included && variant.breakfast_included)) && (!lunch_included || (lunch_included && variant.breakfast_included && variant.dinner_included)) && (!new_value || (new_value && variant.dinner_included && variant.breakfast_included && variant.lunch_included)) && (!all_inclusive || (all_inclusive && variant.all_inclusive)) && (!cancellable || (cancellable && variant.is_cancellable))) {
                                for (let i = 0; i < variant.price_stay.length; i++) {
                                    if (user.client === variant.price_stay[i].owner) {
                                        if (room.price_from === null || room.price_from > variant.showing_price) {
                                            room.price_from = variant.showing_price;
                                        }
                                    }
                                }
                            }
                        });
                        nb_results += 1;
                    }
                });
            }
        });
        this.setState({ dinner_included: new_value, nb_results: nb_results, selected_room: null, group_rooms: group_rooms, prices: prices_cpy });
    };
    toggleAllInclusive = () => {
        const { user } = this.props;
        const { group_rooms, current_group, breakfast_included, lunch_included, dinner_included, all_inclusive, cancellable, prices } = this.state;
        let nb_results = 0;
        let new_value = !all_inclusive;
        let prices_cpy = prices.slice();
        prices_cpy.map((price) => {
            price.price = 0;
            if (!breakfast_included && !lunch_included && !dinner_included && !new_value && !cancellable) {
                price.price = price.prices.prices !== null ? price.prices.prices[0].selling_price : 0;
            } else if (!breakfast_included && !lunch_included && !dinner_included && !new_value && cancellable) {
                price.price = price.prices.cancellable_price !== null ? price.prices.cancellable_price[0].selling_price : 0;
            } else if (breakfast_included && !cancellable) {
                price.price = price.prices.breakfast_price !== null ? price.prices.breakfast_price[0].selling_price : 0;
            } else if (breakfast_included && dinner_included && !cancellable) {
                price.price = price.prices.half_price !== null ? price.prices.half_price[0].selling_price : 0;
            } else if (breakfast_included && lunch_included && dinner_included && !cancellable) {
                price.price = price.prices.full_price !== null ? price.prices.full_price[0].selling_price : 0;
            } else if (breakfast_included && cancellable) {
                price.price = price.prices.c_breakfast_price !== null ? price.prices.c_breakfast_price[0].selling_price : 0;
            } else if (breakfast_included && dinner_included && cancellable) {
                price.price = price.prices.c_half_price !== null ? price.prices.c_half_price[0].selling_price : 0;
            } else if (breakfast_included && lunch_included && dinner_included && cancellable) {
                price.price = price.prices.c_full_price !== null ? price.prices.c_full_price[0].selling_price : 0;
            }
        });
        group_rooms.map((group) => {
            if (group.passenger_group_id === current_group.group.id) {
                group.rooms.map((room) => {
                    room.price_from = null;
                    if ((!breakfast_included || (breakfast_included && room.has_breakfast_variant)) && (!lunch_included || (lunch_included && room.has_breakfast_variant && room.has_dinner_variant)) && (!dinner_included || (dinner_included && room.has_dinner_variant && room.has_breakfast_variant && room.has_lunch_variant)) && (!new_value || (new_value && room.variants.find((variant) => variant.all_inclusive) !== undefined)) && (!cancellable || (cancellable && room.has_cancellable_variant))) {
                        room.variants.map((variant) => {
                            if ((!breakfast_included || (breakfast_included && variant.breakfast_included)) && (!lunch_included || (lunch_included && variant.breakfast_included && variant.dinner_included)) && (!dinner_included || (dinner_included && variant.dinner_included && variant.breakfast_included && variant.lunch_included)) && (!new_value || (new_value && variant.all_inclusive)) && (!cancellable || (cancellable && variant.is_cancellable))) {
                                for (let i = 0; i < variant.price_stay.length; i++) {
                                    if (user.client === variant.price_stay[i].owner) {
                                        if (room.price_from === null || room.price_from > variant.showing_price) {
                                            room.price_from = variant.showing_price;
                                        }
                                    }
                                }
                            }
                        });
                        nb_results += 1;
                    }
                });
            }
        });
        this.setState({ all_inclusive: new_value, nb_results: nb_results, selected_room: null, group_rooms: group_rooms, prices: prices_cpy });
    };
    toggleCancellable = () => {
        const { user } = this.props;
        const { group_rooms, current_group, breakfast_included, lunch_included, dinner_included, all_inclusive, cancellable, prices } = this.state;
        let nb_results = 0;
        let new_value = !cancellable;
        let prices_cpy = prices.slice();
        prices_cpy.map((price) => {
            price.price = 0;
            if (!breakfast_included && !lunch_included && !dinner_included && !all_inclusive && !new_value) {
                price.price = price.prices.prices !== null ? price.prices.prices[0].selling_price : 0;
            } else if (!breakfast_included && !lunch_included && !dinner_included && new_value) {
                price.price = price.prices.cancellable_price !== null ? price.prices.cancellable_price[0].selling_price : 0;
            } else if (breakfast_included && !new_value) {
                price.price = price.prices.breakfast_price !== null ? price.prices.breakfast_price[0].selling_price : 0;
            } else if (breakfast_included && dinner_included && !new_value) {
                price.price = price.prices.half_price !== null ? price.prices.half_price[0].selling_price : 0;
            } else if (breakfast_included && lunch_included && dinner_included && !new_value) {
                price.price = price.prices.full_price !== null ? price.prices.full_price[0].selling_price : 0;
            } else if (breakfast_included && new_value) {
                price.price = price.prices.c_breakfast_price !== null ? price.prices.c_breakfast_price[0].selling_price : 0;
            } else if (breakfast_included && dinner_included && new_value) {
                price.price = price.prices.c_half_price !== null ? price.prices.c_half_price[0].selling_price : 0;
            } else if (breakfast_included && lunch_included && dinner_included && new_value) {
                price.price = price.prices.c_full_price !== null ? price.prices.c_full_price[0].selling_price : 0;
            }
        });
        group_rooms.map((group) => {
            if (group.passenger_group_id === current_group.group.id) {
                group.rooms.map((room) => {
                    room.price_from = null;
                    if ((!breakfast_included || (breakfast_included && room.has_breakfast_variant)) && (!lunch_included || (lunch_included && room.has_breakfast_variant && room.has_dinner_variant)) && (!dinner_included || (dinner_included && room.has_dinner_variant && room.has_breakfast_variant && room.has_lunch_variant)) && (!all_inclusive || (all_inclusive && room.variants.find((variant) => variant.all_inclusive) !== undefined)) && (!new_value || (new_value && room.has_cancellable_variant))) {
                        room.variants.map((variant) => {
                            if ((!breakfast_included || (breakfast_included && (room.breakfast_included))) && (!lunch_included || (lunch_included && variant.breakfast_included && variant.dinner_included)) && (!dinner_included || (dinner_included && variant.dinner_included && variant.breakfast_included && variant.lunch_included)) && (!all_inclusive || (all_inclusive && variant.all_inclusive)) && (!new_value || (new_value && variant.is_cancellable))) {
                                for (let i = 0; i < variant.price_stay.length; i++) {
                                    if (user.client === variant.price_stay[i].owner) {
                                        if (room.price_from === null || room.price_from > variant.showing_price) {
                                            room.price_from = variant.showing_price;
                                        }
                                    }
                                }
                            }
                        });
                        nb_results += 1;
                    }
                });
            }
        });
        this.setState({ cancellable: new_value, nb_results: nb_results, selected_room: null, group_rooms: group_rooms, prices: prices_cpy });
    };
    addToPreCart = (variant, room_code) => {
        const { start_date, end_date, current_group, current_groups, pre_cart, provider_loaded, group_rooms } = this.state;
        let new_pre_cart = pre_cart.slice();
        let adult_nb = 0;
        let child_nb = 0;
        current_group.group.travelers_list.map((traveler) => {
            let dob = new Date(traveler.birth_date);
            let diff = new Date(Date.now() - dob.getTime());
            let age = Math.abs(diff.getUTCFullYear() - 1970);
            if (age >= 18) {
                adult_nb += 1;
            } else {
                child_nb += 1;
            }
        });
        new_pre_cart.push({
            passenger_group_id: current_group.group.id,
            provider_loaded: provider_loaded ?? variant.provider,
            adult_nb: adult_nb,
            child_nb: child_nb,
            start_date: start_date,
            end_date: end_date,
            variant: variant,
            room_code: room_code
        });
        let next = null;
        current_groups.map((group, index_group) => {
            let has_room = false;
            new_pre_cart.map((room) => {
                if (!has_room && group.id === room.passenger_group_id) {
                    has_room = true;
                    room.room_nb = index_group;
                }
            });
            if (!has_room && next === null) {
                next = {
                    group: group,
                    index_group: index_group + 1
                };
            }
        });
        new_pre_cart = new_pre_cart.sort((a, b) => {
            return a.room_nb - b.room_nb;
        });
        group_rooms.map(group => {
            group.rooms.map(room => {
                if (room_code !== null && room.room_code === room_code) {
                    room.variants.map(variant => {
                        if (variant.remaining_stock !== null) {
                            variant.remaining_stock -= 1;
                        }
                    });
                }
            });
        });
        this.setState({ pre_cart: new_pre_cart, current_group: next, selected_room: null }, function () {
            const element = document.getElementById(`pre-cart-0`);
            if (element !== undefined && element !== null) {
                element.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
            }
        });
        if (this.props.onChangeCanValidate) {
            this.props.onChangeCanValidate(true);
        }
    };
    deleteRoom = (index) => {
        const { pre_cart, group_rooms } = this.state;
        let new_pre_cart = pre_cart.slice();
        group_rooms.map(group => {
            group.rooms.map(room => {
                if (pre_cart[index].room_code !== null && room.room_code === pre_cart[index].room_code) {
                    room.variants.map(variant => {
                        if (variant.remaining_stock !== null) {
                            variant.remaining_stock += 1;
                        }
                    });
                }
            });
        });
        new_pre_cart.splice(index, 1);
        new_pre_cart = new_pre_cart.sort((a, b) => {
            return a.room_nb - b.room_nb;
        });
        this.setState({ pre_cart: new_pre_cart });
    };
    controlCart = () => {
        const { detail, current_groups, pre_cart, add_directly_to_variant, start_date, end_date } = this.state;
        const { replace_id, cart, t } = this.props;
        let pass_length = current_groups.length === pre_cart.length;
        let already_in_cart = false;
        let deletion_restriction = false;
        cart.map((room) => {
            if (
                !room.is_optional &&
                (room.booking_status === null || room.booking_status.status_booking !== "CANCELLED") &&
                moment.utc(start_date, "YYYY-MM-DD").isBetween(
                    moment.utc(room.start_date, "YYYY-MM-DD"),
                    moment.utc(room.end_date, "YYYY-MM-DD"),
                    'days',
                    '[]'
                ) &&
                moment.utc(end_date, "YYYY-MM-DD").isBetween(
                    moment.utc(room.start_date, "YYYY-MM-DD"),
                    moment.utc(room.end_date, "YYYY-MM-DD"),
                    'days',
                    '[]'
                )
            ) {
                if (room.deletion_restriction && detail.id !== room.hotel[0].id) {
                    deletion_restriction = true;
                }
                if (replace_id === null) {
                    room.group_passenger.travelers.map((traveler) => {
                        current_groups.map((group) => {
                            group.travelers.map((group_traveler) => {
                                if (traveler === group_traveler) {
                                    already_in_cart = true;
                                }
                            });
                        });
                    });
                }
            }
        });
        if (deletion_restriction) {
            enqueueSnackbar(t("accommodation.request.deletion_restriction"), {
                variant: "warning"
            });
        } else if (pass_length && !already_in_cart) {
            this.setState({ adding: true }, function () {
                this.addToCart(false, false, add_directly_to_variant);
            });
        } else {
            this.setState({ adding: true, add_to_cart: true, pass_length: pass_length, already_in_cart: already_in_cart });
        }
    };
    addToCart = (replace, option, variant) => {
        const { pre_cart, start_date, end_date, destination, variant_name } = this.state;
        const { context, replace_id, router, cart, all_data, params, dispatch, t } = this.props;
        const { id } = params;
        const { headers } = CheckBeforeRequest();
        this.setState({ add_to_cart: false });
        let request_count = pre_cart.length;
        let cart_cpy = [];
        let option_value = 0;
        let variant_value = 0;
        let all_variant = [...variant_name];
        if (replace || option || variant) {
            cart.map((room) => {
                if (moment.utc(room.start_date, "YYYY-MM-DD").isSameOrAfter(moment.utc(start_date, "YYYY-MM-DD")) && moment.utc(room.end_date, "YYYY-MM-DD").isSameOrBefore(moment.utc(end_date, "YYYY-MM-DD"))) {
                    if (replace) {
                        request_count++;
                        axios({
                            method: "DELETE",
                            headers: headers,
                            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/accommodation/${room.id}/?token=${GetCookie("trip_token")}`
                        }).then(function (response) {
                            request_count--;
                            dispatch({ type: 'ACCOMMODATION_REMOVE_FROM_CART_BY_ID', payload: room.id });
                        }).catch(function () {
                            request_count--;
                            cart_cpy.push(room);
                            enqueueSnackbar(t("accommodation.request.delete_error"), {
                                variant: "error"
                            });
                        });
                    } else {
                        cart_cpy.push(room);
                    }
                    if (option) {
                        if ((option_value !== 0 && room.option !== null && option_value <= room.option) || (option_value === 0 && room.option !== null)) {
                            option_value = room.option + 1;
                        } else if (option_value === 0 && room.option === null) {
                            option_value = 1;
                        }
                    }
                    if (variant) {
                        if ((variant_value !== 0 && room.variant !== null && variant_value <= room.variant) || (variant_value === 0 && room.variant !== null)) {
                            variant_value = room.variant + 1;
                        } else if (variant_value === 0 && room.variant === null) {
                            variant_value = 1;
                        }
                        if (variant_name.find((variant) => variant.id === variant_value) === undefined) {
                            let other_variant = [...variant_name].filter(variant => variant.version !== parseInt(GetCookie('trip_id_version')));
                            let new_variant = [...variant_name].filter(variant => variant.version === parseInt(GetCookie('trip_id_version')));
                            new_variant.push({
                                id: new_variant.length > 0 ? new_variant[new_variant.length - 1].id + 1 : 1,
                                name: {},
                                version: parseInt(GetCookie('trip_id_version'))
                            });
                            all_variant = [...other_variant, ...new_variant];
                        } else {
                            all_variant = [...variant_name];
                        }
                    }
                } else {
                    cart_cpy.push(room);
                }
            });
            if (variant && variant_value === 0) {
                variant_value = 1;
            }
        } else {
            cart_cpy = cart.slice();
        }
        const toBeAwaitedPromises = [];
        if (replace_id !== null) {
            request_count++;
            if (context.enableReplace && context.onDelete) {
                context.onDelete();
            }

            toBeAwaitedPromises.push(
                axios({
                    method: "DELETE",
                    headers: headers,
                    url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/accommodation/${replace_id}/?token=${GetCookie("trip_token")}`
                }).then(function () {
                    request_count--;
                    dispatch({ type: 'ACCOMMODATION_REMOVE_FROM_CART_BY_ID', payload: replace_id });
                }).catch(function () {
                    request_count--;
                    enqueueSnackbar(t("accommodation.request.delete_error"), {
                        variant: "error"
                    });
                })
            );
        }
        pre_cart.map((room, room_index) => {
            let request = {
                provider_id: room.provider_loaded.id,
                custom_provider: room.provider_loaded.custom,
                room_identifier: room.variant.identifier,
                rooms: [room.passenger_group_id],
                hotels_identifiers: [id],
                arrival_date: room.start_date,
                departure_date: room.end_date,
                is_optional: option,
                start_destination: destination.destination.id,
                room_ordering: room_index + 1,
                additional_products: room.variant.additional_products ? room.variant.additional_products.map(additional_product => {
                    return {
                        identifier: additional_product.identifier
                    };
                }) : undefined
            };

            if (
                context.enableReplace &&
                context.mode !== 'add' &&
                context.extraData?.current?.status
            ) {
                let status = context.extraData?.current?.status;

                request = {
                    ...request,
                    is_optional: status === 'OPTION',
                    variant: status === 'VARIANT' ? 1 : undefined,
                    is_hidden_for_traveler: status === 'HIDE',
                    is_hidden_for_traveler_count_price: status === 'HIDE_COUNT'
                };
            }

            if (option_value !== 0) {
                request.option = option_value;
            }
            if (variant_value !== 0) {
                request.variant = variant_value;
                console.log('all_data:', all_data);
                let url = `${API_HREF}client/${window.id_owner}/${all_data.circuit_type ? 'circuits' : 'packages'}/${all_data.circuit_type ? all_data.circuit : all_data.package}/`;
                toBeAwaitedPromises.push(
                    axios({
                        method: 'PATCH',
                        headers: headers,
                        url: url,
                        data: {
                            variant_name: all_variant
                        }
                    }).then((response) => {
                        dispatch(SetItineraryTypeDetails(response.data));
                        onClose();
                    }).catch((error) => {
                        console.log('error:', error.response);
                    })
                );
            }
            toBeAwaitedPromises.push(
                axios({
                    method: "POST",
                    headers: headers,
                    url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/accommodation/?token=${GetCookie("trip_token")}`,
                    data: {
                        ...request,
                        start_destination: context.enableReplace && context.mode !== 'add' ?
                            context.extraData?.current?.startDestination :
                            request.start_destination
                    }
                }).then(function (response) {
                    request_count--;
                    cart_cpy.push(response.data);
                    dispatch({
                        type: 'ACCOMMODATION_ADD_TO_CART',
                        payload: { room: response.data }
                    });
                }).catch(function () {
                    request_count--;
                    enqueueSnackbar(`${t("accommodation.request.post_error_1")} ${room.room_nb} ${t("accommodation.request.post_error_2")}`, {
                        variant: "error"
                    });
                })
            );
        });
        if (context.enableReplace) {
            context.onChangeLoading(true);
            Promise.all(toBeAwaitedPromises).finally(() => context.onChangeLoading(false));
        }
        Promise.all(toBeAwaitedPromises).then(() => {
            enqueueSnackbar(t("accommodation.request.post_success_2"), {
                variant: "success"
            });
        
            this.setState({ unmount_after_cart: true }, function () {
                if (!context.enableReplace) {
                    router.push(`/${window.url_name}/apps/accommodation`);
                } else if (context.onClose) {
                    context.onClose();
                }
            });
        });
    };
    cancelAddToCart = () => {
        this.setState({ adding: false, add_to_cart: false });
    };
    setPassLength = (value) => {
        this.setState({ pass_length: value });
    };
    removeCancellableOnly = () => {
        const { dispatch } = this.props;
        dispatch({ type: "MATERIAL_SET_CANCELLABLE", payload: false });
        this.setState({ cancellable: false }, function () {
            this.getProviderPrices();
        });
    };
    removeMealPlan = () => {
        const { dispatch } = this.props;
        dispatch({ type: "MATERIAL_SET_MEAL_PLAN", payload: null });
        this.setState({ breakfast_included: false, lunch_included: false, dinner_included: false, all_inclusive: false }, function () {
            this.getProviderPrices();
        });
    };
    removeSelectedProviders = () => {
        const { dispatch } = this.props;
        dispatch({ type: "MATERIAL_SET_SELECTED_PROVIDERS", payload: [] });
        this.setState({ selected_providers: [] }, function () {
            this.getProviderPrices();
        });
    };
    render() {
        const { context, user, width, cart, classes, router, list_cancellable, list_meal_plan, selected_providers, providers, manual_providers, t } = this.props;
        const { adding, provider_loaded, prices_loaded, destination, standalone_modal_open, facility_modal_open, modal_pictures, pass_length, already_in_cart, add_directly_to_variant, add_to_cart, pre_cart, detail, group_modal_open, current_picture, pictures, current_groups, current_travelers, tripadvisor, tripadvisor_modal_open, pictures_modal_open, pictures_mosaic_modal_open, start_date, end_date, min_date, max_date, prices, diff_day, provider_loading, group_rooms, nb_results, breakfast_included, lunch_included, dinner_included, cancellable, current_group, missing_travelers, selected_room, price_modal_open, all_inclusive } = this.state;

        if (context.enableReplace) {
            switch (context.step) {
                case CartConstructionReplaceProductModalStep.PROVIDERS: {
                    return (
                        <CartConstructionReplaceProductProviders
                            callbackRef={this.props.callbackRef}
                            prices={prices}
                            getRoomPrices={this.getRoomPrices}
                            loaded={!!detail}
                        />
                    );
                }
                case CartConstructionReplaceProductModalStep.ROOMS: {
                    context.onIsFinalStep();
                    if (pre_cart.length === group_rooms?.length) {
                        context.onShowNextButton();
                    } else {
                        context.onHideNextButton();
                    }
                    context.onRegisterNextEvent(() => {
                        this.controlCart();
                    });
                    return (
                        <>
                            <AccommodationCardPreCart
                                pre_cart={ pre_cart }
                                onDelete={(index) => {
                                    const deletedRoom = pre_cart[index];
                                    if (deletedRoom) {
                                        this.deleteRoom(index);
                                        const currentGroupIndex = current_groups?.findIndex((item) => {
                                            return item.id === deletedRoom.passenger_group_id;
                                        });
                                        if (isNumber(currentGroupIndex) && currentGroupIndex >= 0) {
                                            this.setState({
                                                current_group: {
                                                    group: current_groups[currentGroupIndex],
                                                    index_group: currentGroupIndex + 1
                                                }
                                            });
                                        }
                                    }
                                }}
                            />
                            {
                                current_group && group_rooms &&
                                <>
                                    {
                                        user.client_full.type !== 2 && (
                                            <div id={ 'current-provider' } className={ classes.alignCenter }>
                                                {
                                                    provider_loaded.logo !== null ? <img src={ provider_loaded.logo.url } alt={ "provider logo" } width={ 250 }/> : <Typography>{ provider_loaded.name }</Typography>
                                                }
                                            </div>
                                        )
                                    }
                                    <Typography variant={ "h6" } className={ classes.alignCenter }>{ t("accommodation.choose_room") } : { current_group.index_group }/{ current_groups.length }</Typography>
                                    <Typography className={ classes.alignCenter }>{ t("accommodation.price_for") } { current_group.group.travelers.length } { t("accommodation.peoples") } { t("global.and") } { diff_day } { t("itinerary.nights") }</Typography>
                                    <Typography className={ clsx({ [classes.smallFont]: width === 'sm' || width === 'xs' }) }>{ nb_results } { t("accommodation.result_for_search") }</Typography>
                                    <Typography className={ classes.filterTitle }>{ t("accommodation.filter") } : </Typography>
                                    <FormControlLabel className={ classes.noMarginBottom } control={
                                        <Checkbox checked={ breakfast_included } onChange={ this.toggleBreakfastIncluded } name={ "checkedB" } color={ "primary" }/>
                                    } label={ t("accommodation.breakfast_included") }
                                    />
                                    <FormControlLabel className={ classes.noMarginBottom } control={
                                        <Checkbox checked={ lunch_included } onChange={ this.toggleLunchIncluded } name={ "checkedB" } color={ "primary" }/>
                                    } label={ t("accommodation_filters.half_board") }
                                    />
                                    <FormControlLabel className={ classes.noMarginBottom } control={
                                        <Checkbox checked={ dinner_included } onChange={ this.toggleDinnerIncluded } name={ "checkedB" } color={ "primary" }/>
                                    } label={ t("accommodation_filters.all_inclusive") }
                                    />
                                    <FormControlLabel className={ classes.noMarginBottom } control={
                                        <Checkbox checked={ all_inclusive } onChange={ this.toggleAllInclusive } name={ "checkedB" } color={ "primary" }/>
                                    } label={ t("accommodation_filters.all_inclusive_meals") }
                                    />
                                    <FormControlLabel className={ classes.noMarginBottom } control={
                                        <Checkbox checked={ cancellable } onChange={ this.toggleCancellable } name={ "checkedB" } color={ "primary" }/>
                                    } label={ t("accommodation.cancellable") }
                                    />
                                    <br/>
                                    {
                                        group_rooms.map((group, index_group) => {
                                            if (group.passenger_group_id === current_group.group.id) {
                                                let has_room = false;
                                                group.rooms.map(room => {
                                                    if (room.variants[0].remaining_stock === null || room.variants[0].remaining_stock > 0) {
                                                        has_room = true;
                                                    }
                                                });
                                                if (has_room) {
                                                    return (
                                                        <Fragment key={ index_group }>
                                                            {
                                                                group.rooms.map((room, index_room) => {
                                                                    if ((room.variants[0].remaining_stock === null || room.variants[0].remaining_stock > 0) && (!breakfast_included || (breakfast_included && room.has_breakfast_variant)) && (!lunch_included || (lunch_included && room.has_lunch_variant)) && (!dinner_included || (dinner_included && room.has_dinner_variant)) && (!all_inclusive || (all_inclusive && room.variants.find((variant) => variant.all_inclusive) !== undefined)) && (!cancellable || (cancellable && room.has_cancellable_variant))) {
                                                                        return (
                                                                            <AccommodationRoom key={ index_room } index_room={ index_room } provider_loaded={ provider_loaded } breakfast_included={ breakfast_included } lunch_included={ lunch_included } dinner_included={ dinner_included } all_inclusive={all_inclusive} cancellable={ cancellable } diff_day={ diff_day } selected_room={ selected_room } room={ room } onChange={(value) => {
                                                                                this.setState({ selected_room: value }, function () {
                                                                                    const element = document.getElementById(`room-${index_room}`);
                                                                                    if (element !== undefined && element !== null) {
                                                                                        element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
                                                                                    }
                                                                                }); 
                                                                            }} onValidate={ this.addToPreCart } togglePictureModal={ this.togglePictureModal } toggleMosaicModal={ this.toggleMosaicModal }/>
                                                                        );
                                                                    }
                                                                })
                                                            }
                                                        </Fragment>
                                                    );
                                                } 
                                                return <Alert severity={ "error" } variant={ "filled" }>{ t("accommodation.no_stock_left") }</Alert>;
                                            }
                                        })
                                    }
                                </>
                            }
                            {
                                already_in_cart &&
                                <AccommodationAddToCart
                                    open={ add_to_cart }
                                    onClose={ this.cancelAddToCart }
                                    onValidate={ this.addToCart }
                                    setPassLength={ this.setPassLength }
                                    pass_length={ pass_length }
                                    already_in_cart={ already_in_cart }
                                    add_directly_to_variant={ add_directly_to_variant }
                                />
                            }
                        </>
                    );
                }
                default: return null;
            }
        }

        return (
            <Container className={ classes.container }>
                <Grid container spacing={ 3 }>
                    <Grid item xs={ 12 }/>
                    <Grid item xs={ 12 }>
                        {
                            detail !== null ? (
                                <AccommodationCardHeader router={ router } detail={ detail } destination={ destination } tripadvisor={ tripadvisor } toggleTripadvisorModal={ this.toggleTripadvisorModal }/>
                            ) : (
                                <Fragment>
                                    <Skeleton/>
                                    <Skeleton/>
                                </Fragment>
                            )
                        }
                    </Grid>
                    {
                        cancellable && list_cancellable &&
                        <Grid className={ classes.noPaddingTop } item xs={ 12 }>
                            <Alert severity={ "error" } variant={ "filled" } action={ <Button color="inherit" size="small" onClick={ this.removeCancellableOnly }>{ t("accommodation.cancellable_deactive") }</Button> }>{ t("accommodation.cancellable_active") }</Alert>
                        </Grid>
                    }
                    {
                        list_meal_plan !== null &&
                        <Grid className={ classes.noPaddingTop } item xs={ 12 }>
                            <Alert severity={ "error" } variant={ "filled" } action={ <Button color="inherit" size="small" onClick={ this.removeMealPlan }>{ t("accommodation.meal_plan_deactive") }</Button> }>{ t("accommodation.meal_plan_active") } { list_meal_plan === 0 ? t("accommodation_filters.breakfast_included") : list_meal_plan === 1 ? t("accommodation_filters.half_board") : t("accommodation_filters.all_inclusive") } { t("accommodation.meal_plan_active_2") }</Alert>
                        </Grid>
                    }
                    {
                        selected_providers.length > 0 &&
                        <Grid className={ classes.noPaddingTop } item xs={ 12 }>
                            <Alert severity={ "error" } variant={ "filled" } action={ <Button color="inherit" size="small" onClick={ this.removeSelectedProviders }>{ t("accommodation.selected_providers_deactive") }</Button> }>
                                { t('accommodation.selected_providers_active') }
                                {
                                    providers.map((provider, index_provider) => {
                                        if (provider.enabled_content_types.includes(0) && selected_providers.includes(provider.provider.id)) {
                                            return `${provider.provider.name}, `;
                                        }
                                    })
                                }
                                {
                                    manual_providers.map((provider, index_provider) => {
                                        if (provider.available_content_types.includes(0) && selected_providers.includes(provider.provider.id)) {
                                            return `${provider.provider.name}, `;
                                        }
                                    })
                                }
                                { t('accommodation.selected_providers_active_2') }
                            </Alert>
                        </Grid>
                    }
                    {
                        already_in_cart && (
                            <Grid className={ classes.noPaddingTop } item xs={ 12 }>
                                <Alert severity={ "error" } variant={ "filled" }>{ t("accommodation.already_in_cart") }</Alert>
                            </Grid>
                        )
                    }

                    <AccommodationCardPictures size={ 279 } detail={ detail } pictures={ pictures } togglePictureModal={ this.togglePictureModal } toggleMosaicModal={ this.toggleMosaicModal }/>
                    <Grid item container direction={ "row-reverse" } spacing={ 3 }>
                        {
                            isWidthUp('md', width) && (
                                <Grid item xs={ 12 } md={ 4 }>
                                    <Card className={ classes.stickyPaper } raised>
                                        {
                                            detail !== null ? (
                                                <AccommodationCardPrices
                                                    min_date={ min_date }
                                                    max_date={ max_date }
                                                    cart={ cart }
                                                    pre_cart={ pre_cart }
                                                    prices={ prices }
                                                    start_date={ start_date }
                                                    end_date={ end_date }
                                                    diff_day={ diff_day }
                                                    onDateChange={ this.onDateChange }
                                                    group_modal_open={ group_modal_open }
                                                    current_groups={ current_groups }
                                                    current_travelers={ current_travelers }
                                                    toggleGroupModal={ this.toggleGroupModal }
                                                    provider_loading={ provider_loading }
                                                    prices_loaded={ prices_loaded }
                                                    setNewGroups={ this.setNewGroups }
                                                    getRoomPrices={ this.getRoomPrices }
                                                    adding={ adding }
                                                    onValidate={ this.controlCart }
                                                    openStandalone={ this.toggleStandaloneModal }
                                                    setNewGroupsFromHeader={ this.setNewGroupsFromHeader }
                                                    destination={ destination }
                                                    hotel_indentifier={ this.props.params.id }
                                                    setRoomMappingResults={ this.setRoomMappingResults }
                                                />
                                            ) : (
                                                <CardContent>
                                                    <Skeleton variant={ "rect" } width={ "100%" } height={ 300 }/>
                                                </CardContent>
                                            )
                                        }
                                    </Card>
                                </Grid>
                            )
                        }
                        <Grid item xs={ 12 } md={ 8 }>
                            <AccommodationCardPreCart pre_cart={ pre_cart } onDelete={(index) => {
                                const deletedRoom = pre_cart[index];
                                if (deletedRoom) {
                                    this.deleteRoom(index);
                                    const currentGroupIndex = current_groups?.findIndex((item) => {
                                        return item.id === deletedRoom.passenger_group_id;
                                    });
                                    if (isNumber(currentGroupIndex) && currentGroupIndex >= 0) {
                                        this.setState({
                                            current_group: {
                                                group: current_groups[currentGroupIndex],
                                                index_group: currentGroupIndex + 1
                                            }
                                        });
                                    }
                                }
                            }}/>
                            {
                                current_group !== null && group_rooms !== null && (
                                    <Fragment>
                                        {
                                            user.client_full.type !== 2 && provider_loaded !== null && (
                                                <div id={ 'current-provider' } className={ classes.alignCenter }>
                                                    {
                                                        provider_loaded.logo !== null ? <img src={ provider_loaded.logo.url } alt={ "provider logo" } width={ 250 }/> : <Typography>{ provider_loaded.name }</Typography>
                                                    }
                                                </div>
                                            )
                                        }
                                        <Typography variant={ "h6" } className={ classes.alignCenter }>{ t("accommodation.choose_room") } : { current_group.index_group }/{ current_groups.length }</Typography>
                                        <Typography className={ classes.alignCenter }>{ t("accommodation.price_for") } { current_group.group.travelers.length } { t("accommodation.peoples") } { t("global.and") } { diff_day } { t("itinerary.nights") }</Typography>
                                        <Typography className={ clsx({ [classes.smallFont]: width === 'sm' || width === 'xs' }) }>{ nb_results } { t("accommodation.result_for_search") }</Typography>
                                        <Typography className={ classes.filterTitle }>{ t("accommodation.filter") } : </Typography>
                                        <FormControlLabel className={ classes.noMarginBottom } control={
                                            <Checkbox checked={ breakfast_included } onChange={ this.toggleBreakfastIncluded } name={ "checkedB" } color={ "primary" }/>
                                        } label={ t("accommodation.breakfast_included") }
                                        />
                                        <FormControlLabel className={ classes.noMarginBottom } control={
                                            <Checkbox checked={ lunch_included } onChange={ this.toggleLunchIncluded } name={ "checkedB" } color={ "primary" }/>
                                        } label={ t("accommodation_filters.half_board") }
                                        />
                                        <FormControlLabel className={ classes.noMarginBottom } control={
                                            <Checkbox checked={ dinner_included } onChange={ this.toggleDinnerIncluded } name={ "checkedB" } color={ "primary" }/>
                                        } label={ t("accommodation_filters.all_inclusive") }
                                        />
                                        <FormControlLabel className={ classes.noMarginBottom } control={
                                            <Checkbox checked={ all_inclusive } onChange={ this.toggleAllInclusive } name={ "checkedB" } color={ "primary" }/>
                                        } label={ t("accommodation_filters.all_inclusive_meals") }
                                        />
                                        <FormControlLabel className={ classes.noMarginBottom } control={
                                            <Checkbox checked={ cancellable } onChange={ this.toggleCancellable } name={ "checkedB" } color={ "primary" }/>
                                        } label={ t("accommodation.cancellable") }
                                        />
                                        <br/>
                                        {
                                            group_rooms.map((group, index_group) => {
                                                if (group.passenger_group_id === current_group.group.id) {
                                                    let has_room = false;
                                                    group.rooms.map(room => {
                                                        if (room.variants[0].remaining_stock === null || room.variants[0].remaining_stock > 0) {
                                                            has_room = true;
                                                        }
                                                    });
                                                    if (has_room) {
                                                        return (
                                                            <Fragment key={ index_group }>
                                                                {
                                                                    group.rooms.map((room, index_room) => {
                                                                        console.log('room:', room);
                                                                        if ((room.variants[0].remaining_stock === null || room.variants[0].remaining_stock > 0) && (!breakfast_included || (breakfast_included && room.has_breakfast_variant)) && (!lunch_included || (lunch_included && room.has_breakfast_variant && room.has_dinner_variant)) && (!dinner_included || (dinner_included && room.has_breakfast_variant && room.has_lunch_variant && room.has_dinner_variant)) && (!all_inclusive || (all_inclusive && room.variants.find(variant => variant.all_inclusive) !== undefined)) && (!cancellable || (cancellable && room.has_cancellable_variant))) {
                                                                            return (
                                                                                <AccommodationRoom key={ index_room } index_room={ index_room } provider_loaded={ provider_loaded } breakfast_included={ breakfast_included } lunch_included={ lunch_included } dinner_included={ dinner_included } all_inclusive={all_inclusive} cancellable={ cancellable } diff_day={ diff_day } selected_room={ selected_room } room={ room } onChange={(value) => {
                                                                                    this.setState({ selected_room: value }, function () {
                                                                                        const element = document.getElementById(`room-${index_room}`);
                                                                                        if (element !== undefined && element !== null) {
                                                                                            element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
                                                                                        }
                                                                                    }); 
                                                                                }} onValidate={ this.addToPreCart } togglePictureModal={ this.togglePictureModal } toggleMosaicModal={ this.toggleMosaicModal }/>
                                                                            );
                                                                        }
                                                                    })
                                                                }
                                                            </Fragment>
                                                        );
                                                    } 
                                                    return <Alert severity={ "error" } variant={ "filled" }>{ t("accommodation.no_stock_left") }</Alert>;
                                                }
                                            })
                                        }
                                    </Fragment>
                                )
                            }
                            {
                                detail !== null ? (
                                    <AccommodationCardContent detail={ detail } openModal={ this.toggleFacilityModal }/>
                                ) : (
                                    <Fragment>
                                        <Skeleton/>
                                        <Skeleton/>
                                        <Skeleton/>
                                        <Skeleton/>
                                        <Skeleton/>
                                    </Fragment>
                                )
                            }
                        </Grid>
                    </Grid>
                </Grid>
                {
                    detail !== null && (
                        <Fragment>
                            <br/>
                            <Divider/>
                            <br/>
                            <Typography variant={ "h6" } className={ classes.bold }>{ t("accommodation_map.card_title") } :</Typography>
                            <br/>
                            <AccommodationCardMap lat={ parseFloat(detail.latitude) } lng={ parseFloat(detail.longitude) }/>
                            <br/>
                        </Fragment>
                    )
                }
                {
                    tripadvisor !== null && (
                        <TripAdvisor tripadvisor={ tripadvisor } open={ tripadvisor_modal_open } toggleTripadvisorModal={ this.toggleTripadvisorModal }/>
                    )
                }
                {
                    detail !== null && (
                        <Fragment>
                            <MosaicPicture open={ pictures_mosaic_modal_open } onClose={ this.toggleMosaicModal } pictures={ modal_pictures } openModal={ this.togglePictureModal }/>
                            <Picture open={ pictures_modal_open } onClose={ this.togglePictureModal } pictures={ modal_pictures } current_picture={ current_picture } setCurrentPicture={ this.setCurrentPicture }/>
                            <AccommodationCardFacility open={ facility_modal_open } onClose={ this.toggleFacilityModal } facilities={ detail.facility } rooms={ detail.room } sports={ detail.sports } meals={ detail.meals } dists={ detail.dist } payments={ detail.payment }/>
                        </Fragment>
                    )
                }
                <AccommodationCardStandaloneHotel
                    open={ standalone_modal_open }
                    onClose={ this.toggleStandaloneModal }
                    start_date={ start_date }
                    end_date={ end_date }
                    detail={ detail }
                    current_groups={ current_groups }
                    destination={ destination }
                    standaloneSuccess={ this.standaloneSuccess }
                />
                <AccommodationCancellationInfo start_date={ start_date }/>
                <AccommodationCancellationText/>
                <AccommodationCheckInInfo/>
                <AccommodationAdditionalInfo/>
                <AccommodationTaxesInfo/>
                <AccommodationLoadAdditionalProducts provider_loaded={ provider_loaded } start_date={ start_date } end_date={ end_date } destination={ destination } id={ this.props.params.id } current_group={ current_group }/>
                <AccommodationMissingTravelers missing_travelers={ missing_travelers } setNewGroups={ this.setNewGroups }/>
                <AccommodationAddToCart open={ add_to_cart } onClose={ this.cancelAddToCart } onValidate={ this.addToCart } setPassLength={ this.setPassLength } pass_length={ pass_length } already_in_cart={ already_in_cart } add_directly_to_variant={ add_directly_to_variant }/>
                {
                    detail !== null && (
                        <AccommodationCardPricesModal open={ price_modal_open } onClose={ this.togglePriceModal } min_date={ min_date } max_date={ max_date } cart={ cart } pre_cart={ pre_cart } prices={ prices } start_date={ start_date } end_date={ end_date } diff_day={ diff_day } onDateChange={ this.onDateChange } group_modal_open={ group_modal_open } current_groups={ current_groups } current_travelers={ current_travelers } toggleGroupModal={ this.toggleGroupModal } provider_loading={ provider_loading } prices_loaded={ prices_loaded } setNewGroups={ this.setNewGroups } getRoomPrices={ this.getRoomPrices } onValidate={ this.controlCart }/>
                    )
                }
                {
                    isWidthDown('sm', width) && (
                        <AccommodationCardResponsiveChoose pre_cart={ pre_cart } adding={ adding } onValidate={ this.controlCart } detail={ detail } prices={ prices } diff_day={ diff_day } prices_loaded={ prices_loaded } togglePriceModal={ this.togglePriceModal }/>
                    )
                }
            </Container>
        );
    }
}
